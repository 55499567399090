import React, { useState, useEffect, } from 'react';
import {
    setProject, setSubproject,
    setUserinfo, getAllProjectAndSubprojects
} from '../actions'
import { useSelector, useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

const FirstComponent = (WrappedComponent) => {
    return function EnhancedComponent() {
        const dispatch = useDispatch();
        const { authState, oktaAuth } = useOktaAuth(); 

        // store user info
        useEffect(() => {
            if (authState.isAuthenticated) {
                oktaAuth.getUser().then((info) => {
                    dispatch(setUserinfo({ userinfo: info }));                
});
            }
        }, [authState, oktaAuth]);


        return (
            <>
                <WrappedComponent />
            </>
        )


    };
};

export default FirstComponent;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import { insertNewPage, updateSelectedView, getSelectedView, updateViewBuilderJSON, getAllPagesForAdmin } from '../../actions';
import Form from 'react-bootstrap/Form'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withOktaAuth } from '@okta/okta-react';

class DashboardBasicDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.viewBuilderJSON.viewName,
			description: this.props.viewBuilderJSON.viewDescription,
			category: this.props.viewBuilderJSON.categoryName,
			viewStatus: this.props.viewBuilderJSON.viewStatus,
			enableDatepicker: this.props.viewBuilderJSON.datePicker,
			linkForDocumentation:this.props.viewBuilderJSON.linkForDocumentation,
			error: "",
			updateView: false,
			viewId: this.props.viewBuilderJSON.viewId,
			userInfo:"",
			defaultDateRange: this.props.viewBuilderJSON.defaultDateRange,
			optionsList: ""
		}
	}
	componentWillReceiveProps(nextprops) {
		this.props.oktaAuth.getUser().then((res, rej) => {
            if (res) {
                
			this.setState({ userInfo: res });
		  } else {

		} 
})
	}
	componentDidMount(){
		this.props.oktaAuth.getUser().then((res, rej) => {
            if (res) {
			this.setState({ userInfo: res });
		  } else {

		} 
});
		let { dateFilterOptions } = cloneDeep(this.props.globalReducer);
	    let dateOptions =  dateFilterOptions.map((dateFilter) => {
			return  <option value={dateFilter}>{dateFilter}</option>
		});
		this.setState({
			optionsList: dateOptions
		})
	}

	saveNewPageDetails() {
		const { name, description, category, viewStatus,enableDatepicker,userInfo , linkForDocumentation,defaultDateRange} = this.state;
		if (!name || !description || !category) {
			toast.error("Please enter all values", { position: toast.POSITION.BOTTOM_LEFT });
		} else {
			if (this.state.viewId && this.state.viewId !== "") {
				let obj = {
					viewId: this.state.viewId,
					"categoryName": category,
					"viewName": name,
					viewDescription: description,
					viewStatus: viewStatus,
					"createdBy": userInfo.email,
					"updatedBy": userInfo.email,
					datePicker:enableDatepicker,
					linkForDocumentation:linkForDocumentation,
					defaultDateRange:defaultDateRange

				}
				this.props.updateSelectedViewAPI(obj).then((res, err) => {
					if (res) {
						toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
						let newId = res.data.responseData[0];
						let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
						tempViewBuilderObj.viewBuilderJSON.categoryName = category;
						tempViewBuilderObj.viewBuilderJSON.viewName = name;
						tempViewBuilderObj.viewBuilderJSON.description = description;
						tempViewBuilderObj.viewBuilderJSON.viewStatus = viewStatus;
						tempViewBuilderObj.viewBuilderJSON.datePicker = enableDatepicker;
						tempViewBuilderObj.viewBuilderJSON.linkForDocumentation = linkForDocumentation;
						tempViewBuilderObj.viewBuilderJSON.defaultDateRange = defaultDateRange;
						this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
						this.props.hideSettingsWindow('e');
					} else if (err) {
					}
				});
			} else {
				let obj = {
					"categoryName": category,
					"viewName": name,
					"description": description,
					"status": "Draft",
					"createdBy": userInfo.email,
					"updatedBy": userInfo.email,
					"defaultDateRange":defaultDateRange

				}
				this.props.insertNewPageAPI(obj).then((res, err) => {
					if (res) {
						let newId = res.data.responseData[0];
						this.props.getSelectedViewAPI(newId);
						this.props.getAllPagesForAdminAPI({ viewStatus: 'Draft' });
						this.props.hideSettingsWindow('e');
						toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
					} else if (err) {

					}
				});
			}

		}
	}
	renderDashboardStatus() {
		if (this.state.viewId && this.state.viewId != '') {
			return (
				<div className="col-12  col-xl-12 form-group ">
					<label className="">
						Status
       </label>
					<select value={this.state.viewStatus} className="form-control " onChange={(e) => { this.setState({ viewStatus: e.target.value }) }}>
						<option disabled="">Select</option>
						<option value="Active">Active</option>
						<option value="Inactive">Inactive</option>
						<option value="Draft">Draft</option>
					</select>
				</div>
			)
		}
	}
	render() {

		return (<div className="container">
			<ToastContainer />
			<div className="col-12 sections my-2" id="basic_screen">
				<form  className="form">
					<div className="row">
						<div className="col-12  col-xl-12 form-group ">
							<label className="">
								Dashboard Name
      							</label>
							<input value={this.state.name} type="text" className="form-control " placeholder="Label for dashboard" onChange={(e) => { this.setState({ name: e.target.value }) }} />
						</div>
						<div className="col-12  col-xl-12 form-group ">
							<label className="">

								Description
      							</label>
							<input value={this.state.description} type="text" className="form-control " placeholder="Enter short description for the dashboard." onChange={(e) => { this.setState({ description: e.target.value }) }} />
						</div>
						<div className="col-12  col-xl-12 form-group ">
							<label className="">

								Link for documentation <small>(Ensure the link has http(s) included)</small>
							</label>
							<input value={this.state.linkForDocumentation} type="text" className="form-control " placeholder="Enter a link" onChange={(e) => { this.setState({ linkForDocumentation: e.target.value }) }} />
						</div>
						<Form.Group className="col-12  col-xl-12 form-group " controlId="formBasicCheckbox">
							<Form.Check defaultChecked={this.state.enableDatepicker} type="checkbox" label="Enable Datepicker ?" className="ml-2" onChange={(e)=>{
								this.setState({enableDatepicker:e.target.checked})
							}}/>
						</Form.Group>
						<div className="col-12  col-xl-12 form-group ">
							<label className="">
								Select Category	for the Dashboard
      							</label>
							<select value={this.state.category} className="form-control " onChange={(e) => { this.setState({ category: e.target.value }) }}>
								<option disabled="">Select</option>
								<option value="Insights">Insights</option>
								<option value="Scorecard">Scorecard</option>
							</select>
						</div>
						{this.renderDashboardStatus()}
						<div className="col-12  col-xl-12 form-group ">
							<label className="">
								Select Default Date Range
      							</label>
							<select value={this.state.defaultDateRange} className="form-control " onChange={(e) => { this.setState({ defaultDateRange: e.target.value }) }}>
								{this.state.optionsList}
							</select>
						</div>
						<div>{this.state.error}</div>
						<div className="col-12  col-xl-12 form-group  flex mt-0" style={{ justifyContent: "flex-end" }}>
							<button type="button" className="btn btn-apply" onClick={(e) => { e.preventDefault(); this.saveNewPageDetails() }}>Save</button>
						</div>


					</div>
				</form>
			</div>
		</div>);
	}
}
function mapStateToProps(state) {
	return ({
		viewBuilder: state.viewBuilderObj,
		globalReducer: state.globalReducer
	})
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		insertNewPageAPI: insertNewPage,
		updateSelectedViewAPI: updateSelectedView,
		getSelectedViewAPI: getSelectedView,
		updateViewBuilderJSONAPI: updateViewBuilderJSON,
		getAllPagesForAdminAPI: getAllPagesForAdmin
	}, dispatch)
}

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(DashboardBasicDetails));

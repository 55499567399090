import React, { useState, useEffect } from 'react';
// import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDetailsData, getDetailsSidebarOptions } from '../../actions';
import Sidebar from '../../components/Sidebar';
import Filters from '../../components/Filters';
import { useDispatch, useSelector } from "react-redux";

import './Details.scss';
import BarLoader from "react-spinners/BarLoader";
import { PriceFormatter, sortFunction , addCommonActivityCaptureDetail} from '../../helper';

const Details = (props) => {
    const [selectedTab, setSelectedTab] = useState("All");
    const [loading, setLoading] = useState(true);
   
    const [interacted, setInteracted] = useState(false);

    let {
        selectedGlobalFilters, keyMapTabList
      } = useSelector((state) => state.globalReducer);
      let {  selectedStrategies } = useSelector(
        (state) => state.strategyReducer
      );
      useEffect(() => {
        document.title = keyMapTabList ? keyMapTabList.detailsTab : "";
    }, [keyMapTabList]);
    useEffect(() => {
      
        Promise.all([
            props.getDetailsSidebarOptionsAPI(),
        ]).then(function (values) {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        addCommonActivityCaptureDetail({page: `Details- ${selectedTab}`,globalFilters:selectedGlobalFilters ,strategyFilters: selectedStrategies })
        Promise.all([
            props.getDetailsDataAPI({
                "currentIndex": 1,
                "limit": 20,
                "columnFilter": selectedTab,
                "filterValues": selectedGlobalFilters
            })]).then(function (values) {
                setLoading(false);
            });

    }, [selectedTab,selectedGlobalFilters]);

    const setSelectedTabMethod = (option) => {
        setInteracted(true);
        setSelectedTab(option);// change selected tab
    }
    const productInfoColumnFormatter = (cell, row, rowIndex, formatExtraData) => {


        return (
            <div className="flex details_wrapper_reco">
                <div className="mr-3">
                    {row.Image_URL !== "0" && row.Image_URL ? <img src={row.Image_URL} alt={row.title} className="image-fluid" height="70" width="75" /> :
                        <img src={process.env.PUBLIC_URL + 'img/kc_logo.jpg'} alt="logo" className="image-fluid" height="70" width="75" />}

                </div>
                <div>
                    {row.Product_Title && row.Product_Title.length > 0 ? <p> {row.Product_Title} </p> : <p>Product_Title</p>}

                    <p ><span>UPC : </span>
                        <span className="sku mr-1">{Number(row.UPC)}</span>, <span>ID : </span>
                        <span className="sku">{row.Product_ID}</span>
                        <a className="sku ml-3" target="_blank" rel="noopener noreferrer" href={row.Product_URL}><i className="fas fa-link"></i></a>
                    </p>


                </div>
            </div>
        )
    }
 
    function renderDetailsData(){
        const {sidebarOptions} = props;
        if( (sidebarOptions.constructor === Array && sidebarOptions.length === 0 )  || (sidebarOptions.constructor === Object && Object.keys(sidebarOptions).length === 0 )){
           return (
            <div>
            No data available
        </div>
           )
        }else{
            return (
                <div className="table-scroll">
                        {/* <ToolkitProvider
                            keyField="id"
                            data={props.detailsData}
                            columns={columns}
                            search

                        >

                            {
                                props => ( */}
                                    <div>

                                        <div className="flex justify-content-end">

                                            {/* <SearchBar {...props.searchProps} /> */}
                                        </div>
                                        {/* <BootstrapTable keyField="id" data={props.detailsData}
                    columns={columns} pagination={paginationFactory()} filter={ filterFactory()}
                                        /> */}
                                    </div>
                        {/* //         )
                        //     }
                        // </ToolkitProvider> */}
                    </div>
            )
        }
    }

    let columnsData = props.detailsDataColumnInfo;
    let columns = columnsData.map(oneColumnInfo => {
        oneColumnInfo.type = oneColumnInfo.type ? oneColumnInfo.type.toLowerCase() : oneColumnInfo.type
        if (oneColumnInfo.type && oneColumnInfo.type === 'currency') {
            oneColumnInfo.formatter = (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div>{PriceFormatter(cell, oneColumnInfo.formatOptions)}</div>
                )
            }
        }
        if (oneColumnInfo.type && oneColumnInfo.type === 'number') {
            oneColumnInfo.formatter = (cell, row, rowIndex, formatExtraData) => {
                let formatOptions = oneColumnInfo.formatOptions ? oneColumnInfo.formatOptions : {}
                formatOptions.symbol = ''
                return (
                    <div>{PriceFormatter(cell, formatOptions)}</div>
                )
            }
        }
        if (oneColumnInfo.type && oneColumnInfo.type === 'percentage') {
            oneColumnInfo.formatter = (cell, row, rowIndex, formatExtraData) => {
                let formatOptions = oneColumnInfo.formatOptions ? oneColumnInfo.formatOptions : {}
                formatOptions.symbol = '%'
                formatOptions.format = '%v %s'
                return (
                    <div>{PriceFormatter(cell, formatOptions)}</div>
                )
            }
        }

        return oneColumnInfo
    })

    let obj = {
        text: "Details", dataField: "DESCRIPTION", formatter: productInfoColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '80px', textAlign: 'center' };
        }
    };
    columns.splice(0, 0, obj);
    // const { SearchBar } = Search;
    return (
        <div id="details_wrapper">

            <Sidebar setSelectedTab={setSelectedTabMethod} selectedTab={selectedTab} sidebarOptions={props.sidebarOptions} />
            <div className="container-fluid  default-top padd-left  bg-gray ">
                <div className='row'>
                    <div className="col-12 my-2">
                        <Filters subtitle= {keyMapTabList ? keyMapTabList.detailsTab : ""} title={selectedTab}  />
                    </div>
                </div>
                {loading ?
                    <div className=" loading_wrapper">
                        <div className="sweet-loading">
                            <BarLoader
                                size={150}
                                color={"#123abc"}
                                height={4}
                                width={100}
                                loading={loading}
                            />
                        </div>

                    </div> : renderDetailsData()}


            </div>
        </div>
    )
}
function mapStateToProps(state) {
    let sidebarOptions = _get(state, 'detailsReducer.sidebarOptions.responseData', []);
    let detailsDataColumnInfo = _get(state, 'detailsReducer.detailsData.responseData.columns', [{ text: "" }]);
    let detailsData = _get(state, 'detailsReducer.detailsData.responseData.rows', []);

    return ({
        sidebarOptions: sidebarOptions,
        detailsData: detailsData,
        detailsDataColumnInfo: detailsDataColumnInfo
    })
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getDetailsDataAPI: getDetailsData,
        getDetailsSidebarOptionsAPI: getDetailsSidebarOptions
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Details);
import React, { useState, useEffect } from 'react';
// import BootstrapTable from "react-bootstrap-table-next";
import Modal from 'react-bootstrap/Modal';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getEtlStatus } from '../actions/index';
import _get from 'lodash/get';

function EtlModal(props) {

  const [loading, setLoading] = useState(false);
  const [preModalBodyText, setPreModalBodyText] = useState("Loading...");
  const [etlStatusList, setEtlStatusList] = useState([]);
  const dispatch = useDispatch();
  const [etlLastRunDate, setEtlLastRunDate] = useState("");
  const [etlStatus, setEtlStatus] = useState("");

  const [columns, setColumns] = useState([{
    dataField: 'OBJECT',
    text: 'Table'
  }, {
    dataField: 'DATE',
    text: 'Date',    
    formatter: function (row, cell) {
      let date = cell.DATE.split(" ")[0];
      return (
          <div className="channel_td">{date}</div>
      )
    },
  }, {
    dataField: 'COMMENTS',
    text: '',
    style:{color:'red'}
  }]);

 

  useEffect(() => {
    setLoading(true);
    getEtlStatus({
      customerKey: props.customerKey
    }, (response) => {
      if (response &&
        response.data &&
        response.data.dataAvailability &&
        response.data.dataAvailability.results) {

        if (response.data.dataAvailability.results.length <= 0) {
          setLoading(true)
          setPreModalBodyText("No Data Available")
        } else {
          setLoading(false);
          setEtlStatusList(response.data.dataAvailability.results);
        }

      } else {
        setLoading(true)
        setPreModalBodyText("No Data Available");
      }

      if(response && response.data &&
         response.data.etlStatus && response.data.etlStatus.results.length > 0 ) {
            setEtlLastRunDate(response.data.etlStatus.results[0].LAST_RUN_DATE);
            setEtlStatus(response.data.etlStatus.results[0].LAST_RUN_STATUS)
      }else {
            setEtlLastRunDate('None');
            setEtlStatus('None')
      }
    });
  }, [props.customerKey]);


  return (
    <Modal size={'lg'} show={true} onHide={props.displayEtlModalOff} id="etlstatusmodal" >
      <Modal.Header closeButton>
        <Modal.Title className="ml-4 mt-2 mr-3">ETL Status</Modal.Title>
        <div className="etl_runstatus">
          <div><strong> Last Run Date:</strong> {etlLastRunDate}</div>
          <div><strong> Last Run Status:</strong> {etlStatus}</div>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-gray py-4">
        {loading ? (
          <div style={{ height: "400px", textAlign: "center" }}>
            {preModalBodyText}
          </div>
        ) : (
            <div>
              <div className="h4"><strong>Data Availability </strong></div>
                {/* <BootstrapTable
                  wrapperClasses="table-responsive"
                  keyField='id'
                  data={etlStatusList}
                  columns={columns}
                  striped
                  hover
                  bordered={false}
                  headerClasses='staticTableheaderProduct' /> */}
              </div>
        )}
        
      </Modal.Body>
    </Modal>
  )
}

export default EtlModal;
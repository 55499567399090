import React, { Component, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Feedback.scss';
import {addCommonActivityCaptureDetail} from '../helper'
import { useDispatch, useSelector } from 'react-redux';

function Feedback(props) {
    const [issueFeedback, setIssueFeedback] = useState(true);
    const [includeScreenshot, setIncludeScreenshot] = useState(true);
    const [comments, setComments] = useState('');
    const [error, setError] = useState("");
    const [file, setFile] = useState('');
    let {  selectedStrategies } = useSelector(
        (state) => state.strategyReducer
      );
      let {  selectedGlobalFilters } = useSelector(
        (state) => state.globalReducer
      );
    useEffect(() => {
        addCommonActivityCaptureDetail({page : "Feedback",globalFilters:selectedGlobalFilters ,strategyFilters: selectedStrategies})   
    }, []);
    
    function handleFeedbackTypeChange(e) {
        setIssueFeedback(e);
    }
    function handleIncludeScreenshotChange(e) {
        setIncludeScreenshot(!includeScreenshot);
    }
    function handleSave(e) {
        if (!comments) {
            setError('Please enter comments to proceed');
        } else if (includeScreenshot && file === "") {
            setError('Please upload an image to proceed');
        } else {
            setError('');
            let obj = {
                feedbackContent: comments,
                feedbackType: issueFeedback ? 'Report an issue' : 'Suggest an enhancement',
            }
            if (includeScreenshot) {
                obj.image = file.base64;
                obj.name = file.name;
            }
            props.submitFeedback(obj);
        }
    }
    function handleTextchange(e) {
        setError('');
        setComments(e.target.value);
    }
    function getFiles(e) {
        setError('');
        var reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = function () {
            setFile({
                name: file.name,
                base64: reader.result
            });
        }
        reader.readAsDataURL(file);


    }
    return (
        <Modal size={'md'} show={true} onHide={props.displayModalOff}  >
            <Modal.Header closeButton>
                <Modal.Title><i className="fas fa-comments mr-3"></i> Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">
                <Form.Group as={Row}>
                    <Form.Label as="legend" column sm={12}>
                        Feedback Type
      </Form.Label>
                    <Col sm={12}>
                        <Form.Check
                            inline
                            defaultChecked={true}
                            type="radio"
                            label="Report an issue"
                            name="feedbackRadio"
                            id="issueRadio"
                            onClick={(e) => {
                                handleFeedbackTypeChange(true)
                            }}
                        />
                        <Form.Check
                            type="radio"
                            inline
                            label="Suggest enhancement"
                            name="feedbackRadio"
                            id="enhancementRadio"
                            onClick={(e) => {
                                handleFeedbackTypeChange(false)
                            }}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>

                    <Col sm={12}>
                        <Form.Check
                            inline

                            defaultChecked={true}
                            type="checkbox"
                            label="Upload an image"
                            name="feedbackRadio"
                            id="issueRadio"
                            onClick={(e) => {
                                handleIncludeScreenshotChange(e)
                            }}
                        />
                        <input
                            type="file"
                            accept=".png,.jpeg,.jpg"
                            className="form-control my-2"
                            onChange={(e) => {
                                getFiles(e);
                            }} />

                    </Col>

                </Form.Group>

                <div>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Enter comments *<small className="ml-2">(Please be as descriptive as you can)</small></Form.Label>
                        <Form.Control value={comments} as="textarea" rows="3" onChange={(e) => {
                            handleTextchange(e)
                        }} />
                    </Form.Group>
                </div>
                <p className="error">{error}</p>
                <button className="btn btn-apply" onClick={(e) => {
                    handleSave()
                }}><i className="fas fa-save mr-2"></i>Save</button>

            </Modal.Body>
        </Modal>
    )
}

export default Feedback;
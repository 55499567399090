import React, { useState, useEffect, useRef } from 'react';
import { Modal, Table, Pagination, Form, FormControl, InputGroup } from 'react-bootstrap';
import './Updates.scss';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { CKEditor }  from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { createNewRelease, displayAllReleases, updateReleaseById, deleteReleaseId, updateLatestUpdateForAllUsers, getOrUpdateLatestUpdateAvailability } from '../actions/index';
import moment from "moment";
import {useHistory, useLocation} from 'react-router-dom';

const AddUpdateModal = (props) => {
    let dispatch = useDispatch();
    const {propsDate, propsReleasenumber, propsTitle, propsContent, propsRetailers, propsSubprojectId, propsProjectId, propsId, propsCreatedBy, propsCreatedByEmail } = props.modalDetails;
    const [addUpdateError, setAddUpdateError] = useState("");
    const [releaseNumberError, setReleaseNumberError] = useState("");
    const [releaseNumber, setReleaseNumber] = useState(propsReleasenumber);
    const [title, setTitle] = useState(propsTitle);
    const [content, setContent] = useState(propsContent);
    let { projectAndSubprojects, project, subproject } = useSelector((state) => state.globalReducer);
    const projectDetails = projectAndSubprojects.filter((individualProject) => {
        return individualProject.name === project;
    });
    const listOfSubprojects = projectDetails[0].subProjectInfo;
    const [startDateToday, setStartDateToday] = useState(propsDate);
    function releaseDateFormat(date) {
        return moment(date).utc().format("YYYY-MM-DD");
    }
    const currentDate = releaseDateFormat(startDateToday);
    let retailersList = props.modalMode === "Edit" ? propsRetailers.split(',') : [];
    const [checkedAllData, setCheckedAllData] = useState(retailersList);
    
    const ref = useRef([]);
    const modalRef = useRef(null);
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    
    useEffect(() => {
        if (props.modalMode === "Edit") {
            listOfSubprojects.filter((retailersName, index) => {
               return retailersList.some((strName) => {
                if(strName === retailersName.name) {
                    ref.current[index].checked = true; 
                }                
               });                
            })}
    }, []);

    const saveUpdate = (e, modalMode) => {
        e.preventDefault();
        const re = /^[0-9.]+$/
        if (releaseNumber === "" || title === "" || checkedAllData?.length === 0 || content === undefined || content?.length === 0) {
            setAddUpdateError("Please fill all values");            
        }else if(re.test(releaseNumber) === false && releaseNumber !== "") {
            modalRef.current.scrollIntoView();
            setReleaseNumberError("Please enter Release number in the format YY.MM.DD");
        } else {
            setAddUpdateError("");
            props.displayModalOff();
            if (modalMode === "Add") {
                dispatch(createNewRelease({ title: title, releaseDate: currentDate, releaseNumber: releaseNumber, content: content, createdBy: userInfo?.email, retailers:checkedAllData, createByName: userInfo?.name, IS_LATEST_UPDATE:'TRUE' }, (res, rej) => {
                    if (res) {
                        toast.success(res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });  
                        dispatch(displayAllReleases({pageNo:1,sortColumn: "ReleaseDate",
                        sortOrder: "desc"},(res) => { })); 
                        dispatch(updateLatestUpdateForAllUsers({retailers:checkedAllData})); 
                        dispatch(getOrUpdateLatestUpdateAvailability({userId:userInfo?.sAMAccountName,userName:userInfo?.name,userEmail:userInfo.email,isUpdate:'FALSE', Retailer:subproject}));                    
                    }
                }));
            } else {
                dispatch(updateReleaseById({releaseData:{ Title: title, ReleaseDate: currentDate, ReleaseNumber: releaseNumber, Content: content, CreatedBy: propsCreatedByEmail, Retailers:checkedAllData.join(),
                     CreateByName: propsCreatedBy, UpdatedBy: userInfo?.email, UpdatedByName: userInfo?.name,UpdatedOn: releaseDateFormat(new Date()),SubprojectId: propsSubprojectId, ProjectId: propsProjectId, IS_LATEST_UPDATE:'TRUE'},releaseId: propsId}, (res, rej) => {
                    if (res) {
                        toast.success(res?.data?.responseDesc === 'Release data got updated successfully.' ? 'Edit Version successful' : res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });  
                        dispatch(displayAllReleases({pageNo:1,sortColumn: "ReleaseDate",
                        sortOrder: "desc"},(res) => { })); 
                        dispatch(updateLatestUpdateForAllUsers({retailers:checkedAllData})); 
                        dispatch(getOrUpdateLatestUpdateAvailability({userId:userInfo?.sAMAccountName,userName:userInfo?.name,userEmail:userInfo.email,isUpdate:'FALSE', Retailer:subproject}));                     
                    }
                }));  

            }

        }
    }

    const handleChange = (event, individualProject, type) => {
        let updatedList = [...checkedAllData];
        if (event.target.checked) {
        updatedList = [...checkedAllData, event.target.value];
        } else {
        updatedList.splice(checkedAllData.indexOf(event.target.value), 1);
        }
        setCheckedAllData(updatedList);        
    };
    const handleSelect = () => {
        let updatedList = [];
        for (let i = 0; i < listOfSubprojects.length; i++) {
            ref.current[i].checked = true;
            updatedList.push(listOfSubprojects[i].name);
        }
        setCheckedAllData(updatedList);
    };    
    const handleClear = () => {        
        for (let i = 0; i < ref.current.length; i++) {
            ref.current[i].checked = false;
        }
        setCheckedAllData([]);
    };      
    const handleContent = (event, editor) => {
        const data = editor.getData();
        setContent(data);
    }
    const cancelUpdate = (e) => {
        e.preventDefault();
        props.displayModalOff();
    }
    let modalText = "Manage";
    if (props.modalMode === "Add") {
        modalText = "New";
    }
    return (
        <Modal size={'md'} show={true} onHide={props.displayModalOff} id="Updation-modal" >
            <Modal.Header closeButton>
                <Modal.Title className='ml-5 newupdatetxt'>{modalText} Update</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">
                <div className=" ">
                    <div className="row">
                        <div className="col-12 my-2">
                            <form >
                                <div className="form-group d-flex">
                                    <label className="col-3 col-form-label"> Release Date </label>
                                    <DatePicker value={startDateToday} selected={startDateToday} onChange={(date) => setStartDateToday(date)} maxDate={new Date()}
                                    className="form-control" />
                                </div>
                                <div className="form-group d-flex mt-4" ref={modalRef}>
                                    <label className="col-3 col-form-label"> Release number </label>
                                    <input type="text" placeholder='Release Number' value={releaseNumber} className="form-control col-4" onChange={(e) => { setReleaseNumber(e.target.value); setAddUpdateError("") }} />
                                </div>
                                {releaseNumberError !== '' && <div className="form-group d-flex">
                                    <lable className="col-3 col-form-label"></lable>
                                    <p className="error col-5">{releaseNumberError}</p>
                                </div> }
                                <div className="form-group d-flex mt-4">
                                    <label className="col-3 col-form-label">Update Title</label>
                                    <input type="text" placeholder='Title Name' value={title} className="form-control col-4" onChange={(e) => { setTitle(e.target.value); setAddUpdateError("") }} />
                                </div>

                                <div className="form-group d-flex mt-4">
                                    <label className="col-3 col-form-label">Update Contents</label>
                                    <CKEditor config={{placeholder: "Add text..."}} editor={ ClassicEditor } data={content} onChange={handleContent} />
                                </div>
                                
                                <div className="form-group d-flex mt-2">
                                    <div className='col-3'>
                                    <div className="col-form-label mt-3">Retailers</div>
                                    <div className="col-form-label mt-3 selet-clr" onClick={handleSelect}>Select All</div>
                                    <div className="col-form-label mt-3 selet-clr" onClick={handleClear}>Clear </div>
                                    </div>
                                    <div className="row">                                    
                                    {listOfSubprojects.map((individualSubproject, index) => {
                                        return(
                                            <div key={index} className="col-4 tile-wrapper">
                                                <div className="form-check form-check-custom">
                                                    <input className="form-check-input custom" ref={(element) => { ref.current[index] = element }}
                                                    type="checkbox" value={individualSubproject.name} 
                                                    onChange={(event) => handleChange(event, individualSubproject, 'single')}                                                    
                                                    />
                                                    <label className="form-check-label ml-2" for="all">
                                                    {individualSubproject.name}
                                                    </label>
                                                </div>                                                
                                            </div>
                                        )
                                    })} 
                                    </div>         
                                </div>
                                <p className="error">{addUpdateError}</p>
                                <div className='d-flex mt-1 btn_udpates'>
                                    <button class="btn btn-default mr-5" onClick={(e) => { cancelUpdate(e) }}>Cancel</button>
                                    <button class="btn btn-apply" onClick={(e) => { saveUpdate(e, props.modalMode) }}>Save Update</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const Updates = () => {
    let dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [allreasesData, setAllreasesData] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modalMode, setModalMode] = useState("Add");
    const [modalDetails, setModalDetails] = useState({
        propsDate: new Date(),
        propsReleasenumber: "",
        propsTitle: "", 
        propsRetailers:[],
    });

    const [pageNumber, setPageNumber] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalSize, setTotalSize] = useState(0);

    let { allRleasesdata } = useSelector((state) => state.versionReducer);
    let { isAppAdmin, isProjectAdmin, isSubprojectAdmin, subproject, project, latestUpdateAvailble} = useSelector((state) => state.globalReducer);
    let hasAdminAccess = isAppAdmin || isProjectAdmin || isSubprojectAdmin ? true : false;
    
    const displayModalOff = () => {
        setDisplayModal(false);
    }
    const handleAddUpdates = (e) => {
        setDisplayModal(true);
        setModalMode("Add");
        setModalDetails({
            propsDate: new Date(),
            propsReleasenumber: "",
            propsTitle: "",
            propsContent: "",
            propsRetailers: [],
        });
    }

    const responseHandlerupdate = (response) => {
        if (response?.data?.responseData) {
            if (response.data.responseData.length <= 0) {
                setLoading(false);
            } else {
                if (response?.data?.responseData?.releaseList) {
                    setAllreasesData(response.data.responseData.releaseList);
                    setTotalSize(response.data.responseData.totalRows);
                    setLoading(false);
                }
            }
        } else {
            setLoading(false);
        }
    }
    
    useEffect(() => {
        setLoading(true);
        dispatch(displayAllReleases({
            pageNo: pageNumber,
            totalRows: sizePerPage,
            sortColumn: "ReleaseDate",
            sortOrder: "desc"
        }, responseHandlerupdate));
    }, [dispatch, pageNumber, sizePerPage]);

    useEffect(() => {
        if (allRleasesdata?.responseData?.releaseList) {
            setAllreasesData(allRleasesdata.responseData.releaseList);
            setTotalSize(allRleasesdata.responseData.totalRows);
            setLoading(false);
        }
    }, [allRleasesdata]);

    const dateFormatter = (cell) => {
        return <>{moment.utc(cell).format("MMMM DD, YYYY")}</>;
    }

    const handleEditRelease = (e, row) => {
        setDisplayModal(true);
        setModalMode("Edit");
        setModalDetails({
            propsDate: new Date(row.ReleaseDate),
            propsReleasenumber: row.ReleaseNumber,
            propsTitle: row.Title,
            propsContent: row.Content,
            propsRetailers: row.Retailers,
            propsSubprojectId: row.SubprojectId,
            propsProjectId: row.ProjectId,
            propsId: row.id,
            propsCreatedByEmail: row.CreatedBy,
            propsCreatedBy: row.CreatedByName
        });
    }

    const handleDelete = (e, row) => {
        Swal.fire({
            title: 'Are you sure you want to delete this Update?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.value) {
                dispatch(deleteReleaseId({ releaseId: row.id }, (res, rej) => {
                    if (res) {
                        dispatch(displayAllReleases({
                            pageNo: pageNumber,
                            sortColumn: "ReleaseDate",
                            sortOrder: "desc"
                        }, (res) => { }));
                        toast.success(res.data.responseDesc === 'Deleted selected release data successfully.' ? 'Delete Version successful' : res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                    }
                }));
            }
        })
    }

    const editColumnFormatter = (cell, row) => {
        return (
            <tr className="flex">
               <td className='mr-5 ed-clr' onClick={(e) => { handleEditRelease(e, row) }}>Edit Version</td> 
               <td className='ml-2 dl-clr' onClick={(e) => { handleDelete(e, row) }}>Delete Version</td> 
            </tr>
        )
    }

    const linkFormatter = (cell, row) => {
        return (
            <div className='ed-clr' onClick={() => history.push(`/ecompass/${subproject}?id=${row.id}`)}>                
                Link               
            </div>
        )
    }

    const handleSizePerPageChange = (event) => {
        setSizePerPage(Number(event.target.value));
        setPageNumber(1); // Reset to first page
    };

    // Sorting functions
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSort = (field) => {
        let order = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);

        const sortedData = [...allreasesData].sort((a, b) => {
            const valueA = a[field] !== undefined && a[field] !== null ? a[field].toString() : '';
            const valueB = b[field] !== undefined && b[field] !== null ? b[field].toString() : '';

            if (typeof a[field] === 'number') {
                return order === 'asc' ? a[field] - b[field] : b[field] - a[field];
            } else if (typeof a[field] === 'string' || typeof a[field] === 'number') {
                return order === 'asc'
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }
            return 0;
        });

        setAllreasesData(sortedData);
    };

    const handleSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc'
                ? <i className="fas fa-sort-up ml-2 active"></i>
                : <i className="fas fa-sort-down ml-2 active"></i>;
        }
        return <i className="fas fa-sort ml-2"></i>;
    };

    const pageSizeOptions = [10, 20, 30, 50];
    const totalPages = Math.ceil(totalSize / sizePerPage);

    const handlePageChange = (newPageNumber) => {
        if (
          newPageNumber > 0 &&
          newPageNumber <= totalPages
        ) {
          setPageNumber(newPageNumber);
        }
    };

    const getPaginationItems = () => {
        const items = [];
        const visiblePages = 3;

        const startPage = Math.max(1, pageNumber - visiblePages);
        const endPage = Math.min(totalPages, pageNumber + visiblePages);

        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === pageNumber}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            items.push(
                <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return items;
    };

    const paginatedData = allreasesData;   
    return (
        <div>
        {loading ? (
        <div id="no-data-available" style={{ marginTop: "5%" }}> Loading....</div>) :
           (<div>
            <ToastContainer />
             {displayModal ?
                <AddUpdateModal
                    modalDetails={modalDetails}
                    modalMode={modalMode}
                    display={displayModal} displayModalOff={displayModalOff} /> : null}
       
            <div className="container-fluid default-top bg-gray p-5">
                <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                        <div className="header"> Manage Updates</div>
                            <div className="mb-3">
                            <button className="btn btn-apply" onClick={handleAddUpdates}>
                                Create Update
                            </button>
                        </div>
                    </div>
                </div>
                
                <Table bordered className='mb-4'>
                    <thead>
                        <tr className='Updatetable'>
                            <th onClick={() => handleSort('Title')}>Update Title {handleSortIcon('Title')}</th>
                            <th onClick={() => handleSort('ReleaseNumber')}>Release Number {handleSortIcon('ReleaseNumber')}</th>
                            <th onClick={() => handleSort('ReleaseDate')}>Release Date {handleSortIcon('ReleaseDate')}</th>
                            <th>Link </th>
                            <th onClick={() => handleSort('UpdatedByName')}>Last Updated by User {handleSortIcon('UpdatedByName')}</th>
                            <th onClick={() => handleSort('UpdatedOn')}>Last Updated Date {handleSortIcon('UpdatedOn')}</th>
                            {hasAdminAccess && <th>Actions </th>}
                        </tr>
                    </thead>
                    <tbody className='Selfservicebcls Updatepad'>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row?.Title}</td>
                                    <td>{row?.ReleaseNumber}</td>
                                    <td>{dateFormatter(row?.ReleaseDate)}</td>
                                    <td>{linkFormatter(row?.id, row)}</td>
                                    <td>{row?.UpdatedByName}</td>
                                    <td>{dateFormatter(row?.UpdatedOn)}</td>
                                    {hasAdminAccess && <td className='editdelete-cls'>{editColumnFormatter(row?.Status, row)}</td>}
                                </tr>
                            ))
                        ) : (
                            <tr> <td colSpan="17">No Data Available</td></tr>
                        )}
                    </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center mt-0">
                        Showing rows {((pageNumber - 1) * sizePerPage) + 1} to {Math.min(pageNumber * sizePerPage, totalSize)} of {totalSize}

                        <Pagination className="d-flex justify-content-end mb-4">
                            <Pagination.Prev
                                disabled={pageNumber === 1}
                                onClick={() => handlePageChange(pageNumber - 1)}
                            />
                            {getPaginationItems()}
                            <Pagination.Next
                                disabled={pageNumber === totalPages}
                                onClick={() => handlePageChange(pageNumber + 1)}
                            />
                        </Pagination>
                    </div>
            </div>
        </div>
        )}    
        </div>
    );
}

export default Updates;
import { combineReducers } from "redux";
import recommendationsReducer from "./reducer_recommendations";
import globalReducers from "./global_reducers";
import adminReducers from "./admin_reducer";
import strategyReducer from "./strategies_reducer";
import detailsReducer from "./details_reducer";
import viewBuilderObj from "./admin_view_builder";
import subscriptionReducer from "./subscriptions_reducer";
import versionReducer from "./versions_reducer";
import adoptionReducer from "./adoption_reducer";
import newRecommendationTableData from "./reducer_new_recommendations";
import insightReducers from "./insights_reducer";

const appReducer = combineReducers({
	recommendationsReducer: recommendationsReducer,
	globalReducer: globalReducers,
	adminReducer: adminReducers,
	strategyReducer: strategyReducer,
	detailsReducer: detailsReducer,
	subscriptionReducer: subscriptionReducer,
	viewBuilderObj: viewBuilderObj,
	versionReducer: versionReducer,
	adoptionReducer: adoptionReducer,
	newRecommendationTableData: newRecommendationTableData,
	insightReducer: insightReducers,
});

export default appReducer;

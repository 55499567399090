import React, { useState, useEffect } from 'react';
import { Link ,withRouter} from 'react-router-dom';
import { LeftArrowIcon, TrashIcon, TickIcon } from '../../components/Icons'
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getVersionData, updateCurrentVersionData, getVersionsList, createNewVersionHistory, updateVersionHistory, deleteVersionHistory } from '../../actions';
import BarLoader from "react-spinners/BarLoader";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useOktaAuth } from '@okta/okta-react';
import { ToastContainer, toast } from 'react-toastify';
const CreateAndEditVersion = (props) => {
  const [loading, setLoading] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [versionData, setversionData] = useState(JSON.parse(localStorage.getItem("versionData")));
  const editorConfiguration = {
    height: "100vh",
    placeholder: 'Type the content here!'
  };
  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const deleteVersion = () => {
  const {project, subproject} = props;
    if (versionData['versionHistoryId'] !== '') {
      props.deleteVersionHistoryAPI(versionData, (res, rej) => {
        if (res.data) {
          if (res.data.responseType === 'success') {
            toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
            props.getVersionsListAPI()
            props.updateCurrentVersionDataAPI({ versionHistoryId: '', versionHistoryName: '', createdBy: '', updatedOn: '', versionHistoryHtml: '' });
            props.history.push(`/${project.toLowerCase()}/${subproject.toLowerCase()}/'/admin/versions'`);
          }
        }
        setLoading(false);
      })
    }
  }
  const handleSavingData = () => {
  const {project, subproject} = props;
    setLoading(true);
    if (versionData.versionHistoryId) {

      let obj = {
        ...versionData, updatedBy: userInfo.email, 

        "totalPatches": 5
      };
      setversionData({ ...versionData, updatedBy: userInfo.email });
      props.updateVersionHistoryAPI(obj, (res, rej) => {
        if (res.data) {
          if (res.data.responseType === 'success') {
            toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
          }
        }
        setLoading(false);
      })
    }
    else {
      props.createNewVersionHistoryAPI({
        totalPatches: 10,
        versionHistoryHtml: versionData.versionHistoryHtml,
        createdBy: userInfo.email,
      }, (res, rej) => {
        if (res) {
          if (res.data) {
            if (res.data.responseType === 'success') {
              toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
              props.history.push(`/${project.toLowerCase()}/${subproject.toLowerCase()}/'/admin/versions'`)
            }
          }

          setLoading(false);
        } else {
          setLoading(false);

        }

      });
    }
  }
  const renderLoader = () => {
    if (loading) {
      return (
        <div className="loader-wraper">
          <div className='loader'>
            <BarLoader
              size={150}
              color={"#123abc"}
              height={4}
              width={100}
              loading={loading}
            />
          </div>
        </div>
      )
    }
  }
  const discardChanges = () => {
    const {project, subproject} = props;
    if (window.confirm(`Are you sure, you want to discard unsaved change(s)`)) {
      if (props.selectedTab) {
        props.resetButton()
      }
      else {
        props.history.push(`/${project.toLowerCase()}/${subproject.toLowerCase()}/'/admin/versions'`)

      }
    }

  }
  return (
    <div id="details_wrapper">
      <ToastContainer />
      {renderLoader()}
      <div className="container-fluid  default-top  bg-gray p-5 full-screen-height ">
        <div className="col-12 px-0 widget-nav" style={{ 'paddingTop': '10px', 'paddingBottom': '14px' }} id="builder_screen_header">
          <div>
            <Link to={`/${props.project}/${props.subproject}/admin/versions`}> <LeftArrowIcon /> All Version History</Link>
          </div>
          <div className="d-flex justify-content-between" >
            {props.type === "edit" ?
              <div className="header">Version {versionData.versionHistoryName}</div>
              : <div className="header">New Version </div>}

            <div >
              <button className="btn btn-apply mx-2" onClick={(e)=>{
                handleSavingData();
              }}> <TickIcon /> Save changes</button>
              {props.type === "edit" ? <button className="btn btn-delete mx-2" onClick={(e) => {
                deleteVersion();
              }}> <TrashIcon /> Delete version</button> : null}

            </div>
          </div>
        </div>
        <div className="App" style={{ "height": "600px" }}>
          <CKEditor
            editor={ClassicEditor}
            config={editorConfiguration}
            data={versionData.versionHistoryHtml}
            onInit={editor => {

            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setversionData({ ...versionData, versionHistoryHtml: data })
            }}
            onBlur={(event, editor) => {

            }}
            onFocus={(event, editor) => {

            }}
          />
        </div>
      </div>
    </div>
  )
}
function mapStateToProps(state) {
  let versionData = _get(state, 'versionReducer.currentVersionData', { versionHistoryId: '', versionHistoryName: '', createdBy: '', updatedOn: '', versionHistoryHtml: '' });
  let project = _get(state, 'globalReducer.project', "");
	let subproject = _get(state, 'globalReducer.subproject', "");
  return ({
    versionData: versionData,
    project,
    subproject


  })
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createNewVersionHistoryAPI: createNewVersionHistory,
    getVersionDataAPI: getVersionData,
    updateVersionHistoryAPI: updateVersionHistory,
    deleteVersionHistoryAPI: deleteVersionHistory,
    getVersionsListAPI: getVersionsList,
    updateCurrentVersionDataAPI: updateCurrentVersionData
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateAndEditVersion));

import React, { useEffect, useState ,useRef} from "react";
import {
  getRecommendationTableContent,
    selectRecommendation,
  storeIsFetching,
  recommendationTableSelectedRows,
  recommendationTableSelectAllProductList,
  storeTableAllDataLoaded,
  updateRecommendationTableContent,
  updateSkuCountData,
  getAlertsActionReasonAndSnooze
} from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";
import "./new-recommendation.css";
import { css } from '@emotion/react';
import BarLoader from "react-spinners/BarLoader";
import NewRecommendationModal from "./NewRecommendationModal";
import { getCookie, PriceFormatter, getRpcEnabledRetailers, decimalConfigRetailerPriceFormat } from "../../helper";
import cloneDeep from "lodash/cloneDeep";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import $ from "jquery";
import AlertLogModal from "../../components/AlertLogModal";
import NoImageeCompass from '../../containers/Insights/NoImageeCompass.jpg';
import { Buffer } from 'buffer';

const ProductTable = () => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tableWidth, setTableWidth] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [activeRowForModel, setActiveRowForModel] = useState({});
  const [activeStrategy, setActiveStrategy] = useState(null);
  const [showAllStrategy, setShowAllStrategy] = useState(true);
  const [isProductLevel, setIsProductLevel] = useState(false);
  let [displayAlertLogModal, setDisplayAlertLogModal] = useState(false);
  const [alertLogModalDetails, setAlertLogModalDetails] = useState({});
  const [allSkuId, setAllSkuId] = useState([])
  const [allSkuSelected, setAllSkuSelected] = useState(false)
  

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  let { selectedGlobalFilters, project, subproject ,isSnoozeSubProject, selectedRetailerCurrency, selectedCustomerKey, dynamicColumnPrecision, 
    dynamicPriceFormatting, defaultAlertFilterCondition, getDecimalRetailersData } = useSelector((state) => state.globalReducer);
  let {
    recommendationSelectedStrategy,
    selectedStrategies,
    detailsForAllStrategies,
    selectedStrategyDateRange,
    strategyCustomDates,
    selectedFilterCondition
  } = useSelector((state) => state.strategyReducer);
  
  let {
    loadStatus,
    responseData,
    selectedRows,
    sortDirecetion,
    sortedColumn,
    curentIndex,
    selectAllProductList,
    setOfDynamicColumns,
    allSkuIds
  } = useSelector((state) => state.newRecommendationTableData);

  //loader
  let { newRecoSearchText,isExpandTableButtonClicked ,isLoadedAll,isFetchingData} = useSelector(
    (state) => state.newRecommendationTableData
  );
let requestObject= {
  filterValues: selectedGlobalFilters,
  recommendationFilters: selectedStrategies,
  curentIndex: 0,
  selectedTab: recommendationSelectedStrategy ? recommendationSelectedStrategy.selection : 'active',
  searchText: newRecoSearchText,
  sortDirecetion: "desc",
  sortedColumn:  isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" ? ["focusAlertEstimation","estimatedRevenueImpact", "daysSinceLastActioned"] : ["focusAlertEstimation","estimatedRevenueImpact"] ,
  filterName: "",
  selectedDateRange:selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
  alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition,
};

let snoozeActiveColumnValueKey = 
[
{displayName:'Actioned By', columnKey: 'actionedBy', sort: false},
{displayName:'Last Actioned Date',columnKey: 'lastActionedDate', sort: true},
{displayName:'Days since Last Actioned', columnKey: 'daysSinceLastActioned', sort: true},
{displayName:'Snooze Days Left', columnKey: 'snoozeDaysLeft', sort: false},
]

const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
      if (ref.current) {
        setSticky(ref.current.getBoundingClientRect().top <= 189);

      }
    };

    useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
   isSnoozeSubProject && selectedStrategies && selectedStrategies.length && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "active" && dispatch(getAlertsActionReasonAndSnooze({alertName: selectedStrategies.toString()}))
  }, [selectedStrategies]);

  function handleScrollForTable(e) {
    const bottom  = e.target.scrollHeight - e.target.scrollTop < (e.target.clientHeight+20);
    if(bottom && responseData.productList && responseData.productList.length !== responseData.totalCount){
      if(!isFetchingData && !isLoadedAll){
        dispatch(storeIsFetching(true))
        let requestObject= {
          filterValues: selectedGlobalFilters,
          recommendationFilters: selectedStrategies,
          currentIndex: curentIndex,
          selectedTab: recommendationSelectedStrategy && recommendationSelectedStrategy.selection ? recommendationSelectedStrategy.selection : "active",
          searchText: newRecoSearchText,
          sortDirecetion: sortDirecetion,
          sortedColumn: sortedColumn,
          filterName: "",
          selectedDateRange:selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
          alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition,
        };
        dispatch(
          getRecommendationTableContent(requestObject)
        )
          .then((data) => {
            if(data.data){
              if(Object.keys(data.data.responseData).length>0){

                  let tempResponseData = cloneDeep(responseData)
                  data.data.responseData.productList.map(item=>{
                    let productKey = Object.keys(item)
                    tempResponseData.productList.push(item)
                    item[productKey].productdetails.selectedProduct = allSkuId.length >= 10
                    item[productKey].storeList.map(storeItem => {
                      storeItem['locationSelected'] = allSkuId.length >= 10
                    })
                  })
                  tempResponseData.curentIndex = data.data.responseData.curentIndex;
                  dispatch(updateSkuCountData({currentCount:tempResponseData.productList.length,totalCount:tempResponseData.totalCount }))
                  dispatch(updateRecommendationTableContent({curentIndex:data.data.responseData.curentIndex, responseData:tempResponseData}))
              }
              else{
              dispatch(storeTableAllDataLoaded(true)) 
              }
            }
             dispatch(storeIsFetching(false))  
          })
          .catch((err) => {
            setLoading(true);
          });

      }
    }
  }

  useEffect(() => {
    let width= $('.new-recomm-container').width()
    if(width !== tableWidth){
      if(setOfDynamicColumns && setOfDynamicColumns.length < 4)
      width= width+30;
      setTableWidth(width)
    }
  })

  useEffect(() => {
    setLoading(true);
    dispatch(updateSkuCountData(  {currentCount:0,totalCount:0 })) 
    if (selectedStrategyDateRange) { 
    dispatch(
      getRecommendationTableContent(requestObject)
    )
      .then((data) => {   
        setAllSkuSelected(false)   
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
      dispatch(recommendationTableSelectedRows({}));
      dispatch(recommendationTableSelectAllProductList([]))
    }
  }, [
    selectedStrategies,
    selectedGlobalFilters,
    newRecoSearchText,
    selectedStrategyDateRange,
    strategyCustomDates,
    selectedFilterCondition
  ]);


  let displayLoader = () => {
    return (
      <div id="strategy_wrapper">
        <div className="container-fluid  default-top loading_wrapper  bg-gray p-5">
          <div className="sweet-loading">
            <BarLoader
              css={override}
              size={150}
              color={"#123abc"}
              height={4}
              width={100}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  };

  function handleStrategyClick(store, strategyClicked) {
    setActiveRowForModel(store);
    setActiveStrategy(strategyClicked);
    dispatch(selectRecommendation(store));
    setDisplayModal(true);
  }
  function displayModalOff() {
    if (displayModal) {
      setDisplayModal(false);
    }
  }
  function setCheckBox(store, type,key) {
    if ( selectedRows.constructor === Object) {
      if(type==='selectAll'){
        if(selectedRows[key] &&  Object.keys(selectedRows[key]).length === store.length){
          return true;
        }
          return false;

      }
      if (selectedRows[store.productId] && selectedRows[store.productId][store.fcNameId]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  function getEstimatedValue(data){
    let final={};
    selectedStrategies.map(strategy =>{
     data
     .filter((item) => {
       return (
         item.recommendationName.toLowerCase() ===
         strategy.toLowerCase()
       );
     })
     .map((obj) => {
       final[strategy] = obj.estimatedValue;
     });
   })
   return final;
  }

  function handleCheckBoxChange(event, productDetails, store, type, selectionType, skus) {
    skus = selectionType == 'allSku' ? skus : getCookie("selected-sku-id") ? getCookie("selected-sku-id") : []
    let tempSelectedRows = cloneDeep(selectedRows);
    let tempSelectAllProductList = [...selectAllProductList]
    let rows = {}
    if(store.length > 0) {
      productDetails.selectedProduct = event.target.checked && type === 'selectAll'
      if(event.target.checked && type === 'selectAll' && selectionType === 'allLocation') {
        skus.push(productDetails.productId)
        setAllSkuId(skus)
        storeSelectedSkuId(skus)
        setAllSkuSelected(allSkuIds.length === skus.length)
        delete tempSelectedRows[productDetails.productId]
      } else if(!event.target.checked && type === 'selectAll' && selectionType === 'allLocation') {
        skus = skus.filter(id => id != productDetails.productId)
        setAllSkuId(skus)
        storeSelectedSkuId(skus)
        setAllSkuSelected(allSkuIds.length === skus.length)
        delete tempSelectedRows[productDetails.productId]
      }
      store.map(storeItem => {
        storeItem.locationSelected = event.target.checked
        tempSelectAllProductList.push(storeItem.productId)
      })
      dispatch({
        type: "SELECTED_SKU_IDS",
        payload: skus
      })
    }else {
      let rows = {}
      let locationCount = 0
      if(event.target.checked) {
        let data = {
          location: store.fcNameId,
          product_id: store.productId,
          upc: store.asin,
          oldInfo: store.actionTakenInfo,
          dateOfRecommendation:store.dateOfRecommendation,
          estimatedVal: getEstimatedValue(store.recommendationInfo)
        };
        rows[store.fcNameId] = data;

        let filteredProduct = responseData.productList.filter(product1 => Object.keys(product1)[0] === productDetails.productId)
        if(filteredProduct && filteredProduct.length) {
          filteredProduct.forEach(prod => {
            prod[productDetails.productId].storeList.forEach(storeItem => {
              if(storeItem.locationSelected) {
                let data = {
                  location:storeItem.fcNameId,
                  product_id:storeItem.productId,
                  upc:storeItem.asin,
                  oldInfo:storeItem.actionTakenInfo,
                  dateOfRecommendation:storeItem.dateOfRecommendation,
                  estimatedVal: getEstimatedValue(storeItem.recommendationInfo),
                };
                rows[storeItem.fcNameId] = data
              }
              if(storeItem.fcNameId !== store.fcNameId && !storeItem.locationSelected)
                locationCount++
            })
            productDetails.selectedProduct = locationCount >= 1 ? false : true
            if(locationCount >= 1) {
              tempSelectedRows[productDetails.productId] = rows              
            }else {
              delete tempSelectedRows[store.productId]
              skus.push(productDetails.productId)
              setAllSkuId(skus)
              storeSelectedSkuId(skus)
              setAllSkuSelected(allSkuIds.length === skus.length)
              dispatch({
                type: "SELECTED_SKU_IDS",
                payload: skus
              })
            }
          })
        }
      }else {
        let rows = {}
        productDetails.selectedProduct = false
        let filteredProduct = responseData.productList.filter(product1 => Object.keys(product1)[0] === productDetails.productId)
        if(filteredProduct && filteredProduct.length) {
          filteredProduct.forEach(prod => {
            prod[productDetails.productId].storeList.forEach(storeItem => {
              if(storeItem.locationSelected && storeItem.fcNameId !== store.fcNameId) {
                let data = {
                  location:storeItem.fcNameId,
                  product_id:storeItem.productId,
                  upc:storeItem.asin,
                  oldInfo:storeItem.actionTakenInfo,
                  dateOfRecommendation:storeItem.dateOfRecommendation,
                  estimatedVal: getEstimatedValue(storeItem.recommendationInfo),
                };
                rows[storeItem.fcNameId] = data
              }
            })
          })
        }
        if(Object.keys(rows).length){
          tempSelectedRows[productDetails.productId] = rows
        }else
          delete tempSelectedRows[productDetails.productId]
        skus = skus.filter(id => id != productDetails.productId)
        setAllSkuId(skus)
        storeSelectedSkuId(skus)
        setAllSkuSelected(false)
        dispatch({
          type: "SELECTED_SKU_IDS",
          payload: skus
        })
      }
      store.locationSelected = event.target.checked
      tempSelectAllProductList.push(store.productId)
    }
    if(!event.target.checked && tempSelectAllProductList && tempSelectAllProductList.length) {
      tempSelectAllProductList = tempSelectAllProductList.filter(id => id != productDetails.productId)
    }
    tempSelectAllProductList = tempSelectAllProductList.filter((value, index) => tempSelectAllProductList.indexOf(value) === index)
    dispatch(recommendationTableSelectedRows(tempSelectedRows))
    dispatch(recommendationTableSelectAllProductList(Object.keys(tempSelectedRows).length <= 0 && skus.length <=0 ? [] : tempSelectAllProductList))
  }

  function storeSelectedSkuId(skus) {
    document.cookie = `selected-sku-id=${JSON.stringify(skus)};path=/;max-age=3600`
  }

  function reorderColumnData(columnName){
    let direction = 'asc';
      if(sortedColumn && (sortedColumn.indexOf(columnName) > -1 )){
        if(sortDirecetion==='asc'){
          direction='desc'
        }
        else if(sortDirecetion==='desc'){
          direction='asc'
        }

      }
      let requestObject= {
        filterValues: selectedGlobalFilters,
        recommendationFilters: selectedStrategies,
        curentIndex: 0,
        selectedTab: recommendationSelectedStrategy ? recommendationSelectedStrategy.selection : 'active',
        searchText: newRecoSearchText,
        sortDirecetion: direction,
        sortedColumn: [columnName],
        filterName: "",
        selectedDateRange:selectedStrategyDateRange !== 'Default' ? strategyCustomDates : '',
        alertFilterCondition: selectedFilterCondition ? selectedFilterCondition : defaultAlertFilterCondition,
      };
      dispatch(
        getRecommendationTableContent(requestObject)
      )
        .then((data) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(true);
        });


  }
  function renderSortDirection(columnName){
    if(sortedColumn && (sortedColumn.indexOf(columnName) > -1 )){

        if(sortDirecetion==='asc'){
          return(<i className="fas fa-sort-up  default-sort-icon active"></i>)
        }
        else if(sortDirecetion==='desc'){
            return(<i className="fas fa-sort-down  default-sort-icon active"></i>)
        }

    }
    else{
      return(<i className="fas fa-sort default-sort-icon"></i>)
    }

  }
  function renderStrategiesModelWindow() {
    if (displayModal) {
      let user_info = localStorage.getItem("user-info");
      let reportId = "";
      detailsForAllStrategies && detailsForAllStrategies.map((strategy, index) => {
        if (
          strategy.strategyName.toLowerCase() ===
          activeStrategy.recommendationName.toLowerCase()
        ) {
          reportId = strategy.strategyReportId;
        }
      });
      return (
        <NewRecommendationModal
          asin={activeRowForModel["asin"]}
          recommendationName={activeStrategy["recommendationName"]}
          chosenProductId={activeRowForModel["productId"]}
          chosenLocation={activeRowForModel["location"]}
          chosenSource={activeRowForModel.channel}
          reportId={reportId}
          chosenKcCategory={activeRowForModel["kcPlatform"]}
          chosenCustomerKey={selectedCustomerKey}
          user={JSON.parse(user_info).email}
          displayModalOff={displayModalOff}
          listOfStrategiesInfo={activeRowForModel["recommendationInfo"]}
          activeRowForModel = {activeRowForModel}
        />
      );
    }
  }

  function renderTooltip(dataToDisplay) {
    return (
      <Tooltip id="info-tooltip">{PriceFormatter(dataToDisplay, { precision: 0 , symbol: selectedRetailerCurrency})}</Tooltip>
    );
  }
 function renderStrategies(recommendationInfo, store) {
    if (recommendationInfo.length > 0) {
      return recommendationInfo.map((strategy, index) => {
        let actionTakenClass = "recomm-name";
        if (
          store.actionFlagInfo &&
          store.actionFlagInfo.indexOf(strategy.recommendationName) > -1
        ) {
          actionTakenClass = "recomm-name2";
        }
        if (!showAllStrategy && index === 0) {
          return (
            <OverlayTrigger
            placement="right"
              overlay={
                renderTooltip(strategy.estimatedValue)
              }
            >
             {strategy.recommendationName === 'Best Seller Share' ? <div className={actionTakenClass} style={{cursor:"default"}}>
            {strategy.recommendationName}
          </div>:
              <div
                className={actionTakenClass}
                onClick={(e) => handleStrategyClick(store, strategy)}
              >
                {strategy.recommendationName === 'Competitor Out Of Stock' ? 'Competition Out Of Stock' : strategy.recommendationName}
              </div>}
            </OverlayTrigger>
          );
        } else if (showAllStrategy) {
          return (
            <OverlayTrigger
            placement="right"
              overlay={
                renderTooltip(strategy.estimatedValue)
              }
            >
              {strategy.recommendationName === 'Best Seller Share' ? <div className={actionTakenClass} style={{cursor:"default"}}>
            {strategy.recommendationName}
          </div>:<div
            className={actionTakenClass}
            onClick={(e) => handleStrategyClick(store, strategy)}
          >
            {strategy.recommendationName === 'Competitor Out Of Stock' ? 'Competition Out Of Stock' : strategy.recommendationName}
          </div>} 
              </OverlayTrigger>

          );
        }
      });
    }
  }
  function renderStrategiesContainer(recommendationInfo, store){
    if(showAllStrategy){
      return(
        <div className='recomm-col strategies expanded'>
          {renderStrategies(store.recommendationInfo, store)}
        </div>
      )
    }

  }
  function handleStrategyColumnClick(){
    if(showAllStrategy){
      setShowAllStrategy(false)
    }
    else{
      setShowAllStrategy(true)
    }
  }
  function renderDynamicColumnValues(store){
    if(setOfDynamicColumns && setOfDynamicColumns.length>0){
      return setOfDynamicColumns.map((col,index)=>{        
        let tempVal = store[col.Metric_Display_Value];
        let tempType = (col.Type).toLowerCase();
        let alertName =  col.Strategy.toLowerCase().replace(/ /g,"_");     
        let metricName = col.Metric_name.toLowerCase().trim(); 
        if((dynamicPriceFormatting && dynamicPriceFormatting === 'dot') && tempVal) {
          if(tempType ==='dollar'){
            tempVal = decimalConfigRetailerPriceFormat(tempVal,selectedRetailerCurrency);
          }
          else if(tempType ==='double'){ 
            tempVal = PriceFormatter((tempVal),  {precision: 2, symbol:''}).replace(/(\.|,)/g, (x)=> {
              return x == ',' ? '.' :  x == '.' ? ',' : '';});
          }
          else if(tempType==='percentage'){
            tempVal = PriceFormatter((tempVal),  {precision: 2, symbol:'%', format:'%v %s' }).replace(/(\.|,)/g, (x)=> {
              return x == ',' ? '.' :  x == '.' ? ',' : '';})
          }
        }else if(dynamicColumnPrecision && dynamicColumnPrecision[alertName] && (dynamicColumnPrecision[alertName][metricName] || dynamicColumnPrecision[alertName][metricName] === 0)) {                
          if(tempType==='dollar'){
            tempVal = PriceFormatter(tempVal, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol : selectedRetailerCurrency})
          }
          else if(tempType==='percentage'){
            tempVal = PriceFormatter((tempVal),  {precision: dynamicColumnPrecision[alertName][metricName], symbol:'%', format:'%v %s' })
          }
          else {
            tempVal = PriceFormatter((tempVal),  {precision: dynamicColumnPrecision[alertName][metricName], symbol:'' })
          }   
        }else{     
          if(tempType==='dollar'){
            tempVal = PriceFormatter(tempVal, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol : selectedRetailerCurrency})
          }
          else if(tempType==='double'){
            tempVal = PriceFormatter(tempVal, { precision: 2,symbol:'' })
          }
          else if(tempType==='percentage'){
            tempVal = PriceFormatter((tempVal),  {precision: 1, symbol:'%', format:'%v %s' })
          }
        }
        return(
          <div className="recomm-col txt-right-aligned">
              {tempVal}
          </div>
        )
                
      })
    }
  }

  function renderActiveSnoozeColumnValues(store){
      return snoozeActiveColumnValueKey.map((col,index)=>{        
         return(
            <div className="recomm-col txt-right-aligned">
                {store[col.columnKey]}
            </div>
          )
      })
    
  }
  function renderSnoozeCommonColumnValue(store,productDetails){
         return(
           <>
            <div className={`recomm-col align-items-center ${ store["previouslyActioned"].toLowerCase() === "yes" ?"previously-actioned" : "disable-click"}`} 
            onClick={(e)=>{
              let finalProductDetails = {...productDetails}
              finalProductDetails["location"] = store.fcNameId
              setAlertLogModalDetails(finalProductDetails)
              setIsProductLevel(false)
              setDisplayAlertLogModal(true)
            }}>
                {store["previouslyActioned"]}
            </div>
             
            <div className="recomm-col txt-right-aligned" >
                {store["alertTriggerDate"]}
            </div>
            {recommendationSelectedStrategy && recommendationSelectedStrategy.activeRowDetails ? ( <>
            <div className="recomm-col txt-right-aligned" >
                {store["daysSinceAlertTrigger"]}
            </div> </> ) : null }
            </>
          )
    
  }
  function downloadPriceDifferenceDetails(store, selectedCustomerKey, selectedGlobalFilters, recommendationSelectedStrategy ) {
    let reqObj = {
      projectName: project,
      subprojectName: subproject,
      productId: store.productId,
      customerKey: selectedCustomerKey,
      filterValues: selectedGlobalFilters
    }
    let bufferValue = Buffer.from(JSON.stringify(reqObj));
    let base64data = bufferValue.toString("base64");
    let expresspricedetails = recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === 'Price Index' ? 'exportPriceIndexReport' : 'exportPriceDifferenceDetails';
      window.open(
        `${process.env.REACT_APP_ROOT_URL}/download/${expresspricedetails}/${base64data}`,
        "_blank"
      );    
  }
  function renderStoreList(storeList,productDetails) {
    if (storeList.length > 0) {
      return storeList.map((store) => {
        let tempClassForLocationRow = "locations";
        if (setCheckBox(store)) {
          tempClassForLocationRow = "locations active";
        }
        return (
          <div className={tempClassForLocationRow}>
            <div className="recomm-col location_block">
              <div className="form-check form-check-custom">
                <input
                  className="form-check-input custom"
                  type="checkbox" disabled={!selectedStrategies?.length}
                  checked={store.locationSelected}
                  onChange={(event) => handleCheckBoxChange(event, productDetails, store,'single', 'location')}
                />
                <label className="form-check-label" for="all">
                  {store.fcNameId}
                </label>
              </div>
            </div>
            {recommendationSelectedStrategy &&  recommendationSelectedStrategy.activeRowDetails ?
              <div className="recomm-col txt-right-aligned">
                {store.focusAlertEstimation === 0 ? 'N/A' : (dynamicPriceFormatting && dynamicPriceFormatting === 'dot') && store.focusAlertEstimation ? 
                decimalConfigRetailerPriceFormat(store.focusAlertEstimation,selectedRetailerCurrency):
                PriceFormatter(store.focusAlertEstimation, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol: selectedRetailerCurrency})}
                </div> : null } 
              {isSnoozeSubProject && renderSnoozeCommonColumnValue(store,productDetails)}
              {isSnoozeSubProject &&  recommendationSelectedStrategy && recommendationSelectedStrategy.selection==="actioned" && renderActiveSnoozeColumnValues(store)}
              {isSnoozeSubProject &&  recommendationSelectedStrategy && recommendationSelectedStrategy.selection==="actioned" && <div className="recomm-col align-items-center 3 col-reason text-break" >
                {store["reason"]}
            </div> }
            <div className="recomm-col txt-right-aligned">{(dynamicPriceFormatting && dynamicPriceFormatting === 'dot') && store.estimatedRevenueImpact ? 
                decimalConfigRetailerPriceFormat(store.estimatedRevenueImpact,selectedRetailerCurrency):
                PriceFormatter(store.estimatedRevenueImpact, { precision: getDecimalRetailersData?.DECIMAL_PRECISION, symbol: selectedRetailerCurrency})}</div>
            {renderStrategiesContainer(store.recommendationInfo, store)}
            {renderDynamicColumnValues(store)}
            {(recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === "Retailer Price Difference" || recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === "Price Index") &&
              <div className="recomm-col txt-right-aligned" onClick={() => { downloadPriceDifferenceDetails(store, selectedCustomerKey, selectedGlobalFilters , recommendationSelectedStrategy ) }} style={{color:"#1563ff",cursor: "pointer"}}>Download report </div>
            } 
            
          </div>
        );
      });
    }
  }
  function renderStrategyArrow(){
    if(showAllStrategy){
      return(<i className="fas fa-angle-left  default-sort-icon active"></i>)
    }
    else{
      return(<i className="fas fa-angle-right  default-sort-icon "></i>)
    }
  }
  function renderProductLinks(productDetails) {
    if (productDetails.links.length > 0) {
      return productDetails.links.map((item) => {
        return (
          <li>
            <a href={item.hrefLink} target="_blank">
              {item.text}
            </a>
          </li>
        );
      });
    }
  }
  function renderEmptyColumns(){
    if(setOfDynamicColumns && setOfDynamicColumns.length>0){
      return setOfDynamicColumns.map((col,index)=>{
          return(<div className="recomm-col"></div>)
      })
    }
  }
  function renderFetchingMoreItems(){
    if(isFetchingData && !isLoadedAll){
    return(<div className='fetchingMoreItem'>
        <img src={require('./loadmore.gif')} className='fetechMoreImg' />
        Fetching more products...
    </div>)
    }
  }
  let renderEmptySnoozeCols = () =>{
    return snoozeActiveColumnValueKey.map((col,index)=>{
      return(<div className="recomm-col"></div>)
  })
  }

  function updateProduct(event, productDetails) {
    productDetails['selectedProduct'] = event.target.checked
    delete selectedRows[productDetails.productId]
    dispatch(recommendationTableSelectedRows(selectedRows))
    let skus = getCookie("selected-sku-id") ? getCookie("selected-sku-id") : []
    if(event.target.checked) {
      skus.push(productDetails.productId)
      setAllSkuId(skus)
      storeSelectedSkuId(skus)
      setAllSkuSelected(allSkuIds.length === skus.length)
    }else{
      skus = skus.filter(id => id != productDetails.productId)
      setAllSkuId(skus)
      storeSelectedSkuId(skus)
      setAllSkuSelected(false)
    }
    dispatch({
      type: "SELECTED_SKU_IDS",
      payload: skus
    })
  }

  function updateAllSKU(event) {
    let skus = event.target.checked ? allSkuIds : []
    selectedRows = {}
    dispatch(recommendationTableSelectedRows({}))
    setAllSkuId(skus)
    storeSelectedSkuId(skus)
    dispatch({
      type: "SELECTED_SKU_IDS",
      payload: skus
    })
    setAllSkuSelected(event.target.checked)
    responseData.productList.map(product => {
      Object.keys(product).map(key => {
        product[key]['productdetails']['selectedProduct'] = event.target.checked
        handleCheckBoxChange(event, product[key]['productdetails'], product[key]['storeList'], 'selectAll', 'allSku', skus)
      })
    })
  }


  function renderRecommmendationTableRows() {    
    if (loadStatus && loadStatus === "loaded") {
      return  responseData && responseData.productList && responseData.productList.map((product) => {
        return Object.keys(product).map((key) => {
          let productDetails = product[key]["productdetails"];
          let productnamesubsring = productDetails.name ? productDetails.name.substring(0,70) : "",
          recomproductImage = productDetails?.imagelink ? productDetails.imagelink : NoImageeCompass;
          return (
            <div className={setOfDynamicColumns && setOfDynamicColumns.length === 0 ? "recomm-row recomm-dynamiccol" : "recomm-row"}>
              {/* First Column - product Column */}
                <div className={productDetails?.sap ? "recomm-product rem-product" : "recomm-product"}>
                  <div className="product-box">
                    <input className="product_imagecheck" 
                           type="checkbox" disabled={!selectedStrategies?.length}
                           checked={productDetails.selectedProduct}
                           onChange={(e) => {
                             updateProduct(e, productDetails)
                             handleCheckBoxChange(e, productDetails, product[key]['storeList'],'selectAll','sku');
                           }}
                     />
                    <div className="product">
                        <img
                          src={recomproductImage}
                          className="product-img"
                        />
                      </div>
                      <div className='product-sku'>
                          <div style={{paddingRight:"6px"}}><div><b>Product ID </b></div>{productDetails.productId}</div>
                          {getRpcEnabledRetailers().includes(subproject) && 
                          <div style={{paddingRight:"6px"}}><div><b>RPC </b></div> {productDetails?.rpc ? productDetails.rpc : 'N/A'}</div> 
                          }
                          <div><div><b>UPC </b></div> {productDetails.upc}</div>
                          {productDetails?.sap &&
                          <div style={{paddingTop:"6px"}}><div><b>Material Code </b></div> {productDetails.sap}</div>
                          }
                      </div>
                      <div className="product-name">
                      
                      {productDetails.name && productDetails.name.length > 70 ? (<OverlayTrigger placement="right" overlay={<Tooltip id="tooltipproduct-disabled">{productDetails.name}</Tooltip>}>
                      <i className="productdetail-name">{productnamesubsring}...</i>
                    </OverlayTrigger>) : productDetails.name}
                    </div>
                    <div className="product-Desc">{ productDetails.desc}</div>
                      <div className="product-Links">
                        <ul>{renderProductLinks(productDetails)}</ul>
                      </div>
                  </div>
                </div>
              {/* Recomm stores*/}

              <div className={setOfDynamicColumns && setOfDynamicColumns.length === 0 ? "recomm-locations recomm-dynamiccol recomm-locations-overflow" : "recomm-locations recomm-locations-overflow"}>
                <div className="locations">
                  <div className="recomm-col location_block">
                    <div className="form-check form-check-custom">
                      <input
                        className="form-check-input custom"
                        type="checkbox" disabled={!selectedStrategies?.length}
                        checked = {productDetails.selectedProduct}
                        onChange={(event)=>handleCheckBoxChange(event, productDetails, product[key]['storeList'],'selectAll', 'allLocation')}
                      />
                      <label className="form-check-label" for="all">
                      Select All Locations 
                      </label>
                    </div>
                  </div>
                  {recommendationSelectedStrategy && recommendationSelectedStrategy.activeRowDetails &&
                  <div className="recomm-col"></div> }
                  {/* intergration needed */}
                
                  {isSnoozeSubProject && 
                  <div className={`recomm-col align-items-center ${productDetails.previouslyActioned.toLowerCase()==="yes"? "previously-actioned" : "disable-click"}`} 
                  onClick={(e)=>
                  {
                      setIsProductLevel(true)
                      setAlertLogModalDetails(productDetails)
                      setDisplayAlertLogModal(true)
                                      
                  }}>
                  {productDetails.previouslyActioned} </div> 
                  }
                 <div className="recomm-col"></div>
                 <div className="recomm-col"></div>
                  {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && renderEmptySnoozeCols()}
                  {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && <div className="recomm-col col-reason"></div>}
                  {isSnoozeSubProject && recommendationSelectedStrategy && recommendationSelectedStrategy.activeRowDetails && <div className="recomm-col"></div>}
                  {renderRecommmendationEmptyCol()}
                  {renderEmptyColumns()}
                  {(recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === "Retailer Price Difference" || recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === "Price Index") &&<div className="recomm-col"></div>}
                </div>
                {renderStoreList(product[key]["storeList"],productDetails)}
              </div>

              {/* Recomm stores end*/}
            </div>
          );
        });
      });
    }
  }
  function renderStrategiesHeader(){
    if(showAllStrategy){
       return(
         <div className="strategies"  onClick={(e)=>handleStrategyColumnClick()}>
           <div className="col-title">
             Alerts
             {renderStrategyArrow()}
           </div>
         </div>
       )
    }
   else{
     return(
      <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      popperConfig={{
       modifiers: {
       preventOverflow: {
        enabled: false,
                         },
                  },
                   }}
      overlay={
          <Tooltip id="info-tooltip">Click to see alerts</Tooltip>
        }
      >
         <div className="strategies all-collapsed "  onClick={(e)=>handleStrategyColumnClick()}>
          <div className='collapsed-icon '>
            <i className="fas fa-arrows-alt-h  default-sort-icon active"></i>
          </div>
        </div>
      </OverlayTrigger>
     )
   }
 }

 function renderRecommmendationEmptyCol(){
   if(showAllStrategy){
     return(
       <div className="recomm-col strategies"></div>
     )
   }
 }

 !isExpandTableButtonClicked  && $(function(){
  $(".wrapper1").scroll(function(){     
      $(".wrapper2")
          .scrollLeft($(".wrapper1").scrollLeft());
  });
  $(".wrapper2").scroll(function(){
      $(".wrapper1")
          .scrollLeft($(".wrapper2").scrollLeft());
  });
});
 function renderTableHeaderColumns(){
   if(setOfDynamicColumns && setOfDynamicColumns.length>0){
     return setOfDynamicColumns.map((col,index)=>{
       return(
         <div  onClick={(e)=>reorderColumnData(col.Metric_name)}>
           <div className="col-title" >
             {col.Display_name}{" "}
             {renderSortDirection(col.Metric_name)}
           </div>
         </div>
       )
     })
   }
 }
let renderSnoozeRelatedColumnsHeader = () =>{
  return(
  <> 
  <div onClick={(e)=>reorderColumnData('previouslyActioned')}>
  <div className="col-title" >
  Previously <br/>Actioned (P3M){renderSortDirection('previouslyActioned')}
  </div>
 </div> 
 
  <div onClick={(e)=>reorderColumnData('alertTriggerDate')}>
    <div className="col-title" >
    Alert Trigger Date{renderSortDirection('alertTriggerDate')}
    </div>
  </div> 

   { recommendationSelectedStrategy && recommendationSelectedStrategy?.activeRowDetails ?
 <div onClick={(e)=>reorderColumnData('daysSinceAlertTrigger')}>
  <div className="col-title" >
  Days since Alert Trigger{renderSortDirection('daysSinceAlertTrigger')}
  </div>
 </div> 
   : null}
 { recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" && 
 snoozeActiveColumnValueKey.map((item)=>{return <div onClick={(e)=> item.sort ? reorderColumnData(item.columnKey) : undefined}>
  <div className="col-title" >
  {item.displayName}{item.sort && renderSortDirection(item.columnKey)}
  </div>
 </div>
 })}
 {recommendationSelectedStrategy && recommendationSelectedStrategy.selection === "actioned" &&  <div className="col-reason" >
  <div className="col-title " >
  Reason
  </div>
 </div>} 
  </>
  )
}

let toggleAlertLogModal = () =>{
  let toggle =displayAlertLogModal
  setDisplayAlertLogModal(!toggle)
}

  if (loading) return displayLoader();
  else if (responseData.productList && responseData.productList.length<=0)
  return (<div id="no-data-available">No products available to display</div>)
  else if ((newRecoSearchText || (selectedStrategies && selectedStrategies?.length) || (recommendationSelectedStrategy && Object.keys(recommendationSelectedStrategy) && Object.keys(recommendationSelectedStrategy).length)) && responseData.productList && responseData.productList.length>0)
    return (
      <div className={isExpandTableButtonClicked ? "full-screen-mode-table-row" : "row"} >
          {displayAlertLogModal ?  <AlertLogModal displayAlertLogModalOff={toggleAlertLogModal} displayAlertLogModal={displayAlertLogModal} isProductLevel={isProductLevel} alertLogModalDetails= {alertLogModalDetails}/>  : null }

        <div className="col-12" >
          {/* Custom code for  product table*/}
          <div style={{display:"flex",width:"100%",paddingTop:"4px"}}>
          <div className={setOfDynamicColumns && setOfDynamicColumns.length === 0 ? "form-check form-check-custom recom_horizontal recomm-dynamiccol" : "form-check form-check-custom recom_horizontal"} >
                      <input className="form-check-input custom" type="checkbox" checked={allSkuSelected} disabled={!selectedStrategies?.length}  onChange={(e) => {
                             updateAllSKU(e);
                           }}/>
                      <label className="form-check-label" for="all" style={{paddingLeft:6,position:"relative",bottom:"1px"}} >
                        Select All {responseData.totalCount} SKU(s)
                      </label>
                    </div>
         {!isExpandTableButtonClicked && <div
            className={`wrapper1 new-recomm sticky-wrapper ${setOfDynamicColumns && setOfDynamicColumns.length < 2 && !isSnoozeSubProject ? "width-auto" : ""}`}
            ref={ref}
            id="sticky"
            style={{backgroundColor:"white",height:10, overflowY:"auto",marginTop:"8px"}}
          >
            <div style={{width: tableWidth , height:16}}>  </div>
          </div> } 
          </div>
          <div className={`table-responsive wrapper2 new-recomm sticky-wrapper ${isExpandTableButtonClicked ? "full-screen-mode-table" : ""} ${isSticky ? ' sticky' : ''} ${setOfDynamicColumns && setOfDynamicColumns.length<2 && !isSnoozeSubProject?'width-auto':''}`} ref={ref} id='sticky' onScroll={(e)=>{handleScrollForTable(e)}} style={{backgroundColor:"white"}}>
            <div className="new-recomm-container">
              <div className={setOfDynamicColumns && setOfDynamicColumns.length === 0 ? "recomm-header recomm-dynamiccol" : "recomm-header"} >
                <div className="headcol first-col">Product</div>
                <div className="headcol location_block" onClick={(e)=>reorderColumnData('location')}>
                  <div>
                    Top 10 Locations 
                   {renderSortDirection('location')}
                  </div>
                </div>
                {recommendationSelectedStrategy &&  recommendationSelectedStrategy.activeRowDetails ?
                  <div onClick={(e)=>reorderColumnData('focusAlertEstimation')}>
                      <div className="col-title" >
                        Focus Alert<br/> Estimated Value{renderSortDirection('focusAlertEstimation')}
                      </div>
                  </div> : null }
                {/* hard code for snooze */}
                {isSnoozeSubProject && renderSnoozeRelatedColumnsHeader()}
                <div onClick={(e)=>reorderColumnData('estimatedRevenueImpact')}>
                   
                  <div className="col-title"  >
                    Total Estimated Value
                    {renderSortDirection('estimatedRevenueImpact')}
                  </div>
                </div>
                
                {renderStrategiesHeader()}

               {renderTableHeaderColumns()}
               {recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === "Retailer Price Difference" &&
                <div> 
                  <div className="col-title"  >
                    Price Difference Details 
                  </div>
                </div>}
                {recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === "Price Index" &&
                <div> 
                  <div className="col-title"  >
                    Price Index
                  </div>
                </div>}
              
              </div>
              <div className="recomm-body">
                {renderRecommmendationTableRows()}
              </div>
            </div>

          {renderFetchingMoreItems()}
          </div>
          {/* Custom code for  product table* ends */}
        </div>
        {renderStrategiesModelWindow()}
      </div>
    );
    else
    return(<div id="no-data-available">No products available to display</div>)
};

export default ProductTable;

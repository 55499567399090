import React, { Component } from 'react';
import BarLoader from "react-spinners/BarLoader";
import Sidebar from '../../components/Sidebar';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getAllPagesForAdmin, getSelectedView, getEmbedToken, updateViewBuilderJSON } from '../../actions';
import cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DOMPurify from 'dompurify';

// import {Report} from 'powerbi-report-component';
import {addCommonActivityCaptureDetail} from '../../helper'
class Explorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedTab: "",
      sidebarOptions: [
        { icon: "fas fa-money-check-alt", title: "Sales Overview" },
        { icon: "far fa-chart-bar", title: "Recommendations" },
        { icon: "far fa-image", title: "Content" },
        { icon: "fas fa-layer-group", title: "PO Fill Rate" }
        ,],
      filterArr: [
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "data",
            column: "Date"
          },
          operator: "In",
          values: ["23 March 2020"]
        },
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "Table",
            column: "Column1"
          },
          operator: "In",
          values: ["Last 1 week"]
        }
      ]
    }
  }
  setStartDateAndCompareToPeriod = (date, option) => {
    let arrFromState = cloneDeep(this.state.filterArr);
    arrFromState[1].values = [option];
    this.setState({ filterArr: arrFromState });
  }
  componentDidMount() {

    addCommonActivityCaptureDetail({page: "Scorecard",globalFilters:this.props.selectedGlobalFilters ,strategyFilters: this.props.selectedStrategies})
    Promise.all([
      this.props.updateViewBuilderJSONAPI({}),
      this.props.getAllPagesForAdminAPI({ projectName: "Walmart", viewStatus: 'Active', categoryName: 'Scorecard' })
    ]).then(data => {
      this.setState({
        loading: false
      })
    }).catch(err => {
      this.setState({
        loading: true
      })
    })

  }
  componentWillReceiveProps(nextprops) {
   
    document.title = nextprops.keyMapTabList ? nextprops.keyMapTabList.scorecardTab : "";
  }
  setSelectedTab = (tab, data) => {
    this.props.updateViewBuilderJSONAPI({})
    this.setState({ selectedTab: tab });
    this.props.getSelectedViewAPI({ viewId: data.data.viewId });
  }
  
  renderInsightsTab() {

  }
  renderSidebar() {
    let sidebarOptions = [];
    this.props.allPagesForAdmin.map((data, index) => {
      if (index === 0 && this.state.selectedTab === '') {
        this.props.getSelectedViewAPI({ viewId: data.viewId });
        this.setState({ selectedTab: data.viewName })
      }
      sidebarOptions.push({ icon: "", title: data.viewName, data: data })
    });


    return (
      <Sidebar setSelectedTab={this.setSelectedTab} selectedTab={this.state.selectedTab} sidebarOptions={sidebarOptions} />
    )
  }
  renderPowerBIDashboard(obj) {
    if (obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken === '') {
      this.props.getEmbedTokenAPI({
        groupId: process.env.REACT_APP_GROUP_ID,
        dashboardId: obj.reportId
      }, (res) => {
        if (res) {
          if (res.data.embedToken) {
            let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
            tempViewBuilderObj.viewBuilderJSON.htmlTemp[obj.id]['embedToken'] = res.data.embedToken;
            this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
          }
        }
      });
      return null;
    }
    else if (obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken !== '') {
      let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${obj.reportId}&groupId=${process.env.REACT_APP_GROUP_ID}`;
      embedUrl = embedUrl.replace("watch?v=", "v/");


      let arr = [];
      let arrFromProps = cloneDeep(this.state.filterArr);
      for (let i = 0; i < arrFromProps.length; i++) {
        if (arrFromProps[i].values.length > 0 && arrFromProps[i].values[0] !== undefined) {
          arr.push(arrFromProps[i]);
        }
      }
      const layoutSettings = {
        customLayout: {
          pageSize: {
            type: models.PageSizeType.Custom,
            width: 1400,
            height: 1500
          },
          displayOption: models.DisplayOption.FitToPage,
          pagesLayout: {
            ReportSection1: {
              defaultLayout: {
                displayState: {
                  mode: models.VisualContainerDisplayMode.Hidden,
                },
              },
              visualsLayout: {
                VisualContainer1: {
                  x: 1,
                  y: 1,
                  z: 1,
                  width: 900,
                  height: 300,
                  displayState: {
                    mode: models.VisualContainerDisplayMode.Visible,
                  },
                },
                VisualContainer2: {
                  displayState: {
                    mode: models.VisualContainerDisplayMode.Visible,
                  },
                },
              },
            },
          },
        },
      };
      const extraSettings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        hideErrors: false,
        background: models.BackgroundType.Transparent,
        ...layoutSettings, // layout config
      };
      const style = {
        height: "1500px",
        width: "100%",
        fontSize: "80%"
      }
      return (
        <div className="col-12 p-0 m-0" id={obj.id}>
          {/* <Report
            embedType="report" // "dashboard"
            tokenType="Embed" // "Aad"
            accessToken={obj.embedToken} // accessToken goes here
            embedUrl={embedUrl} // embedUrl goes here
            embedId={obj.reportId}
            permissions="All"
            extraSettings={extraSettings}
            style={
              style
            }
          /> */}
          <PowerBIEmbed
                        embedConfig={{
                            type: 'report',   // 'report' or 'dashboard'
                            id: obj.reportId,
                            embedUrl: embedUrl,
                            accessToken: obj.embedToken,
                            tokenType: models.TokenType.Embed,
                            settings: extraSettings
                        }}
                        eventHandlers={
                            new Map([
                                ['loaded', (event) => {
                                    const report = event.target;
                                    if (report && typeof report.setFilters === 'function') {
                                        
                                    } else {
                                        console.error('Report object does not support setFilters');
                                    }
                                }],
                                ['rendered', () => {
                                    console.log('Report rendered');
                                }]
                            ])
                        }
                        cssClassName="report-style-class"
                    />
        </div>
      )
    }

  }

  renderHtmlContent(obj) {
    if (obj.innerhtml != '' && obj.type !== 'dashboard') {
      return (<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(obj.innerhtml)}} />)
    }

    else if (obj.innerhtml != '' && obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken === '') {
      return (<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(obj.innerhtml)}} />)
    }
    else {

    }

  }

  renderChildrenOfId(id) {
    if (Object.keys(this.props.viewBuilder).length > 0) {
      if (this.props.viewBuilder.hasOwnProperty("viewBuilderJSON") && this.props.viewBuilder.viewBuilderJSON.hasOwnProperty("htmlTemp")) {
        let newJson = this.props.viewBuilder.viewBuilderJSON.htmlTemp
        if (Object.keys(newJson).length > 0) {
          let obj = newJson[id];

          const HtmlTag = DOMPurify.sanitize(`${obj.element}`);
          return (
            <HtmlTag className={obj.classDefault + ' ' + obj.classMobile + ' ' + obj.classTablet + ' ' + obj.classDesktop}
              id={obj.id} type={obj.type}
              style={obj.configOptions.style}
            >
              {this.renderHtmlContent(obj)}
              {this.renderPowerBIDashboard(obj)}
              {obj.children.length > 0 ? obj.children.map((id, index) => {
                return (
                  <React.Fragment key={index}>
                    {this.renderChildrenOfId(id)}
                  </React.Fragment>
                )
              }) : null}
            </HtmlTag>
          )
        }


      }

    } else {
      return (
        <div className="ml-4">
          No data available
        </div>
      )
    }
  }

  render() {

    return (<div>
      {this.renderSidebar()}
      {this.state.loading ? <div className=" loading_wrapper">
        <div className="sweet-loading">
          <BarLoader
            size={150}
            color={"#123abc"}
            height={4}
            width={100}
            loading={this.state.loading}
          />
        </div>

      </div> : <div className="container-fluid  default-top padd-left bg-gray">
          <div className="container-fluid  px-0  height_max">
            <div className='col-12 mt-4 p-0'>
              <div className="d-flex flex-column">
                <div>Scorecard</div>
                <div className="header">{this.state.selectedTab}</div>
              </div>
            </div>
            <div className="col-12 sections height_100 p-0 m-0" id="builder_screen_header">
              <div className="col-12 px-0 builder_window height_100" >
                <div className="sections p-0 m-0" id="preview_screen" style={{ 'backgroundImage': 'none', height: "auto" }}>
                  {this.renderChildrenOfId(0)}
                </div>
              </div>
            </div>
          </div>


        </div>}


    </div>);
  }
}

function mapStateToProps(state) {

  let allPagesForAdmin = _get(state, 'adminReducer.allAdminViews.responseData', []);
  let {selectedGlobalFilters} = _get(state, 'globalReducer', {});
  
  let selectedStrategies = _get(
    state,
    "strategyReducer.selectedStrategies",
    []
  );
  return ({
    allPagesForAdmin: allPagesForAdmin,
    viewBuilder: state.viewBuilderObj,
    selectedGlobalFilters,
    selectedStrategies,
    keyMapTabList:state.globalReducer.keyMapTabList,
  })
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllPagesForAdminAPI: getAllPagesForAdmin,
    getSelectedViewAPI: getSelectedView,
    getEmbedTokenAPI: getEmbedToken,
    updateViewBuilderJSONAPI: updateViewBuilderJSON
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Explorer);

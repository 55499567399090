import React, { useState, useEffect } from 'react';
import './Strategy.scss';
import { Modal, Table, Pagination, Form, FormControl, InputGroup } from 'react-bootstrap';
import _get from 'lodash/get';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { displayAllStrategy, insertNewStrategy, updateStrategy, deleteStrategy } from '../../actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { css } from '@emotion/react';
import BarLoader from "react-spinners/BarLoader";
import { useOktaAuth } from '@okta/okta-react';
import { addCommonActivityCaptureDetail } from '../../helper'

const AddStrategyModal = (props) => {
    const { propName, propLink, propDescription, propReportId, propStatus, propStrategyId } = props.modalDetails;
    const [addStrategyError, setStrategyError] = useState("");
    const [name, setName] = useState(propName);
    const [description, setDescription] = useState(propDescription);
    const [link, setLink] = useState(propLink);
    const [status, setStatus] = useState(propStatus);
    const [reportId, setReportId] = useState(propReportId);
    const [strategyId, setStrategyId] = useState(propStrategyId);

    const saveStrategy = (e, modalMode) => {
        e.preventDefault();
        if (name === "" || description === "" || link === "" || status === "") {
            setStrategyError("Please fill all values");
        } else {
            setStrategyError("");
            props.displayModalOff();
            if (modalMode === "Add") {
                props.insertNewStrategyAPI({ strategyName: name, strategyDesc: description, strategyUrl: link, strategyStatus: status, strategyReportId: reportId }, (res, rej) => {
                    if (res) {
                        toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                        props.displayAllStrategy();
                    }
                });
            } else {
                props.updateStrategyAPI({ strategyId: strategyId, strategyName: name, strategyDesc: description, strategyUrl: link, strategyStatus: status, strategyReportId: reportId }, (res, rej) => {
                    if (res) {
                        toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                        props.displayAllStrategy();
                    }
                });

            }

        }
    }

    let modalText = "Edit";
    if (props.modalMode === "Add") {
        modalText = "Add";
    }
    return (
        <Modal size={'md'} show={true} onHide={props.displayModalOff} id="recommendations-modal" >
            <Modal.Header closeButton>
                <Modal.Title><i className="far fa-file-alt mr-3"></i> Strategy</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">
                <div className="container ">
                    <div className="row recommendations-table">

                        <div className="col-12 my-2">
                            <form >
                                <div className="form-group">
                                    <label> Name</label>
                                    <input type="text" value={name} className="form-control" onChange={(e) => { setName(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Description</label>
                                    <input type="text" value={description} className="form-control" onChange={(e) => { setDescription(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> ReportID <small>(Optional)</small></label>
                                    <input type="text" value={reportId} className="form-control" onChange={(e) => { setReportId(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Link</label>
                                    <input type="text" value={link} className="form-control" onChange={(e) => { setLink(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Status</label>
                                    <select className="form-control" value={status} onChange={(e) => { setStatus(e.target.value); setStrategyError("") }}>
                                        <option value="" disabled>Please select a status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <p className="error">{addStrategyError}</p>
                                <button class="btn btn-apply" onClick={(e) => { saveStrategy(e, props.modalMode) }}>Save</button>
                            </form>
                        </div>



                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const Strategies = (props) => {

    const [displayModal, setDisplayModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const allStrategiesData = props.allStrategies;
    const [modalMode, setModalMode] = useState("Add");
    const [modalDetails, setModalDetails] = useState({
        propName: "",
        propDescription: "",
        propStatus: "",
        propLink: "",
        propReportId: "",
        propStrategyId: ""
    });
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const environment = process.env.REACT_APP_OKTA_ENV;

    const isAppAdmin = useSelector((state) => state.globalReducer.isAppAdmin);
    const isProjectAdmin = useSelector((state) => state.globalReducer.isProjectAdmin);
    const isSubprojectAdmin = useSelector((state) => state.globalReducer.isSubprojectAdmin);
    let { globalFilter, selectedGlobalFilters, keyMapTabList } = useSelector(
        (state) => state.globalReducer
    );
    let { selectedStrategies } = useSelector(
        (state) => state.strategyReducer
    );
    let hasAdminAccess = isAppAdmin || isProjectAdmin || isSubprojectAdmin ? true : false;
    const [searchQuery, setSearchQuery] = useState('');
    const [strategiesData, setStrategiesData] = useState(props?.allStrategies);

    const [pageNumber, setPageNumber] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const totalSize = props?.allStrategies?.length;
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const filteredData = props.allStrategies.filter(row =>
            (row.strategyName?.toLowerCase() || "").includes(searchQuery)
        );
        setStrategiesData(filteredData);
    }, [searchQuery, props.allStrategies]);

    useEffect(() => {

        addCommonActivityCaptureDetail({ page: "Strategies", globalFilters: selectedGlobalFilters, strategyFilters: selectedStrategies })
        Promise.all([
            props.displayAllStrategyAPI()
        ]).then(function (values) {

            setLoading(false);
        });
    }, []);
    useEffect(() => {
        document.title = keyMapTabList ? keyMapTabList.strategiesTab : "";
    }, [keyMapTabList]);
    useEffect(() => {
        if (!authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            });
        }
    }, [authState, oktaAuth]); // Update if authState changes

    const displayModalOff = () => {
        setDisplayModal(false);
    }
    const displayAllStrategy = () => {
        props.displayAllStrategyAPI()
    }
    const handleAddStrategy = () => {
        setDisplayModal(true);
        setModalMode("Add");
        let obj = {
            propName: "",
            propDescription: "",
            propLink: "",
            propReportId: "",
            propStatus: "",
            propStrategyId: ""
        }
        setModalDetails(obj);
    }
    const handleDelete = (e, row) => {

        Swal.fire({
            text: 'Are you sure you want to delete this strategy ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                props.deleteStrategyAPI({ strategyIds: [row.strategyId] }, (res, rej) => {
                    if (res) {
                        props.displayAllStrategyAPI();
                        toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                    } else {

                    }
                });

            }
        })
    }
    const handleEditStrategy = (e, row) => {

        setDisplayModal(true);
        setModalMode("Edit");
        let obj = {
            propName: row.strategyName,
            propDescription: row.strategyDesc,
            propLink: row.strategyUrl,
            propReportId: row.strategyReportId,
            propStatus: row.strategyStatus,
            propStrategyId: row.strategyId
        }
        setModalDetails(obj);
    }

    const AddStrategy = () => {
        return (
            <div className="mb-3" >
                <button className="btn btn-light" onClick={(e) => { handleAddStrategy(e) }}>
                    <i className="fas fa-plus mr-2"></i>Add a new strategy
                </button>
            </div>
        )
    }
    const nameFormatter = (cell, row) => {
        return (
            <div className="strategyRow">
                <span className="name"> {row.strategyName} </span>
                <p >{row.strategyDesc}</p>
            </div>
        )
    }
    const statusFormatter = (cell, row) => {

        return (
            <div style={{ width: "8rem" }}>
                <i class={`fas ${row.strategyStatus === "Active" ? 'green' : 'red'} fa-dot-circle mr-2`}></i>
                {row.strategyStatus}
            </div>
        )
    }
    const linkFormatter = (cell, row) => {

        return (
            <div style={{ maxWidth: "16rem", overflow: "hidden" }}>
                <a href={row.strategyUrl} target="_blank" download={row.strategyUrl}>
                    {row.strategyUrl}
                </a>

            </div>
        )
    }
    function renderAddModalButton() {

        if (hasAdminAccess) {
            return <AddStrategy />;
        }
        else {
            return <></>;
        }
    }
    const editColumnFormatter = (cell, row) => {

        return (
            <div className="flex editColumn">
                <span onClick={(e) => { handleEditStrategy(e, row) }}><i className="fas fa-edit mr-3" ></i></span>
                <span onClick={(e) => { handleDelete(e, row) }}><i className="fas fa-trash"></i></span>
            </div>
        )
    }

    // Handle page size change
    const handleSizePerPageChange = (e) => {
        setSizePerPage(parseInt(e.target.value, 10));
        setCurrentPage(1); // Reset to first page when size per page changes
    };
    // Sorting functions
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSort = (field) => {
        let order = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);

        const sortedData = [...strategiesData].sort((a, b) => {
            const valueA = a[field] !== undefined && a[field] !== null ? a[field].toString() : '';
            const valueB = b[field] !== undefined && b[field] !== null ? b[field].toString() : '';

            if (typeof a[field] === 'number') {
                return order === 'asc' ? a[field] - b[field] : b[field] - a[field];
            } else if (typeof a[field] === 'string' || typeof a[field] === 'number') {
                return order === 'asc'
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            }
            return 0;
        });

        setStrategiesData(sortedData);
    };

    const handleSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 'asc'
                ? <i className="fas fa-sort-up ml-2 active"></i>
                : <i className="fas fa-sort-down ml-2 active"></i>;
        }
        return <i className="fas fa-sort ml-2"></i>;
    };

    const pageSizeOptions = [10, 20, 30, 50];
    const totalPages = Math.ceil(totalSize / sizePerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setPageNumber(page); // Update the page number for pagination
    };

    const getPaginationItems = () => {
        const items = [];
        const visiblePages = 3;

        const startPage = Math.max(1, currentPage - visiblePages);
        const endPage = Math.min(totalPages, currentPage + visiblePages);

        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            items.push(
                <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return items;
    };

    const paginatedData = strategiesData.slice((currentPage - 1) * sizePerPage, currentPage * sizePerPage);

    if (loading) {
        const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
      `;
        return (
            <div id="strategy_wrapper">
                <div className="container-fluid  default-top loading_wrapper  bg-gray p-5">
                    <div className="sweet-loading">
                        <BarLoader
                            css={override}
                            size={150}
                            color={"#123abc"}
                            height={4}
                            width={100}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (loading) {
        return (
            <div>Loading...</div>
        );
    } else {
        return (
            <div id="strategy_wrapper">
                <ToastContainer />
                {displayModal ?
                    <AddStrategyModal
                        modalDetails={modalDetails}
                        modalMode={modalMode}
                        displayAllStrategy={displayAllStrategy}
                        insertNewStrategyAPI={props.insertNewStrategyAPI}
                        updateStrategyAPI={props.updateStrategyAPI}
                        display={displayModal} displayModalOff={displayModalOff} /> : <div></div>}
                <div className="container-fluid  default-top  bg-gray p-5">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between">
                            <div className="header">{keyMapTabList ? keyMapTabList.strategiesTab : ""}</div>
                            {renderAddModalButton()}

                        </div>
                    </div>
                    <InputGroup className="mb-3" style={{ width: '220px' }}>
                        <FormControl
                            placeholder="Search..."
                            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                        />
                    </InputGroup>
                    <Table bordered className='mb-4'>
                        <thead>
                            <tr className='Selfservicetable'>
                                <th onClick={() => handleSort('strategyName')}>Name {handleSortIcon('strategyName')}</th>
                                <th onClick={() => handleSort('strategyUrl')}>Link {handleSortIcon('strategyUrl')}</th>
                                {hasAdminAccess ?
                                    <>
                                        <th >Status </th>
                                        <th >Edit </th></> : null}
                            </tr>
                        </thead>
                        <tbody className='Selfservicebcls'>
                            {paginatedData?.length > 0 ? <>
                                {paginatedData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{nameFormatter(row?.strategyName, row)}</td>
                                        <td>{linkFormatter(row?.strategyUrl, row)}</td>
                                        {hasAdminAccess ?
                                            <>
                                                <td>{statusFormatter(row?.Status, row)}</td>
                                                <td>{editColumnFormatter(row?.Status, row)}</td> </> : null}
                                    </tr>
                                ))}</> : <tr> <td colSpan="17" style={{ textAlign: 'center' }}>No Data Available</td></tr>}
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-between align-items-center mt-0">
                        <Form.Group controlId="sizePerPageSelect">
                            <Form.Control
                                as="select"
                                value={sizePerPage}
                                onChange={handleSizePerPageChange}
                                id='pageDropDown'
                                className="pageDropdownoption"
                            >
                                {pageSizeOptions.map(size => (
                                    <option key={size} value={size}>{size}</option>
                                ))}
                            </Form.Control>
                            <Form.Label>Showing rows {((currentPage - 1) * sizePerPage) + 1} to {Math.min(currentPage * sizePerPage, totalSize)} of {totalSize}</Form.Label>
                        </Form.Group>

                        <Pagination className="d-flex justify-content-end mb-4">
                            <Pagination.Prev
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            />
                            {getPaginationItems()}
                            <Pagination.Next
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            />
                        </Pagination>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {

    let allStrategies = _get(state, 'strategyReducer.allStrategies', []);

    return ({
        allStrategies: allStrategies,

    })
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        displayAllStrategyAPI: displayAllStrategy,
        insertNewStrategyAPI: insertNewStrategy,
        updateStrategyAPI: updateStrategy,
        deleteStrategyAPI: deleteStrategy

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Strategies);
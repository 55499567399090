import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Pagination, Form } from "react-bootstrap";
import { getSummaryByAlert } from '../../actions/index';
import { convertToKMB, generateStrategyNameList, dynamicRetailerimpactPriceFormat } from "../../helper";
import { CarretIconUp, CarretIconDown } from '../../components/Icons';

const AlertSummaryTable = (props) => {
  const [alertSummaryData, setAlertSummaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  
  // Redux selectors
  let { selectedCustomerKey, selectedRetailerCurrency, dynamicPriceFormatting } = useSelector((state) => state.globalReducer);
  let allStrategyDetails = useSelector((state) => state.strategyReducer.detailsForAllStrategies);
  let [selectedStrategies] = useState(generateStrategyNameList(allStrategyDetails));
  let {
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedStrategies,
    alertSummary,
    adoptionSelectedUseCases,
    retailerCurrency
  } = useSelector((state) => state.adoptionReducer);
  
  let retailerCurrencySign = retailerCurrency === undefined || retailerCurrency === '' || retailerCurrency === 'USD' ? selectedRetailerCurrency : '$';

  // Handle API response
  let responseHandler = (response) => {
    if(response?.data){
      if(response.data.responseData.length <= 0) {
        setLoading(false)
      } else {
        setAlertSummaryData(response.data.responseData);
        setTotalSize(response.data.responseData.length);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  let requestObject = () => {
    return {
      userNames: adoptionSelectedUsers ? adoptionSelectedUsers : [],
      strategies: adoptionSelectedStrategies ? adoptionSelectedStrategies : [],
      dropDownSelectionPeriod: selectedDateRange ? selectedDateRange : "Prior Week",
      additionalParametersInterval: adoptionCustomDates ? adoptionCustomDates : {},
      additionalParametersPoint: adoptionAdditionalParamaters,
      useCase: adoptionSelectedUseCases,
      currency: retailerCurrency === undefined || retailerCurrency === '' ? 'USD' : retailerCurrency,
      customerKey: selectedCustomerKey
    };
  };

  // Fetch data on mount and when dependencies change
  useEffect(() => {
    if(alertSummary && alertSummary.responseData){
      setAlertSummaryData(alertSummary.responseData);
      setTotalSize(alertSummary.responseData.length);
      setLoading(false);
    }
  }, [alertSummary]);

  useEffect(() => {
    setLoading(true);
    if (selectedDateRange) {
      dispatch(getSummaryByAlert(requestObject(), responseHandler));
    }
  }, [
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedStrategies,
    sizePerPage,
    pageNumber,
    retailerCurrency,
    adoptionSelectedUseCases,
    adoptionSelectedStrategies
  ]);

  // Formatting functions
  const estimatedRevenueFormatter = (cell) => {
    if(cell !== undefined && cell !== null && cell !== 'NaN') {
      return (
        <>
          {Number(cell) && dynamicPriceFormatting && dynamicPriceFormatting === 'dot' 
            ? dynamicRetailerimpactPriceFormat(convertToKMB(Number(cell), retailerCurrencySign)) 
            : Number(cell) 
              ? convertToKMB(Number(cell), retailerCurrencySign) 
              : retailerCurrencySign + cell}
        </>
      );
    } else {
      return retailerCurrencySign + 0;
    }
  };

  const percentValue = (cell) => {
    if(cell !== undefined && cell !== null && cell !== 'NaN' && cell !== 'NA') {
      let finalVal = parseFloat(cell).toFixed(2);
      let finalValfixed = parseFloat(cell).toFixed(0);
      if(finalVal == 0) {
        return <span className="clr-grn"><CarretIconUp />{finalValfixed}%</span>;
      } else if (finalVal > 0) {
        return <span className="clr-grn"><CarretIconUp />{finalVal}%</span>;
      } else {
        return  <span className="clr-rd"><CarretIconDown />{finalVal}%</span>;
      }     
    } else {
      return  <span className="clr-grn"><CarretIconUp />0%</span>;
    } 
  };

  const percentValuenew = (cell) => {
    return(<>{cell != undefined ? cell + '%' : '0%'}</>);
  };

  const actionTime = (cell) => {
    return(<>{cell == 1 ? cell + 'day' : cell != undefined ? cell + ' days' : '0 days'}</>);
  };

  // Handle page size change
  const handleSizePerPageChange = (e) => {
    setSizePerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when size per page changes
  };

  // Sorting functions
  const [sortField, setSortField] = useState(''); 
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = (field) => {
    let order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  
    const sortedData = [...alertSummaryData].sort((a, b) => {
      const valueA = a[field] !== undefined && a[field] !== null ? a[field].toString() : '';
      const valueB = b[field] !== undefined && b[field] !== null ? b[field].toString() : '';
  
      if (typeof a[field] === 'number') {
        return order === 'asc' ? a[field] - b[field] : b[field] - a[field];
      } else if (typeof a[field] === 'string' || typeof a[field] === 'number') {
        return order === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
      return 0;
    });
  
    setAlertSummaryData(sortedData);
  };

  const handleSortIcon = (field) => {
    if (sortField === field) {
      return sortOrder === 'asc' 
        ? <i className="fas fa-sort-up ml-2 active"></i> 
        : <i className="fas fa-sort-down ml-2 active"></i>;
    }
    return <i className="fas fa-sort ml-2"></i>;
  };

  const pageSizeOptions = [10, 20, 30, 50];
  const totalPages = Math.ceil(totalSize / sizePerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPageNumber(page); // Update the page number for pagination
  };

  const getPaginationItems = () => {
    const items = [];
    const visiblePages = 3; 

    const startPage = Math.max(1, currentPage - visiblePages);
    const endPage = Math.min(totalPages, currentPage + visiblePages);

    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      items.push(
        <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  const paginatedData = alertSummaryData.slice((currentPage - 1) * sizePerPage, currentPage * sizePerPage);

  if (loading) {
    return (
      <div id="no-data-available" style={{ marginTop: "5%", height: '80px' }}>
        Loading....
      </div>
    );
  } else {
    return (
      <div className='alert_summarytable alert-bysummary'>
        <div className="row ml-0">
          <div style={{fontSize: 18, marginBottom: 10, marginTop: 35, fontWeight: "bold" }}>
            Summary By Alert
          </div>              
        </div>
        <Table bordered className='table_center mb-4'>
          <thead>
            <tr className='impactpagerow_first'>
              <th rowSpan="2" style={{ width: '140px' }} onClick={() => handleSort('useCase')}>Use Case{handleSortIcon('useCase')}</th>
              <th rowSpan="2" style={{ width: '140px' }} onClick={() => handleSort('alert')}>Alert{handleSortIcon('alert')}</th>
              <th colSpan="2" style={{ width: '100px' }}>New</th>
              <th colSpan="2" style={{ width: '100px' }}>Actions Taken</th>
              <th colSpan="1" style={{ width: '100px' }}>Unresolved Actions</th>
              <th colSpan="2" style={{ width: '100px' }}>Resolved Actions</th>
              <th colSpan="2" style={{ width: '100px' }}>Sales Impact</th>
              <th colSpan="6" style={{ width: '100px' }}>Effectiveness</th>
            </tr>
            
            <tr>
              <th style={{ width: '100px' }} onClick={() => handleSort('percentageOfPendingEstimatedValue')}>%Total{handleSortIcon('percentageOfPendingEstimatedValue')}</th>
              <th style={{ width: '90px' }}  onClick={() => handleSort('newEstimatedValue')}>Estimated Value{handleSortIcon('newEstimatedValue')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('actionedEstimatedValue')}>Estimated Value{handleSortIcon('actionedEstimatedValue')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('avgActionTime')}>Avg Action time{handleSortIcon('avgActionTime')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('UnResolvedEstimatedValue')}>Estimated Value{handleSortIcon('UnResolvedEstimatedValue')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('resolvedEstimatedValue')}>Estimated Value{handleSortIcon('resolvedEstimatedValue')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('avgResolutionTime')}>Avg Resol. time{handleSortIcon('avgResolutionTime')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('salesImnpactValue')}>Value{handleSortIcon('salesImnpactValue')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('salesImnpactPercent')}>PoP{handleSortIcon('salesImnpactPercent')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('effectivenessVsTriggered')}>VS. Triggered{handleSortIcon('effectivenessVsTriggered')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('effectivenessVsTriggeredPOP')}>PoP{handleSortIcon('effectivenessVsTriggeredPOP')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('effectivenessVsTriggeredYOY')}>YoY{handleSortIcon('effectivenessVsTriggeredYOY')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('effectivenessVsActioned')}>VS. Actioned{handleSortIcon('effectivenessVsActioned')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('effectivenessVsActionedPOP')}>PoP{handleSortIcon('effectivenessVsActionedPOP')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('effectivenessVsActionedYOY')}>YoY{handleSortIcon('effectivenessVsActionedYOY')}</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.length > 0 ? <>
            {paginatedData.map((row, index) => (
              <tr key={index}>
                <td>{row?.useCase}</td>
                <td>{row?.alertName}</td>
                <td>{percentValuenew(row?.percentageOfPendingEstimatedValue)}</td>
                <td>{estimatedRevenueFormatter(row.newEstimatedValue)}</td>
                <td>{estimatedRevenueFormatter(row?.actionedEstimatedValue)}</td>
                <td>{actionTime(row.avgActionTime)}</td>
                <td>{estimatedRevenueFormatter(row.UnResolvedEstimatedValue)}</td>
                <td>{estimatedRevenueFormatter(row?.resolvedEstimatedValue)}</td>
                <td>{actionTime(row.avgResolutionTime)}</td>
                <td>{estimatedRevenueFormatter(row.salesImnpactValue)}</td>
                <td>{percentValue(row?.salesImnpactPercent)}</td>
                <td>{percentValuenew(row.effectivenessVsTriggered)}</td>
                <td>{percentValue(row?.effectivenessVsTriggeredPOP)}</td>
                <td>{percentValue(row?.effectivenessVsTriggeredYOY)}</td>
                <td>{percentValuenew(row.effectivenessVsActioned)}</td>
                <td>{percentValue(row?.effectivenessVsActionedPOP)}</td>
                <td>{percentValue(row.effectivenessVsActionedYOY)}</td>
              </tr>
            ))}</> : <tr> <td colSpan="17">No Alert Data Available</td></tr>}
          </tbody>
        </Table>
        {alertSummaryData?.length > 0 &&
          <div className="d-flex justify-content-between align-items-center mt-0">
            <Form.Group controlId="sizePerPageSelect">
              <Form.Control
                as="select"
                value={sizePerPage}
                onChange={handleSizePerPageChange}
                id='pageDropDown'
                className="pageDropdownoption"
              >
                {pageSizeOptions.map(size => (
                  <option key={size} value={size}>{size}</option>
                ))}
              </Form.Control>
              <Form.Label>Showing rows {((currentPage - 1) * sizePerPage) + 1} to {Math.min(currentPage * sizePerPage, totalSize)} of {totalSize}</Form.Label>
            </Form.Group>
            
            <Pagination className="d-flex justify-content-end mb-4">
              <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              />
              {getPaginationItems()}
              <Pagination.Next
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              />
            </Pagination>
          </div>
        }
      </div>  
    );
  }
};

export default AlertSummaryTable;

import React, { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEmbedToken, updateViewBuilderJSON } from '../../actions';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import DOMPurify from 'dompurify';
//Add Powerbi report, tables 
class PreviewDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  renderPowerBIDashboard(obj) {
    if (obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken === '') {
      this.props.getEmbedTokenAPI({
        groupId: process.env.REACT_APP_GROUP_ID,
        dashboardId: obj.reportId
      }, (res) => {
        if (res) {
          if (res.data.embedToken) {
            let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
            tempViewBuilderObj.viewBuilderJSON.htmlTemp[obj.id]['embedToken'] = res.data.embedToken;
            this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
          }
        }
      });
      return null;
    }
    else if (obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken !== '') {
      let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${obj.reportId}&groupId=${process.env.REACT_APP_GROUP_ID}`;
      embedUrl = embedUrl.replace("watch?v=", "v/");
      const extraSettings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        hideErrors: false
      };
      const style = {
        height: "300px",
        width: "100%",
        fontSize: "80%"
      }
      return (
        <div className="col-12" id={obj.id}>          
          <PowerBIEmbed
                        embedConfig={{
                            type: 'report',   // 'report' or 'dashboard'
                            id: obj.reportId,
                            embedUrl: embedUrl,
                            accessToken: obj.embedToken,
                            tokenType: models.TokenType.Embed,
                            settings: extraSettings
                        }}
                        eventHandlers={
                            new Map([
                                ['loaded', (event) => {
                                    const report = event.target;
                                    if (report && typeof report.setFilters === 'function') {
                                        
                                    } else {
                                        console.error('Report object does not support setFilters');
                                    }
                                }],
                                ['rendered', () => {
                                    console.log('Report rendered');
                                }]
                            ])
                        }
                        cssClassName="report-style-class"
                    />
        </div>
      )
    }

  }
  
  renderHtmlContent(obj) {
    if (obj.innerhtml !== '' && obj.type !== 'dashboard') {
      return (<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(obj.innerhtml)}} />)
    }

    else if (obj.innerhtml !== '' && obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken === '') {
      return (<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(obj.innerhtml)}} />)
    }
    else {

    }

    }
  renderChildrenOfId(id) {
    if (Object.keys(this.props.viewBuilder).length > 0) {
      if (this.props.viewBuilder.hasOwnProperty("viewBuilderJSON") && this.props.viewBuilder.viewBuilderJSON.hasOwnProperty("htmlTemp")) {
        let newJson = this.props.viewBuilder.viewBuilderJSON.htmlTemp
        if (Object.keys(newJson).length > 0) {
           let obj = newJson[id];
          const HtmlTag = DOMPurify.sanitize(`${obj.element}`);
          return (
            <HtmlTag className={obj.classDefault + ' ' + obj.classMobile + ' ' + obj.classTablet + ' ' + obj.classDesktop}
              id={obj.id} type={obj.type}
              style={obj.configOptions.style}
            >
              {this.renderHtmlContent(obj)}
              {this.renderPowerBIDashboard(obj)}
              {obj.children.length > 0 ? obj.children.map((id, index) => {
                return (
                  <React.Fragment key={index}>
                    {this.renderChildrenOfId(id)}
                  </React.Fragment>
                )
              }) : null}
            </HtmlTag>
          )
        }


      }




    }
  }
  render() {


    return (
      <div className="sections" id="preview_screen" >
        <div className={`row mx-0 ${this.props.windowType}`} id="view_builder_content" >
          {this.renderChildrenOfId(0)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return ({
    viewBuilder: state.viewBuilderObj
  })
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getEmbedTokenAPI: getEmbedToken,
    updateViewBuilderJSONAPI: updateViewBuilderJSON
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PreviewDashboard);

import React from "react";
interface CtaButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	customProp?: string;
}

function LightButton({ customProp, children, ...props }: CtaButtonProps) {
	const { className } = props;
	const buttonStyle: React.CSSProperties = {
		cursor: "pointer",
		fontSize: "16px",
		lineHeight: "22px",
		color: "#304FFE",
		padding: "0px 16px",
	};
	const FlexStyle: React.CSSProperties = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};
	return (
		<div style={FlexStyle}>
			<span style={buttonStyle} {...props} className={`${className}`}>
				{children}
			</span>
		</div>
	);
}

export default LightButton;

import React from "react";
import CtaButton from "./CtaButton";
import { CloseIcon } from "../Icons";
interface UnsuscribeButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	customProp?: string;
}
function UnsuscribeButton({
	customProp,
	children,
	...props
}: UnsuscribeButtonProps) {
	return (
		<CtaButton {...props} className='subscription-filter'>
			Unsubscribe <CloseIcon />
		</CtaButton>
	);
}

export default UnsuscribeButton;

import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import {
     setSubproject, setProject, setSubprojectInfo,
    setProjectInfo, resetViewBuilderData, setIsAppAdmin, setIsProjectAdmin,
    setIsSubprojectAdmin, getTabList, storeSelectedStrategies, storeNewRecomSearchText,
    adoptionSelectedUSers, storeGlobalFilterSelections, storeRecommendationSelectedStrategies, storeSaveFilterClicked,
    getDateFilterOptions, getDateFilterKeyValueOptions, getComparisonIdValues, setRetailerCurrency,
    setCustomerKey, saveDateRangeFilter, storeAdoptionSelectedStrategies, storeAdoptionSelectedUseCases,
    strategySelectedDateRange, strategyFilterAndOrCondition, getOrUpdateLatestUpdateAvailability,
    newInsightLeftNameClicked, RankTypeAlertFilter, newInsightSectionName, retailerCurrencyCode, getDecimalForRetailers, submitFeedback
} from '../actions';
import { ToastContainer, toast } from 'react-toastify';
import Feedback from './Feedback';
import HelpModal from './HelpModal';
import LatestModal from './LatestModal';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import { DropDownIcon, RightArrowIcon, TickIcon, UserIcon, BoxArrowUpRight } from './Icons';
import HandleUserListModal from './HandleUserListModal';
import { generateStrategyNameList, generateUseCaseNameList } from '../helper';
import DOMPurify from 'dompurify';
import greatLogo from '../img/great_logo_new.png';
import { useSelector, useDispatch } from 'react-redux';

const NavbarNew = () => {
    let dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { oktaAuth, authState } = useOktaAuth();
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayHelpModal, setDisplayHelpModal] = useState(false);
    const [displayUserListModal, setDisplayUserListModal] = useState(false);
    const [displayLatestModal, setDisplayLatestModal] = useState(false);
    let { selectedSubprojectInfo, selectedProjectInfo, subproject, project, latestUpdateAvailble,
        isAppAdmin, isProjectAdmin, isSubprojectAdmin, hideNavbarPages, tabList, projectAndSubprojects
    } = useSelector((state) => state.globalReducer);
   
    let { allUseCases } = useSelector((state) => state.adoptionReducer);
    let { detailsForAllStrategies } = useSelector((state) => state.strategyReducer);
    const [hoveredProjectName, setHoveredProjectName] = useState(project);
    const [hoveredProjectInfo, setHoveredProjectInfo] = useState(selectedProjectInfo);
    const [hoveredSubproject, setHoveredSubproject] = useState(subproject);
    const displayModalOff = () => setDisplayModal(false);
    const displayHelpModalOff = () => setDisplayHelpModal(false);
    const displayUserListModalOff = () => setDisplayUserListModal(false);
    const displayLatestModalOff = () => setDisplayLatestModal(false);
    const onToggleProjectSubproject = () => setHoveredProjectName(project);

    const login = async () => oktaAuth.signInWithRedirect('/');
    const logout = async () => {
        oktaAuth.signOut('/');
        localStorage.removeItem("okta-token-storage");
    }
    useEffect(() => {        
        if (authState?.isAuthenticated) {
            oktaAuth.getUser().then((res, rej) => {
            if (res) {
                setUserInfo(res);
                setLoading(false);
                const projectAdminGroupName = selectedProjectInfo?.oktaGroupAdmin;
                const subprojectAdminGroupName = selectedSubprojectInfo?.oktaGroupAdmin;

                dispatch(setRetailerCurrency(selectedSubprojectInfo?.currencyCode));
                dispatch(setCustomerKey(selectedSubprojectInfo?.customerKey));
                dispatch(getDateFilterOptions()); // for future implementation to configure date filter option
                dispatch(getDateFilterKeyValueOptions());
                dispatch(getComparisonIdValues());
                dispatch(getTabList());
                localStorage.setItem("user-info", JSON.stringify(res));
                dispatch(getDecimalForRetailers({customerKey:selectedSubprojectInfo?.customerKey},()=>{}));
                dispatch(getOrUpdateLatestUpdateAvailability({
                    userId: res?.sAMAccountName,
                    userName: res?.name,
                    userEmail: res.email,
                    isUpdate: 'FALSE',
                    Retailer: subproject
                }));

                if (res) {
                    if ((res.Roles && res.Roles.indexOf('Admin') > -1) || (res.groups && res.groups.indexOf('Admin') > -1)) {
                        dispatch(setIsAppAdmin(true));
                    }
                    if ((res.Roles && res.Roles.indexOf(projectAdminGroupName) > -1) || (res.groups && res.groups.indexOf(projectAdminGroupName) > -1)) {
                        dispatch(setIsProjectAdmin(true));
                    }
                    if ((res.Roles && res.Roles.indexOf(subprojectAdminGroupName) > -1) || (res.groups && res.groups.indexOf(subprojectAdminGroupName) > -1)) {
                        dispatch(setIsSubprojectAdmin(true));
                    }
                }
            }
        });
        }
    }, [oktaAuth, authState]);

    const getPageDetails = (currentPath, currentPathArr, project, subProject) => {
        let pos = window.dataLayer.map(function (e) {
            return e && e.event ? e.event : '';
        }).indexOf('core-page-view');
        let pathValue = currentPath
        if (!pathValue) {
            pathValue = currentPathArr.length === 2 && location.pathname === "/" ? 'Projects' :
                ((currentPathArr.length === 2 || currentPathArr.length === 3) && (location.pathname.includes('dash') || location.pathname.includes('ecompass'))) ? 'Sub Projects' :
                    currentPathArr.length === 4 ? location.pathname.includes('ecompass') ? 'Insights' : location.pathname.includes('dash') ? 'Recommendations' : pathValue : pathValue
        }
        pathValue = (pathValue === 'ecompass' || pathValue === 'dash') ? 'Sub Projects' : pathValue
        let pageDataLayer = {
            'event': 'core-page-view',
            'pageInfo': {
                'pageName': pathValue,
                'pagePath': location.pathname,
                'retailerName': `${project}_${subProject}`,
            }
        }
        pos === -1 ? window.dataLayer.push(pageDataLayer) : window.dataLayer[pos] = pageDataLayer;

    }
   
    const renderProjectsAndSubprojects = () => {
        const projectDetails = projectAndSubprojects && projectAndSubprojects.filter((project) => {
            return project.name === hoveredProjectName;
        });
        const listOfSubprojects = projectDetails?.length ? projectDetails[0]?.subProjectInfo : [];
        return (
            <div className="d-flex projects_subprojects_dropdown">
                <div id="projects_list">
                    <ListGroup>
                        {projectAndSubprojects && projectAndSubprojects.map((project, index) => {
                            const projectName = project.name;
                            const firstSubProject = project.subProjectInfo[0];
                            return (
                                <ListGroup.Item
                                    onMouseEnter={(e) => {
                                        setHoveredProjectName(project.name);
                                        setHoveredProjectInfo(project);
                                    }}
                                    onClick={(e) => {
                                        dispatch(setProject({ project: projectName }));
                                        dispatch(storeGlobalFilterSelections({}))
                                        document.cookie = "selected-global-filters= ; path=/; max-age=0; "
                                        document.cookie = "selected-parent-filter= ; path=/; max-age=0; "
                                        document.cookie = "selected-filter-name= ; path=/; max-age=0; "
                                        document.cookie = "selected-filter-object= ; path=/; max-age=0; "
                                        document.cookie = "selected-sku-id=; path=/; max-age=0; "
                                        dispatch(storeSaveFilterClicked(false));
                                        dispatch(storeNewRecomSearchText(""));
                                        dispatch(adoptionSelectedUSers([]));
                                        dispatch(storeAdoptionSelectedStrategies(generateStrategyNameList(detailsForAllStrategies)));
                                        localStorage.setItem("project", projectName);
                                        dispatch(setProjectInfo(project));
                                        dispatch(setSubproject({ subproject: firstSubProject.name }));
                                        dispatch(setSubprojectInfo(firstSubProject));
                                        dispatch(setRetailerCurrency(firstSubProject.currencyCode));
                                        dispatch(setCustomerKey(firstSubProject.customerKey));
                                        localStorage.setItem("subproject", firstSubProject.name);
                                        dispatch(resetViewBuilderData());
                                        dispatch(storeRecommendationSelectedStrategies(undefined));
                                        dispatch(getTabList());
                                        history.push(`/${projectName.toLowerCase()}/${firstSubProject.name.toLowerCase()}/`);
                                        document.cookie = "selected-date-range-filters= ; path=/; max-age=0; "
                                        dispatch(newInsightLeftNameClicked());
                                        dispatch(RankTypeAlertFilter([]));
                                        dispatch(newInsightSectionName());
                                        dispatch(retailerCurrencyCode());
                                        dispatch(saveDateRangeFilter({
                                            'dateRange': '',
                                            'compareTo': ''
                                        }));
                                    }}
                                    className="d-flex justify-content-between"
                                    active={project.name === hoveredProjectName ? true : false} key={index}>
                                    {project.name}
                                    <RightArrowIcon />
                                </ListGroup.Item>
                            )
                        })}
                        <div className="horizontal_divider"></div>
                        <ListGroup.Item className="manage_projects" onClick={(e) => {
                            history.push("/");
                        }}>View Projects</ListGroup.Item>
                    </ListGroup>
                </div>
                <div id="subprojects_list" className={`${listOfSubprojects?.length > 12 ? 'listOfSubprojects_cls' : ''}`}>
                    <ListGroup>
                        {listOfSubprojects && listOfSubprojects.map((subProject, index) => {
                            let active = subproject === subProject.name && project === hoveredProjectName;
                            return (
                                <ListGroup.Item
                                    onMouseEnter={(e) => {
                                        setHoveredSubproject(subProject.name);
                                    }}
                                    onClick={(e) => {
                                        dispatch(setProject({ project: hoveredProjectName }));
                                        localStorage.setItem("project", hoveredProjectName);
                                        dispatch(setProjectInfo(hoveredProjectInfo));
                                        dispatch(setSubprojectInfo(subProject));
                                        dispatch(setSubproject({ subproject: subProject.name }));
                                        dispatch(setRetailerCurrency(subProject.currencyCode));
                                        dispatch(setCustomerKey(subProject.customerKey));
                                        localStorage.setItem("subproject", subProject.name);
                                        dispatch(resetViewBuilderData());
                                        dispatch(storeGlobalFilterSelections({}))
                                        document.cookie = "selected-global-filters= ; path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                                        document.cookie = "selected-parent-filter=; path=/; max-age=0;"
                                        document.cookie = "selected-filter-name=; path=/; max-age=0;"
                                        document.cookie = "selected-filter-object=; path=/; max-age=0;"
                                        document.cookie = "selected-sku-id=; path=/; max-age=0;"
                                        dispatch(storeRecommendationSelectedStrategies(undefined));
                                        dispatch(storeSaveFilterClicked(false));
                                        dispatch(storeNewRecomSearchText(""));
                                        dispatch(storeSelectedStrategies([]));
                                        dispatch(adoptionSelectedUSers([]));
                                        dispatch(storeAdoptionSelectedStrategies(generateStrategyNameList(detailsForAllStrategies)));
                                        dispatch(getTabList());
                                        dispatch(getDecimalForRetailers({customerKey:subProject?.customerKey},()=>{}));
                                        dispatch(getOrUpdateLatestUpdateAvailability({ userId: userInfo?.sAMAccountName, userName: userInfo?.name, userEmail: userInfo.email, isUpdate: 'FALSE', Retailer: subProject?.name }));
                                        history.push(`/${hoveredProjectName.toLowerCase()}/${subProject.name.toLowerCase()}/`);
                                        document.cookie = "selected-date-range-filters= ; path=/; max-age=0;"
                                        dispatch(saveDateRangeFilter({
                                            'dateRange': "",
                                            'compareTo': ""
                                        }));
                                        dispatch(newInsightLeftNameClicked());
                                        dispatch(getDateFilterOptions());
                                        dispatch(strategySelectedDateRange('Default'));
                                        dispatch(strategyFilterAndOrCondition(''));
                                        dispatch(RankTypeAlertFilter([]));
                                        dispatch(newInsightSectionName());
                                        dispatch(retailerCurrencyCode());
                                    }}
                                    active={active ? true : false} key={index}>
                                    <div className="d-flex justify-content-between">
                                        <div>{subProject.name}</div>
                                        <div>
                                            {active ?
                                                <TickIcon /> : null}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}

                    </ListGroup>
                </div>
            </div>
        )
    }
    
    const submitFeedbackAPI = (selections) => {
        selections.projectId = 2;
        selections.feedbackProvider = userInfo.email;

        dispatch(submitFeedback(selections, (res, rej) => {
            if (res) {
                if (res.data.responseType === "success") {
                    toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                } else {
                    toast.error(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                }
            } else {

            }
            setDisplayModal(false);
        }));

    }

    const listOfPages = (currentPath, project, subProject) => {
        return tabList && tabList.map((item, index) => {
            let linkText = item.toLocaleLowerCase();
            if (project && project.toLowerCase() === "dash") {
                if (linkText === 'recommendations') {
                    return (
                        <li className="nav-item">
                            <Link className={`nav-link ${currentPath === "" || currentPath === "/" ? "active" : null}`} to={`/${project}/${subProject}/`} onClick={(e) => { dispatch(resetViewBuilderData()) }}>{item}</Link>
                        </li>
                    )
                }
            } else {
                if (linkText === 'insights') {
                    return (
                        <li className="nav-item">
                            <Link className={`nav-link  ${tabList?.length > 5 ? 'nav-fsize' : ''}  ${currentPath === "" || currentPath === "/" ? "active" : null}`} to={`/${project}/${subProject}/`} onClick={(e) => { dispatch(resetViewBuilderData()) }}>{item}</Link>
                        </li>
                    )
                }
            }
            return (
                <li className="nav-item">
                    <Link className={`nav-link  ${tabList?.length > 5 && project === 'ecompass' ? 'nav-fsize' : ''} ${currentPath === linkText ? "active" : null}`} to={`/${project}/${subProject}/${linkText}`} onClick={(e) => { dispatch(newInsightLeftNameClicked()); dispatch(RankTypeAlertFilter([])); dispatch(newInsightSectionName()); dispatch(retailerCurrencyCode()); dispatch(resetViewBuilderData()); dispatch(storeAdoptionSelectedUseCases(generateUseCaseNameList(allUseCases?.detailsForAllUsecase))); dispatch(storeAdoptionSelectedStrategies(generateStrategyNameList(detailsForAllStrategies))); }}>{item}</Link>
                </li>
            )
        })
    }

    if (!authState || loading) {
        return (
            <div>Loading ...</div>
        )
    } else {
        let currentPath = location.pathname,
            currentPathArr = currentPath.split("/"),
            currentAlertspage = currentPath.substring(currentPath.lastIndexOf('/') + 1);
        currentAlertspage === 'alerts' ? document.body.classList.add('alertspagestle') : document.body.classList.remove('alertspagestle');
        currentPath = currentPathArr[currentPathArr.length - 1];
        const formattedProject = project?.toLowerCase();
        const formattedSubProject = subproject?.toLowerCase();
        getPageDetails(currentPath, currentPathArr, formattedProject, formattedSubProject);
        return (
            <>{authState?.isAuthenticated ?
                <nav id="navbar" className={`navbar  fixed-top navbar-expand-lg  top-nav kimberlylogo ${project === 'eCompass' ? 'menubarnewHeader' : ''}`} >
                    <ToastContainer />
                    {displayModal ? <Feedback submitFeedback={submitFeedbackAPI} displayModalOff={displayModalOff} /> : null}
                    {displayHelpModal ? <HelpModal displayHelpModalOff={displayHelpModalOff} /> : null}
                    {displayLatestModal ? <LatestModal displayLatestModalOff={displayLatestModalOff} /> : null}
                    {displayUserListModal ? <HandleUserListModal displayUserListModalOff={displayUserListModalOff} displayUserListModal={displayUserListModal} /> : null}
                    <Dropdown className={`project_wrapper ${project === 'eCompass' ? 'newprojectHeader' : ''}`} onToggle={onToggleProjectSubproject}>
                        <Dropdown.Toggle variant="light" className="d-flex align-items-center project_subproject_name">
                            {project === 'eCompass' ?
                                <div>
                                    <img src={greatLogo} className='greatheader_logo' />
                                    <span className="ml-1 mr-2 retailer_header">Retailer Analytics</span>
                                </div> :
                                <div>
                                    <img src={selectedProjectInfo?.logo} height="30" />
                                    <span className="ml-2 mr-2">{project}</span>
                                </div>
                            }
                            <div className="vertical_divider"></div>
                            <div className="project_logo_pl6">
                                <img src={selectedSubprojectInfo?.logo} height="30" />
                                <span className="ml-1">{subproject}</span>
                            </div>
                            <DropDownIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {renderProjectsAndSubprojects()}
                        </Dropdown.Menu>
                    </Dropdown>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#listOfPages" aria-controls="listOfPages" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`ml-auto ${project === 'eCompass' ? 'nwheader_stlelinks' : 'dash-header'}`}>
                        {project === 'eCompass' ? <div className='rightmenu_pbilinks'>
                            <a href='https://app.powerbi.com/groups/938a56ce-8cfa-4c98-ba87-b179aa61b462/reports/02d8d0d6-569c-4cf4-a9d1-f5d8112ad9c8/ReportSection?experience=power-bi' target="_blank"><span>CRISP <BoxArrowUpRight /></span></a>
                            <a href={`${process.env.REACT_APP_CRISP_LINK}`} target="_blank"><span className='ml-1'>Sales Dashboard <BoxArrowUpRight /></span></a>
                        </div> : null}
                        {hideNavbarPages ? null :
                            <>
                                <div className="collapse navbar-collapse" id="listOfPages">
                                    <ul className="navbar-nav ml-auto">
                                        {listOfPages(
                                            currentPath,
                                            formattedProject,
                                            formattedSubProject
                                        )
                                        }
                                        <li className="nav-item dropdown">
                                            <div className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div>
                                                    {latestUpdateAvailble?.isLatestUpdate === "TRUE" && project.toLowerCase() === 'ecompass' ?
                                                        <span className='position-relative top-badge'>
                                                            <UserIcon />
                                                            <span class="badgeicon rounded-circle"></span>
                                                        </span> : <UserIcon />
                                                    }
                                                    <span>{userInfo.given_name}</span>
                                                    <DropDownIcon />
                                                </div>
                                            </div>
                                            <div className="dropdown-menu align-right-auto" aria-labelledby="navbarDropdown">
                                                <div className="dropdown-item" onClick={(e) => {
                                                    setDisplayModal(true);
                                                }}><span>Feedback</span></div>
                                                {project?.toLowerCase() !== 'ecompass' ?
                                                    <div className="dropdown-item" onClick={(e) => {
                                                        setDisplayHelpModal(true);
                                                    }}> <span>Version History</span></div>
                                                    : (<div className="dropdown-item" onClick={(e) => {
                                                        setDisplayLatestModal(true);
                                                        if (latestUpdateAvailble?.isLatestUpdate === "TRUE") {
                                                            getOrUpdateLatestUpdateAvailability({ userId: DOMPurify.sanitize(userInfo?.sAMAccountName), userName: DOMPurify.sanitize(userInfo?.name), userEmail: DOMPurify.sanitize(userInfo.email), isUpdate: "TRUE", Retailer: subproject });
                                                        }
                                                    }}> <span>{latestUpdateAvailble?.isLatestUpdate === "TRUE" && <span className='latest_badge'></span>}Latest Updates</span></div>)
                                                }
                                                <div className="dropdown-divider"></div>
                                                <span className="ml-4"><b>Manage</b></span>
                                                {project?.toLowerCase() !== 'ecompass' ? <Link className="dropdown-item" to={`/${formattedProject}/${formattedSubProject}/subscriptions`}  ><span> Subscriptions</span></Link> : ''}
                                                {isAppAdmin || isProjectAdmin || isSubprojectAdmin ? <Link className="dropdown-item" to={`/${formattedProject}/${formattedSubProject}/admin`}  ><span> Dashboard</span></Link> : null}
                                                {isAppAdmin || isProjectAdmin ? <Link className="dropdown-item" to={`/${formattedProject}/`}  ><span> Sub Projects</span></Link> : null}
                                                {isAppAdmin ? <Link className="dropdown-item" to="/"  ><span> Projects</span></Link> : null}
                                                {isAppAdmin || isProjectAdmin ? (<div className="dropdown-item" onClick={(e) => {
                                                    setDisplayUserListModal(true);
                                                }}> <span> User List</span></div>) : null}
                                                {project?.toLowerCase() === 'ecompass' ? <>{isAppAdmin || isProjectAdmin || isSubprojectAdmin ? <Link className="dropdown-item" to={`/${formattedProject}/updates`}  ><span> Updates</span></Link> : null} </>
                                                    : <>{isAppAdmin || isProjectAdmin || isSubprojectAdmin ? <Link className="dropdown-item" to={`/${formattedProject}/${formattedSubProject}/admin/versions`}  ><span> Version History</span></Link> : null}
                                                    </>}
                                                <div className="dropdown-divider"></div>
                                                <button className="dropdown-item" onClick={(e) => { logout() }}> <span className="logout-text">Logout</span></button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className=" navbar-collapse-custom">
							<ul className="navbar-nav mr-auto">
								
							</ul>
						</div> */}
                            </>}</div>
                </nav> :
                null}</>
        )
    };

}


export default NavbarNew;
import React, { useState, useEffect } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import moment from "moment";
import { getFormattedDate } from "../helper";
import Dropdown from "react-bootstrap/Dropdown";
import "./Filters.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  strategySelectedDateRange,
  alertStrategyCustomDates,
  strategyAdditionalParameters,
} from "../actions";
import MonthSelector from "../components/MonthSelector";
function returnFormatedDate(date) {
  return moment(date).format("YYYY-MM-DD");
}
function Datepicker(props) {
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() - 1;
  const { from, to } = props.dateRange;

  if (props.isSpecificMonthView)
    return (
      <MonthSelector
        selectMonthHandler={props.selectMonthHandler}
        selectedSpecificMonth={props.selectedSpecificMonth}
        selectedSpecificYearHandler={props.selectedSpecificYearHandler}
        selectedSpecificYear={props.selectedSpecificYear}
      />
    );
  else if (props.isCustomDateRange) {
    return (
      <DayPicker
        className="custom-selectable"
        numberOfMonths={2}
        disabledDays={{ after: today }}
        modifiers={{ start: from, end: to }}
        month={new Date(year, month)}
        selectedDays={[from, { from, to }]}
        onDayClick={props.handleDayClick}
      />
    );
  } else
    return (
      <DayPicker
        className="Selectable"
        numberOfMonths={2}
        disabledDays={{ after: today }}
        modifiers={props.modifiers}
        month={new Date(year, month)}
      />
    );
}

export default function AlertDateFilter(props) {
  const dispatch = useDispatch();
  let {selectedStrategies, selectedStrategyDateRange,
    strategyCustomDates,selectedCustomDates,strategyAdditionalParamaters
  } = useSelector((state) => state.strategyReducer) 
  let defaultDateRange = selectedStrategyDateRange ? selectedStrategyDateRange : "Default";
  const [selectedCustomDateRange, setSelectedDateRange] = useState(
    defaultDateRange
  );
  const [selectedSpecificMonth, setSelectedSpecificMonth] = useState(strategyAdditionalParamaters ? strategyAdditionalParamaters-1 : '');
  const [selectedSpecificYear, setSelectedSpecificYear] = useState(strategyCustomDates?.start ? new Date(strategyCustomDates.start).getFullYear() : new Date().getFullYear());
  const [startDateCurrent, setStartDateCurrent] = useState(
    new Date(returnFormatedDate(new Date()))
  );

  const [endDateCurrent, setEndDateCurrent] = useState(
    new Date(returnFormatedDate(new Date()))
  );
  const [modifiers, setModifiers] = useState({});

  const [displayDatepicker, setDisplayDatepicker] = useState(false);

  const [isSpecificMonth, setIsSpecificMonth] = useState(false);

  const [isCustomDateRange, setIsCustomDateRange] = useState(selectedStrategyDateRange == "Custom Date Range" ? true : false);
  const [dateRangeConfigs, setDateRangeConfigs] = useState({
    from: undefined,
    to: undefined,
  });

  let handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, dateRangeConfigs);
    setDateRangeConfigs(range);
  };

  let selectMonthHandler = (selectedMonth) => {
    setSelectedSpecificMonth(selectedMonth);
  };
  let selectedSpecificYearHandler = (selectedYear) => {
    setSelectedSpecificYear(selectedYear);
  };

 
  useEffect(() => {
    if(!selectedStrategyDateRange){
      dispatch(
        alertStrategyCustomDates({
          start: returnFormatedDate(
            new Date(moment(new Date()).startOf("month"))),
          end: returnFormatedDate(
            new Date(moment(new Date()))
          ),
        })
      );
      dispatch(strategySelectedDateRange(defaultDateRange));  
    }   
  }, []);

  useEffect(() => {
    let startDate;
    let date = new Date();
    if (selectedCustomDateRange === "Prior Week") {
      startDate = new Date(
        moment(date).subtract(1, "weeks").startOf("isoWeek")
      );
    } else if (selectedCustomDateRange === "Current Week") {
      startDate = new Date(moment(date).startOf("isoWeek"));
    } else if (selectedCustomDateRange === "Current Month") {
      startDate = new Date(moment(date).startOf("month"));
    } 
    if (selectedCustomDateRange === "Custom Date Range") {
      setIsCustomDateRange(true)
      if(selectedStrategyDateRange == "Custom Date Range" && defaultDateRange == "Custom Date Range" && selectedCustomDateRange == "Custom Date Range") {
        let dateConfig = {
          from : strategyCustomDates?.start ? new Date(strategyCustomDates.start) : undefined,
          to: strategyCustomDates?.end ? new Date(strategyCustomDates.end) : undefined
        }
        setDateRangeConfigs(dateConfig)
      }
    } else setIsCustomDateRange(false);
    if (selectedCustomDateRange !== "Specific Month") {
      setIsSpecificMonth(false);
    } else setIsSpecificMonth(true);
    setStartDateCurrent(startDate);

    var endDate = new Date(
      moment(new Date()).subtract(1, "weeks").endOf("isoWeek")
    );
    if (selectedCustomDateRange === "Prior Week") setEndDateCurrent(endDate);
    else setEndDateCurrent(new Date(returnFormatedDate(new Date())));
  }, [selectedCustomDateRange]);

  useEffect(() => {
    setModifiers({
      currentDateRange: {
        from: startDateCurrent,
        to: endDateCurrent,
      },
      outside: false,
    });
  }, [startDateCurrent, endDateCurrent]);

  const onToggleDatepicker = (e) => {
    setDisplayDatepicker(e);
  };

  useEffect(() => {
    if (selectedStrategyDateRange == 'Default'){
      if(selectedCustomDateRange != 'Default'){
        setSelectedDateRange(selectedStrategyDateRange)
      }
    }
  },[selectedStrategyDateRange])

  const renderSelectedDates = () => {
    if (!selectedStrategyDateRange)
      return (
        <span className="range">
          {defaultDateRange}
        </span>
      );
    if (selectedStrategyDateRange === "Specific Month") {
      return (
        <span className="range">
          {`${moment(strategyAdditionalParamaters, "MM").format("MMMM")} - ${moment(strategyCustomDates?.start,"YYYY-MM-DD").year()}`}
        </span>
      );
    } else if (selectedStrategyDateRange === "Custom Date Range") {
      return (
        <span className="custom-date-range">
          {selectedCustomDates?.from
            ? getFormattedDate(selectedCustomDates.from)
            : ""}
          -
          {selectedCustomDates?.to
            ? getFormattedDate(selectedCustomDates.to)
            : ""}
        </span>
      );
    } else
      return (
        <span className="range">
          {selectedStrategyDateRange}
        </span>
      );
  };

  const handleApplyButtonClicked = () => {
    if (selectedCustomDateRange === "Custom Date Range") {
      dispatch(
        alertStrategyCustomDates({
          start: returnFormatedDate(dateRangeConfigs.from),
          end: returnFormatedDate(dateRangeConfigs.to),
        })
      );
      dispatch({
        type: "SELECTED_STRATEGYCUSTOM_DATES",
        payload: { from: dateRangeConfigs.from, to: dateRangeConfigs.to },
      });
      dispatch(strategyAdditionalParameters(0));
    } else if (selectedCustomDateRange === "Specific Month") {
      var startDate = moment([selectedSpecificYear, selectedSpecificMonth]);
      var endDate = moment(startDate).endOf("month");
      dispatch(strategyAdditionalParameters(selectedSpecificMonth + 1));
      dispatch(
        alertStrategyCustomDates({
          start: returnFormatedDate(startDate.toDate()),
          end: returnFormatedDate(endDate.toDate()),
        })
      );
    } else {
      dispatch(
        alertStrategyCustomDates({
          start: returnFormatedDate(startDateCurrent),
          end: returnFormatedDate(endDateCurrent),
        })
      );
      dispatch(strategyAdditionalParameters(0));
    }

    dispatch(strategySelectedDateRange(selectedCustomDateRange));
  };

  const handleResetClicked = () => {
    defaultDateRange = "Default"
    setSelectedDateRange(defaultDateRange);
    setSelectedSpecificMonth(-1);
    setSelectedSpecificYear(new Date().getFullYear());
    dispatch(strategySelectedDateRange(defaultDateRange));
    dispatch(
      alertStrategyCustomDates({
        start: returnFormatedDate(
          new Date(moment(new Date()).startOf("month"))          ),
        end: returnFormatedDate(
          new Date(moment(new Date()))
        )
      }));
    setDateRangeConfigs({
      from: undefined,
      to: undefined,
    });
  };
  return (
    <Dropdown
      onToggle={onToggleDatepicker}
      show={displayDatepicker}
      className="mr-2"
    >
      <Dropdown.Toggle variant="light" id="dropdown-datepicker" disabled={!selectedStrategies?.length}>
        <div id="date-range-picker" className="date-range-picker">
          {renderSelectedDates()}
          <i className="far fa-calendar-alt clander-icon"></i>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu id="datepicker-menu" toggle={false}>
        <div className="custom-date-flex">
          <Datepicker
            modifiers={modifiers}
            endDateCurrent={endDateCurrent}
            dateRange={dateRangeConfigs}
            handleDayClick={handleDayClick}
            isCustomDateRange={isCustomDateRange}
            isSpecificMonthView={isSpecificMonth}
            selectMonthHandler={selectMonthHandler}
            selectedSpecificYearHandler={selectedSpecificYearHandler}
            selectedSpecificYear={selectedSpecificYear}
            selectedSpecificMonth={selectedSpecificMonth}
          />
          <div>
            <div>
              <p className="blue-heading">Date Range</p>
              <select
                className="form-control"
                value={selectedCustomDateRange}
                onChange={(e) => {
                  setSelectedDateRange(e.target.value);
                }}
              >
                <option value="Current Week">Current Week</option>
                <option value="Prior Week">Prior Week</option>
                <option value="Current Month">Current Month</option>
                <option value="Specific Month">Specific Month</option>
                <option value="Default">Default</option>
                <option value="Custom Date Range">Custom Date Range</option>
              </select>
              <div id="footer-buttons" className="mt-4">
                <button
                  className="btn btn-disabled  mr-2"
                  onClick={(e) => {
                    handleResetClicked();
                  }}
                >
                  Reset
                </button>
                <button
                  className={`btn  ${
                    selectedCustomDateRange === "Custom Date Range" ? dateRangeConfigs.from && dateRangeConfigs.to ? "btn-apply" : "btn-disabled-apply"
                    : selectedCustomDateRange === "Specific Month" ? isSpecificMonth && selectedSpecificMonth >=0 && selectedSpecificMonth !== '' ? "btn-apply" : "btn-disabled-apply" : "btn-apply"
                  }`}
                  onClick={(e) => {
                    onToggleDatepicker(false);
                    handleApplyButtonClicked();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

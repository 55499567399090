import React, { Component } from 'react';
import AdminPageList from './AdminPageList';
import DashboardBasicDetails from './DashboardBasicDetails';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllPagesForAdmin } from '../../actions';
import _get from 'lodash/get';
import Modal from 'react-bootstrap/Modal';
import { PlusCircleFill } from '../../components/Icons'
import BarLoader from "react-spinners/BarLoader";
import {addCommonActivityCaptureDetail} from '../../helper'
//To create new dashboard
class AdminDefaultView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "Active",
            loading:false,
            showAddNewDashboardModal: false,
            viewBuilderJSON: {
                "viewId": null,
                "viewName": null,
                "viewDescription": null,
                "viewStatus": "Draft",
                "htmlTemp": {},
                "projectId": null,
                "categoryId": null,
                "projectName": null,
                "categoryName": null,
                linkForDocumentation: null,
                displayAddNewProjectModal: false,
                displayAddNewSubprojectModal: false,
                subprojectName: null,
                projectName: null,
                oktaGroupAdmin: null,
                oktaGroupRead: null,
                logo: null,
                defaultDateRange: 'Last 1 week'
            }
        }
    }
    verisonsView() {
        this.props.history.push('/admin/versions')
    }

    componentDidMount() {
        this.setState({loading:true});
        addCommonActivityCaptureDetail({page : "Dashboard",globalFilters:this.props.selectedGlobalFilters ,strategyFilters: this.props.selectedStrategies})
        Promise.all([
            this.props.getAllPagesForAdminAPI({ viewStatus: this.state.selectedTab }),
        ]).then( (values)=> {
            this.setState({loading:false});
        });
        
    }
    changeUserSelectedTab = (tab) => {

        if (this.state.selectedTab !== tab) {
            this.setState({ selectedTab: tab,loading:true }, () => {
                Promise.all([
                    this.props.getAllPagesForAdminAPI({ viewStatus: this.state.selectedTab }),
                ]).then( (values)=> {
                    this.setState({loading:false});
                });
            });
        }
    }
    renderAddNewDashboardModal() {
        return (
            <Modal size={'md'} show={this.state.showAddNewDashboardModal} onHide={() => {
                this.setState({ showAddNewDashboardModal: false });
            }} id="setting_modal" >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="modalForWidgetLabel"> Add New Dashboard</h5>
                </Modal.Header>
                <Modal.Body className="bg-gray">
                    <DashboardBasicDetails
                        hideSettingsWindow={(e) => { this.setState({ showAddNewDashboardModal: false }) }}
                        viewBuilderJSON={this.state.viewBuilderJSON} />
                </Modal.Body>
            </Modal>
        )
    }
    renderPageListBasedOnSelection() {
        return (
            <AdminPageList
                type={this.state.selectedTab}
                pages={this.props.allPagesForAdmin}
            />
        )
    }

    render() {
        return (
            <div className="container-fluid  default-top  bg-gray p-5 full-screen-height">
                {this.renderAddNewDashboardModal()}
                <div className="row my-3 d-flex justify-content-between align-items-center">
                    <div >
                        <span className="header">Dashboards</span>
                    </div>
                    <div>
                        <button className="btn " onClick={(e) => {
                            this.setState({ showAddNewDashboardModal: true });
                        }}><PlusCircleFill />New Dashboard</button>
                    </div>
                </div>
                <div className="row bg-white ">
                
                    <div className=" d-flex pl-3 dashboard-button-row" onClick={(e)=>{
                        this.changeUserSelectedTab(e.target.innerText);
                    }}>
                        <button className={`btn btn-plain ${this.state.selectedTab==="Active"?"active":null}`} >Active</button>
                        <button className={`btn btn-plain ${this.state.selectedTab==="Inactive"?"active":null}`}>Inactive</button>
                        <button className={`btn btn-plain ${this.state.selectedTab==="Draft"?"active":null}`}>Draft</button>
                    </div>
                </div>
                {this.state.loading ?
                    <div className=" loading_wrapper">
                        <div className="sweet-loading">
                            <BarLoader
                                size={150}
                                color={"#123abc"}
                                height={4}
                                width={100}
                                loading={true}
                            />
                        </div>

                    </div>: this.renderPageListBasedOnSelection()}
            </div>
        );
    }
}

function mapStateToProps(state) {

    let allPagesForAdmin = _get(state, 'adminReducer.allAdminViews.responseData', []);
    let selectedGlobalFilters = _get(state, 'globalReducer.selectedGlobalFilters', {});
  
    let selectedStrategies = _get(
      state,
      "strategyReducer.selectedStrategies",
      []
    );
    return ({
        allPagesForAdmin: allPagesForAdmin,
        selectedGlobalFilters,
        selectedStrategies
    })
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllPagesForAdminAPI: getAllPagesForAdmin
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDefaultView);
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import {
	getInsightsTable,
	getReportDetailsByMetricName,
	getCrispLink,
	getSkuHealthChart,
} from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import {
	convertToKMB,
	getOtherFilterInformation,
	splitRetailerAndCountry,
	formatDate,
	getFirstAndLastDate,
	getCurrentMonthRange,
} from "../../helper";
import { saveAs } from "file-saver";
import NewInsightsDetailsModal from "./NewInsightsDetailsModal.js";
import {
	ExcelfileIcon,
	RightArrowUpIcon,
	CarretIconUp,
	CarretIconDown,
} from "../../components/Icons";
import BarLoader from "react-spinners/BarLoader";
import moment from "moment";
import * as XLSX from "xlsx-js-style";

const NewInsightsTable = () => {
	let dispatch = useDispatch();
	const getSelectedGlobalFilters = useSelector(
		(state) => state.globalReducer.selectedGlobalFilters
	);
	const getRetailerAndCountry = useSelector(
		(state) => state.globalReducer.subproject
	);
	const [insightDigitalChartData, setInsightDigitalChartData] = useState([]);
	let {
		insightsTabsDetailsData,
		insightsDatesParamaterdata,
		insightsTabsResponseData,
		selectedSelectionAndMetricName,
		insightsStartEndDatesdata,
	} = useSelector((state) => state.insightReducer);
	let {
		selectedCustomerKey,
		selectedGlobalFilters,
		selectedRetailerCurrency,
		dynamicPriceFormatting,
		subproject,
		globalFilter,
		insightsDateValues,
	} = useSelector((state) => state.globalReducer);
	const [insightTableData, setInsightTableData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showInsightCellModal, setShowInsightCellModal] = useState(false);
	const [mqyToValue, setMqyToValue] = useState("");
	let finalSectionName = insightsTabsDetailsData?.finalData;
	let startendDate = insightsStartEndDatesdata?.start;

	const responseHandlerupdate = (response) => {
		const hasInsightTableData =
			response?.data?.responseData?.insightsTableData?.length >= 0;
		if (hasInsightTableData) {
			setInsightTableData(response.data.responseData.insightsTableData);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};
	const responseHandlerupdateChartData = (response) => {
		const hasInsightChartData = response?.data?.responseData;
		if (hasInsightChartData) {
			const chartData = response?.data?.responseData;
			setInsightDigitalChartData(chartData);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};
	const updatedDataObj = { ...selectedGlobalFilters };
	const removeValueFromObjectArray = (obj) => {
		const key = "rpln_flag";
		if (
			obj.hasOwnProperty(key) &&
			obj[key].length > 0 &&
			selectedSelectionAndMetricName?.sectionName === "Digital Shelf"
		) {
			delete obj[key];
		}
		return obj;
	};
	const updatedObject =
		selectedSelectionAndMetricName?.sectionName === "Digital Shelf"
			? removeValueFromObjectArray(updatedDataObj)
			: selectedGlobalFilters;
	useEffect(() => {
		setLoading(true);
		if (finalSectionName?.METRIC_NAME !== undefined) {
			if (finalSectionName?.SECTION_NAME !== "Digital Shelf") {
				dispatch(
					getInsightsTable(
						{
							customerKey: selectedCustomerKey,
							filterValues: updatedObject,
							sectionName: finalSectionName?.SECTION_NAME,
							metricNames: finalSectionName?.METRIC_NAME,
							selectedDateRange:
								insightsStartEndDatesdata?.start !== undefined
									? insightsStartEndDatesdata
									: "",
						},
						responseHandlerupdate
					)
				);
			} else {
				dispatch(
					getInsightsTable(
						{
							customerKey: selectedCustomerKey,
							filterValues: updatedObject,
							sectionName: finalSectionName?.SECTION_NAME,
							metricNames: finalSectionName?.METRIC_NAME,
						},
						responseHandlerupdate
					)
				);
			}
		}
	}, [
		selectedGlobalFilters,
		insightsTabsResponseData,
		insightsStartEndDatesdata.start,
		dispatch,
		finalSectionName,
		insightsStartEndDatesdata,
		selectedCustomerKey,
		updatedObject,
	]);

	useEffect(() => {
		dispatch(
			getSkuHealthChart(
				{
					customerKey: selectedCustomerKey,
					filterValues: updatedObject,
				},
				responseHandlerupdateChartData
			)
		);
	}, [dispatch, selectedCustomerKey, updatedObject]);
	function getSheetData(data, header) {
		let fields = Object.keys(data[0]);
		let sheetData = data.map(function (row) {
			return fields.map(function (fieldName) {
				return row[fieldName] ? row[fieldName] : "";
			});
		});
		sheetData.unshift(header);
		return sheetData;
	}
	const numberWithCommas = (val) => {
		let resultNum =
			val !== null && val !== undefined && val !== "NaN"
				? parseFloat(val)
				: 0;
		return (
			selectedRetailerCurrency +
			resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		);
	};
	const downloadData = () => {
		let insightTableHeaders = [];
		let bodyData = [];
		if (insightTableData.length) {
			if (
				startendDate !== undefined &&
				finalSectionName?.SECTION_NAME !== "Digital Shelf"
			) {
				insightTableHeaders = [
					" ",
					"Last Day Value",
					"Last Day PoP",
					"Last Day YoY",
					"MTM Value",
					"MTM POP",
					"MTM YOY",
					"QTM Value",
					"QTM POP",
					"QTM YOY",
					"YTM Value",
					"YTM YOY",
				];
			} else {
				insightTableHeaders = [
					" ",
					"Last Day Value",
					"Last Day PoP",
					"Last Day YoY",
					"MTD Value",
					"MTD POP",
					"MTD YOY",
					"QTD Value",
					"QTD POP",
					"QTD YOY",
					"YTD Value",
					"YTD YOY",
				];
			}
			let multilehund = 100;
			bodyData = insightTableData.map((item) => {
				let ldtypecheck = item?.LD_TYPE?.toLowerCase(),
					mtdtypecheck = item?.MTM_TYPE
						? item?.MTM_TYPE.toLowerCase()
						: item?.MTD_TYPE?.toLowerCase(),
					qtdtypecheck = item?.QTM_TYPE
						? item?.QTM_TYPE.toLowerCase()
						: item?.QTD_TYPE?.toLowerCase(),
					ytdtypecheck = item?.YTM_TYPE
						? item?.YTM_TYPE.toLowerCase()
						: item?.YTD_TYPE?.toLowerCase(),
					ldVal = "",
					mtdVal = "",
					qtdVal = "",
					ytdVal = "",
					percent = "";
				if (
					item.DISPLAY_NAME === "Coupon ROAS" &&
					(subproject === "Shopee-eCom-Malaysia" ||
						subproject === "Shopee-eCom-Vietnam")
				) {
					ldVal = "N/A";
					mtdVal = "N/A";
					qtdVal = "N/A";
					ytdVal = "N/A";
					percent = "N/A";
				} else {
					ldVal = ldtypecheck === "percentage" ? "0%" : "0";
					mtdVal = mtdtypecheck === "percentage" ? "0%" : "0";
					qtdVal = qtdtypecheck === "percentage" ? "0%" : "0";
					ytdVal = ytdtypecheck === "percentage" ? "0%" : "0";
					percent = "0%";
				}
				let mtmdVal = "",
					qtmdVal = "",
					ytmdVal = "",
					mtmdPoP = "",
					mtmdYoY = "",
					qtmdPoP = "",
					qtmdYoY = "",
					ytmdYoY = "";
				if (
					startendDate !== undefined &&
					finalSectionName?.SECTION_NAME !== "Digital Shelf"
				) {
					mtmdVal = item?.MTM_VALUE;
					qtmdVal = item?.QTM_VALUE;
					ytmdVal = item?.YTM_VALUE;
					mtmdPoP = item?.MTM_POP;
					mtmdYoY = item?.MTM_YOY;
					qtmdPoP = item?.QTM_POP;
					qtmdYoY = item?.QTM_YOY;
					ytmdYoY = item?.YTM_YOY;
				} else {
					mtmdVal = item?.MTD_VALUE;
					qtmdVal = item?.QTD_VALUE;
					ytmdVal = item?.YTD_VALUE;
					mtmdPoP = item?.MTD_POP;
					mtmdYoY = item?.MTD_YOY;
					qtmdPoP = item?.QTD_POP;
					qtmdYoY = item?.QTD_YOY;
					ytmdYoY = item?.YTD_YOY;
				}
				return [
					item.DISPLAY_NAME,
					item?.LD_VALUE
						? mtdtypecheck === "percentage"
							? item.LD_VALUE !== 0
								? (item.LD_VALUE * multilehund).toFixed(0) + "%"
								: `${item.LD_VALUE}%`
							: mtdtypecheck === "text"
							? numberWithCommas(item.LD_VALUE)
							: item.LD_VALUE !== 0
							? item.LD_VALUE.toFixed(2)
							: item.LD_VALUE
						: mtdVal,
					item?.LD_POP
						? (item.LD_POP * multilehund).toFixed(0) + "%"
						: percent,
					item?.LD_YOY
						? (item.LD_YOY * multilehund).toFixed(0) + "%"
						: percent,
					mtmdVal
						? mtdtypecheck === "percentage"
							? mtmdVal !== 0
								? (mtmdVal * multilehund).toFixed(0) + "%"
								: `${mtmdVal}%`
							: mtdtypecheck === "text"
							? numberWithCommas(mtmdVal)
							: parseFloat(mtmdVal)
						: mtdVal,
					mtmdPoP
						? (mtmdPoP * multilehund).toFixed(0) + "%"
						: percent,
					mtmdYoY
						? (mtmdYoY * multilehund).toFixed(0) + "%"
						: percent,
					qtmdVal
						? qtdtypecheck === "percentage"
							? qtmdVal !== 0
								? (qtmdVal * multilehund).toFixed(0) + "%"
								: `${qtmdVal}%`
							: qtdtypecheck === "text"
							? numberWithCommas(qtmdVal)
							: parseFloat(qtmdVal)
						: qtdVal,
					qtmdPoP
						? (qtmdPoP * multilehund).toFixed(0) + "%"
						: percent,
					qtmdYoY
						? (qtmdYoY * multilehund).toFixed(0) + "%"
						: percent,
					ytmdVal
						? ytdtypecheck === "percentage"
							? ytmdVal !== 0
								? (ytmdVal * multilehund).toFixed(0) + "%"
								: `${ytmdVal}%`
							: ytdtypecheck === "text"
							? numberWithCommas(ytmdVal)
							: parseFloat(ytmdVal)
						: ytdVal,
					ytmdYoY
						? (ytmdYoY * multilehund).toFixed(0) + "%"
						: percent,
				];
			});

			const { retailer, country } = splitRetailerAndCountry(
				getRetailerAndCountry
			);
			console.log("insightDigitalChartData", insightDigitalChartData);
			let filtersRows = [];
			const insightHasData =
				Object.keys(insightDigitalChartData).length !== 0 &&
				insightDigitalChartData.constructor !== Object;
			console.log(insightsStartEndDatesdata);
			console.log("hasInsightData", insightHasData);
			if (insightHasData) {
				const getPeriod = getFirstAndLastDate(insightDigitalChartData);
				const formattedDates = getPeriod.map(formatDate);
				const dateFrom = formattedDates[0];
				const dateTo = formattedDates[1];
				const currentPeriod = `${dateFrom} to ${dateTo}`;
				// data for filters and insights table
				filtersRows = [
					["Current period", currentPeriod],
					["Previous Period", "N/A"],
					["Country", country],
					["Retailer", retailer],
					[
						"Categories",
						getSelectedGlobalFilters.kc_category?.length
							? getSelectedGlobalFilters.kc_category.join(", ")
							: "All",
					],
					[
						"Brands",
						getSelectedGlobalFilters.kc_primary_brand?.length
							? getSelectedGlobalFilters.kc_primary_brand.join(
									", "
							  )
							: "All",
					],
					[
						"Other filter information",
						getOtherFilterInformation(getSelectedGlobalFilters),
					],
				];
			} else {
				const currentMonth = getCurrentMonthRange();

				const isCalendarEmpty =
					insightsStartEndDatesdata.start === undefined;
				const currentPeriod = `${insightsStartEndDatesdata.start} to ${insightsStartEndDatesdata.end}`;
				filtersRows = [
					[
						"Current period",
						isCalendarEmpty ? currentMonth : currentPeriod,
					],
					["Previous Period", "N/A"],
					["Country", country],
					["Retailer", retailer],
					[
						"Categories",
						getSelectedGlobalFilters.kc_category?.length
							? getSelectedGlobalFilters.kc_category.join(", ")
							: "All",
					],
					[
						"Brands",
						getSelectedGlobalFilters.kc_primary_brand?.length
							? getSelectedGlobalFilters.kc_primary_brand.join(
									", "
							  )
							: "All",
					],
					[
						"Other filter information",
						getOtherFilterInformation(getSelectedGlobalFilters),
					],
				];
			}
			const sheetData = [...filtersRows];
			const insightsTableData = getSheetData(
				bodyData,
				insightTableHeaders
			);
			sheetData.push(...insightsTableData);

			const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

			// Apply styles to the insight table headers (row 8)
			const headerRowIndex = filtersRows.length; // Row after the filters
			const totalColumns = insightTableHeaders.length;

			// Loop through each header cell and apply styles
			for (let col = 0; col < totalColumns; col++) {
				const cellAddress = XLSX.utils.encode_cell({
					r: headerRowIndex,
					c: col,
				});
				worksheet[cellAddress].s = {
					fill: { fgColor: { rgb: "BFBFBF" } }, // Light grey background
					font: { bold: true }, // Make the text bold
				};
			}

			// Create a workbook and append the worksheet
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

			// Convert workbook to binary format for download
			const excelBuffer = XLSX.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			const dataBlob = new Blob([excelBuffer], {
				type: "application/octet-stream",
			});

			// Save the file
			saveAs(dataBlob, "DownloadKPIS.xlsx");
		}
	};
	let toggleDynamicColumnLogModal = () => {
		let flag = showInsightCellModal;
		setShowInsightCellModal(!flag);
	};
	let responseHandlermodal = (response) => {
		setShowInsightCellModal(true);
	};
	function showDynamicColDialog(cell, row, todate) {
		setMqyToValue(todate);
		dispatch(
			getReportDetailsByMetricName(
				{
					metricName: row?.METRIC_NAME,
					customerKey: selectedCustomerKey,
				},
				responseHandlermodal
			)
		);
	}

	const newValueformatld = (cell, row) => {
		let detailslink = row?.DETAIL_SCREEN,
			ldtypecheck = row?.LD_TYPE?.toLowerCase(),
			timeFrame = "Last Day";
		let rowdisplayName = row?.DISPLAY_NAME;
		if (cell !== null && cell !== undefined) {
			let newcelldfault = cell !== 0 ? cell.toFixed(2) : cell;
			let newcellprecent = cell !== 0 ? (cell * 100).toFixed(0) : cell;
			return (
				<>
					{detailslink === "Y" && rowdisplayName !== "In-Stock" ? (
						<span
							onClick={() =>
								showDynamicColDialog(cell, row, timeFrame)
							}
							className='newValueformat'
						>
							{ldtypecheck === "percentage"
								? `${newcellprecent}%`
								: ldtypecheck === "text"
								? convertToKMB(cell, selectedRetailerCurrency)
								: newcelldfault}
						</span>
					) : (
						<span>
							{ldtypecheck === "percentage"
								? `${newcellprecent}%`
								: ldtypecheck === "text"
								? convertToKMB(cell, selectedRetailerCurrency)
								: newcelldfault}
						</span>
					)}
				</>
			);
		} else {
			let cell =
				row.DISPLAY_NAME === "Coupon ROAS" &&
				(subproject === "Shopee-eCom-Malaysia" ||
					subproject === "Shopee-eCom-Vietnam")
					? "N/A"
					: ldtypecheck === "percentage"
					? "0%"
					: 0;
			if (
				detailslink === "Y" &&
				rowdisplayName !== "Content Health Score" &&
				rowdisplayName !== "In-Stock"
			) {
				return (
					<span
						onClick={() =>
							showDynamicColDialog(cell, row, timeFrame)
						}
						className='newValueformat'
					>
						{cell}
					</span>
				);
			} else {
				return cell;
			}
		}
	};

	const newValueformatmtd = (cell, row) => {
		let detailslink = row?.DETAIL_SCREEN,
			mtdtypecheck = row?.MTM_TYPE
				? row?.MTM_TYPE.toLowerCase()
				: row?.MTD_TYPE?.toLowerCase(),
			timeFrame = row?.MTM_TIMEFRAME
				? row?.MTM_TIMEFRAME
				: row?.MTD_TIMEFRAME
				? row?.MTD_TIMEFRAME
				: startendDate !== undefined
				? "MTM"
				: "MTD";
		let rowdisplayName = row?.DISPLAY_NAME;
		if (cell !== null && cell !== undefined) {
			let newcelldfault = cell !== 0 ? cell.toFixed(2) : cell;
			let newcellprecent = cell !== 0 ? (cell * 100).toFixed(0) : cell;
			return (
				<>
					{detailslink === "Y" &&
					rowdisplayName !== "Content Health Score" &&
					rowdisplayName !== "In-Stock" ? (
						<span
							onClick={() =>
								showDynamicColDialog(cell, row, timeFrame)
							}
							className='newValueformat'
						>
							{mtdtypecheck === "percentage"
								? `${newcellprecent}%`
								: mtdtypecheck === "text"
								? convertToKMB(cell, selectedRetailerCurrency)
								: newcelldfault}
						</span>
					) : (
						<span>
							{mtdtypecheck === "percentage"
								? `${newcellprecent}%`
								: mtdtypecheck === "text"
								? convertToKMB(cell, selectedRetailerCurrency)
								: newcelldfault}
						</span>
					)}
				</>
			);
		} else {
			let cell =
				row.DISPLAY_NAME === "Coupon ROAS" &&
				(subproject === "Shopee-eCom-Malaysia" ||
					subproject === "Shopee-eCom-Vietnam")
					? "N/A"
					: mtdtypecheck === "percentage"
					? "0%"
					: 0;
			if (
				detailslink === "Y" &&
				rowdisplayName !== "Content Health Score" &&
				rowdisplayName !== "In-Stock"
			) {
				return (
					<span
						onClick={() =>
							showDynamicColDialog(cell, row, timeFrame)
						}
						className='newValueformat'
					>
						{cell}
					</span>
				);
			} else {
				return cell;
			}
		}
	};

	const newValueformatqtd = (cell, row) => {
		let detailslink = row?.DETAIL_SCREEN;
		let qtdtypecheck = row?.QTM_TYPE
			? row?.QTM_TYPE.toLowerCase()
			: row?.QTD_TYPE?.toLowerCase();
		let timeFrame = row?.QTM_TIMEFRAME
			? row?.QTM_TIMEFRAME
			: row?.QTD_TIMEFRAME
			? row?.QTD_TIMEFRAME
			: startendDate !== undefined
			? "QTM"
			: "QTD";
		let rowdisplayName = row?.DISPLAY_NAME;
		if (cell !== null && cell !== undefined) {
			let newcelldfault = cell !== 0 ? cell.toFixed(2) : cell;
			let newcellprecent = cell !== 0 ? (cell * 100).toFixed(0) : cell;
			return (
				<>
					{detailslink === "Y" &&
					rowdisplayName !== "Content Health Score" &&
					rowdisplayName !== "In-Stock" ? (
						<span
							onClick={() =>
								showDynamicColDialog(cell, row, timeFrame)
							}
							className='newValueformat'
						>
							{qtdtypecheck === "percentage"
								? `${newcellprecent}%`
								: qtdtypecheck === "text"
								? convertToKMB(cell, selectedRetailerCurrency)
								: newcelldfault}
						</span>
					) : (
						<span>
							{qtdtypecheck === "percentage"
								? `${newcellprecent}%`
								: qtdtypecheck === "text"
								? convertToKMB(cell, selectedRetailerCurrency)
								: newcelldfault}
						</span>
					)}
				</>
			);
		} else {
			let cell =
				row.DISPLAY_NAME === "Coupon ROAS" &&
				(subproject === "Shopee-eCom-Malaysia" ||
					subproject === "Shopee-eCom-Vietnam")
					? "N/A"
					: qtdtypecheck === "percentage"
					? "0%"
					: 0;
			if (
				detailslink === "Y" &&
				rowdisplayName !== "Content Health Score" &&
				rowdisplayName !== "In-Stock"
			) {
				return (
					<span
						onClick={() =>
							showDynamicColDialog(cell, row, timeFrame)
						}
						className='newValueformat'
					>
						{cell}
					</span>
				);
			} else {
				return cell;
			}
		}
	};

	const newValueformatytd = (cell, row) => {
		let detailslink = row?.DETAIL_SCREEN;
		let ytdtypecheck = row?.YTM_TYPE
			? row?.YTM_TYPE.toLowerCase()
			: row?.YTD_TYPE?.toLowerCase();
		let timeFrame = row?.YTM_TIMEFRAME
			? row?.YTM_TIMEFRAME
			: row?.YTD_TIMEFRAME
			? row?.YTD_TIMEFRAME
			: startendDate !== undefined
			? "YTM"
			: "YTD";
		let rowdisplayName = row?.DISPLAY_NAME;
		if (cell !== null && cell !== undefined) {
			let newcelldfault = cell !== 0 ? cell.toFixed(2) : cell;
			let newcellprecent = cell !== 0 ? (cell * 100).toFixed(0) : cell;
			return (
				<>
					{detailslink === "Y" &&
					rowdisplayName !== "Content Health Score" &&
					rowdisplayName !== "In-Stock" ? (
						<span
							onClick={() =>
								showDynamicColDialog(cell, row, timeFrame)
							}
							className='newValueformat'
						>
							{ytdtypecheck === "percentage"
								? `${newcellprecent}%`
								: ytdtypecheck === "text"
								? convertToKMB(cell, selectedRetailerCurrency)
								: newcelldfault}
						</span>
					) : (
						<span>
							{ytdtypecheck === "percentage"
								? `${newcellprecent}%`
								: ytdtypecheck === "text"
								? convertToKMB(cell, selectedRetailerCurrency)
								: newcelldfault}
						</span>
					)}
				</>
			);
		} else {
			let cell =
				row.DISPLAY_NAME === "Coupon ROAS" &&
				(subproject === "Shopee-eCom-Malaysia" ||
					subproject === "Shopee-eCom-Vietnam")
					? "N/A"
					: ytdtypecheck === "percentage"
					? "0%"
					: 0;
			if (
				detailslink === "Y" &&
				rowdisplayName !== "Content Health Score" &&
				rowdisplayName !== "In-Stock"
			) {
				return (
					<span
						onClick={() =>
							showDynamicColDialog(cell, row, timeFrame)
						}
						className='newValueformat'
					>
						{cell}
					</span>
				);
			} else {
				return cell;
			}
		}
	};

	const newpercentValue = (cell, row) => {
		if (cell !== null && cell !== undefined) {
			let newCentVal = cell * 100;
			if (newCentVal >= 0) {
				return (
					<span className='clr-grn'>
						<CarretIconUp />
						{newCentVal.toFixed(0)}%
					</span>
				);
			} else {
				return (
					<span className='clr-rd'>
						<CarretIconDown />
						{newCentVal.toFixed(0) * -1}%
					</span>
				);
			}
		} else {
			let cell =
				row.DISPLAY_NAME === "Coupon ROAS" &&
				(subproject === "Shopee-eCom-Malaysia" ||
					subproject === "Shopee-eCom-Vietnam")
					? "N/A"
					: "0%";
			return (
				<span className='clr-grn'>
					<CarretIconUp />
					{cell}
				</span>
			);
		}
	};

	const metricDisplayName = (cell, row) => {
		if (row?.DETAIL_SCREEN === "Y" && row?.DISPLAY_NAME === "In-Stock") {
			let timeFrame = "";
			return (
				<span
					onClick={() => showDynamicColDialog(cell, row, timeFrame)}
					className='newValueformat'
				>
					{row?.DISPLAY_NAME ? row?.DISPLAY_NAME : row?.METRIC_NAME}{" "}
				</span>
			);
		} else {
			return (
				<>
					{row?.DISPLAY_NAME !== undefined &&
					row?.DISPLAY_NAME !== null
						? row?.DISPLAY_NAME
						: row?.METRIC_NAME}{" "}
				</>
			);
		}
	};
	const showCrispLink = (selecteddisplayName) => {
		dispatch(
			getCrispLink(
				{
					customerKey: selectedCustomerKey,
					displayName: selecteddisplayName,
				},
				(reponse) => {
					window.open(reponse?.data?.responseData?.url, "_blank");
				}
			)
		);
	};
	const dataCrispLink = (cell, row) => {
		return (
			<>
				{cell === "TRUE" ? (
					<div
						className='crisplink'
						onClick={(e) => showCrispLink(row?.DISPLAY_NAME)}
					>
						<RightArrowUpIcon />
						CRISP
					</div>
				) : (
					""
				)}{" "}
			</>
		);
	};
	const LoadingComponent = () => (
		<div id='strategy_wrapper'>
			<div className='container-fluid  default-top loading_insights loading_wrapper bg-w p-5'>
				<div className='sweet-loading'>
					<span>
						<b>Processing...</b>
					</span>
					<div
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<BarLoader width={100} color='#123abc' />
					</div>
				</div>
			</div>
		</div>
	);

	if (loading) {
		return <LoadingComponent />;
	} else {
		let monthVal =
				startendDate !== undefined
					? `${moment(insightsDatesParamaterdata, "MM").format(
							"MMMM"
					  )} - ${moment(
							insightsStartEndDatesdata?.start,
							"YYYY-MM-DD"
					  ).year()}`
					: "Month To Date",
			querterVal = startendDate !== undefined ? "QTM" : "Quarter To Date",
			yearVal = startendDate !== undefined ? "YTM" : "Year To Date";
		return (
			<div className='insighttable mb-5'>
				{showInsightCellModal && (
					<NewInsightsDetailsModal
						showInsightCellModal={showInsightCellModal}
						displayColumnDialogOff={toggleDynamicColumnLogModal}
						mqyToValue={mqyToValue}
					/>
				)}
				<div className='text-right downloadfile' onClick={downloadData}>
					{" "}
					Download <ExcelfileIcon />
				</div>

				<Table bordered className='table_center mb-4'>
					<thead>
						<tr className='tablerow_first'>
							<th
								rowSpan='1'
								colSpan='1'
								style={{ width: "100px" }}
							></th>
							<th colSpan='3' style={{ width: "100px" }}>
								Last Day
							</th>
							<th colSpan='3' style={{ width: "100px" }}>
								{monthVal}
							</th>
							<th colSpan='3' style={{ width: "100px" }}>
								{querterVal}
							</th>
							<th colSpan='2' style={{ width: "100px" }}>
								{yearVal}
							</th>
							{finalSectionName?.CRISP_LINK > 0 && (
								<th colSpan='2' style={{ width: "100px" }}></th>
							)}
						</tr>
						<tr>
							<th style={{ width: "120px" }}></th>
							<th style={{ width: "100px" }}>Value</th>
							<th style={{ width: "90px" }}>PoP</th>
							<th style={{ width: "90px" }}>YoY</th>
							<th style={{ width: "100px" }}>Value</th>
							<th style={{ width: "90px" }}>PoP</th>
							<th style={{ width: "90px" }}>YoY</th>
							<th style={{ width: "100px" }}>Value</th>
							<th style={{ width: "90px" }}>PoP</th>
							<th style={{ width: "90px" }}>YoY</th>
							<th style={{ width: "100px" }}>Value</th>
							<th style={{ width: "90px" }}>YoY</th>
							{finalSectionName?.CRISP_LINK > 0 && (
								<th style={{ width: "90px" }}></th>
							)}
						</tr>
					</thead>
					<tbody>
						{insightTableData.length ? (
							<>
								{insightTableData.map((row, index) => (
									<tr key={index}>
										<td>
											{metricDisplayName(
												row?.DISPLAY_NAME,
												row
											)}
										</td>
										<td>
											{newValueformatld(
												row?.LD_VALUE,
												row
											)}
										</td>
										<td>
											{newpercentValue(row?.LD_POP, row)}
										</td>
										<td>
											{newpercentValue(row.LD_YOY, row)}
										</td>
										<td>
											{newValueformatmtd(
												startendDate !== undefined &&
													finalSectionName?.SECTION_NAME !==
														"Digital Shelf"
													? row.MTM_VALUE
													: row.MTD_VALUE,
												row
											)}
										</td>
										<td>
											{newpercentValue(
												startendDate !== undefined &&
													finalSectionName?.SECTION_NAME !==
														"Digital Shelf"
													? row.MTM_POP
													: row.MTD_POP,
												row
											)}
										</td>
										<td>
											{newpercentValue(
												startendDate !== undefined &&
													finalSectionName?.SECTION_NAME !==
														"Digital Shelf"
													? row.MTM_YOY
													: row.MTD_YOY,
												row
											)}
										</td>
										<td>
											{newValueformatqtd(
												startendDate !== undefined &&
													finalSectionName?.SECTION_NAME !==
														"Digital Shelf"
													? row.QTM_VALUE
													: row.QTD_VALUE,
												row
											)}
										</td>
										<td>
											{newpercentValue(
												startendDate !== undefined &&
													finalSectionName?.SECTION_NAME !==
														"Digital Shelf"
													? row.QTM_POP
													: row.QTD_POP,
												row
											)}
										</td>
										<td>
											{newpercentValue(
												startendDate !== undefined &&
													finalSectionName?.SECTION_NAME !==
														"Digital Shelf"
													? row.QTM_YOY
													: row.QTD_YOY,
												row
											)}
										</td>
										<td>
											{newValueformatytd(
												startendDate !== undefined &&
													finalSectionName?.SECTION_NAME !==
														"Digital Shelf"
													? row.YTM_VALUE
													: row.YTD_VALUE,
												row
											)}
										</td>
										<td>
											{newpercentValue(
												startendDate !== undefined &&
													finalSectionName?.SECTION_NAME !==
														"Digital Shelf"
													? row.YTM_YOY
													: row.YTD_YOY,
												row
											)}
										</td>
										{finalSectionName?.CRISP_LINK > 0 && (
											<td>
												{dataCrispLink(row.CRISP, row)}
											</td>
										)}
									</tr>
								))}{" "}
							</>
						) : (
							<tr>
								<td
									colSpan={
										finalSectionName?.CRISP_LINK > 0
											? 13
											: 12
									}
									style={{ textAlign: "center" }}
								>
									No Data Available
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<div className='row mt-2 mb-4'>
					<div class='col-md-12 col-xs-12 col-sm-12 col-lg-12'>
						<span class=''>
							Showing rows{" "}
							{insightTableData.length > 0 ? (
								<span>
									1 to {insightTableData.length} of{" "}
									{insightTableData.length}
								</span>
							) : (
								<span>0 to 0 of 0</span>
							)}
						</span>
					</div>
				</div>
				<div className='f-15'>
					{" "}
					<strong> Note: </strong>{" "}
					<i>
						{" "}
						<strong> PoP:</strong> Period over Period percentage
						comparison. <strong>YoY:</strong> Year over Year
						percentage comparison{" "}
					</i>
				</div>
				{finalSectionName?.SECTION_NAME === "Digital Shelf" && (
					<div className='mb-3 f-15 ml-5 pl-2'>
						<i>
							{" "}
							<strong> Share of Search: </strong>How many K-C SKUs
							are in the Top 10 results measured by keyword.
							Keywords are configured in the provider.
						</i>
					</div>
				)}
			</div>
		);
	}
};

export default NewInsightsTable;
import React, { useState, useEffect } from 'react';
import { getSentimentCountChart, getSentimentAVGChart, getSentimentTrends, getSentimetTableCount } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import {
  LineChart, XAxis, Tooltip, YAxis, Label,
  Legend, CartesianGrid, Line,
  ResponsiveContainer, LabelList
} from "recharts";
import moment from "moment";
// import BootstrapTable from 'react-bootstrap-table-next';
import { Table } from "react-bootstrap";
import BarLoader from "react-spinners/BarLoader";

const SentimentSectionCharts = () => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [sentimentChartData, setSentimentChartData] = useState([]);
  const [sentimentAvgChartData, setSentimentAvgChartData] = useState([]);
  const [sentimentTrendsChartData, setSentimentTrendsChartData] = useState([]);
  const [sentimentTableData, setSentimentTableData] = useState([]);
  const { selectedCustomerKey, selectedGlobalFilters, insightsDateValues } = useSelector((state) => state.globalReducer);
  const { dateFilterAndRange } = useSelector((state) => state.newRecommendationTableData);
  const dateFormat = (dateVal) => moment(dateVal).format('D-M-YYYY');
  const formatXAxis = (tickItem) => moment(tickItem).format('MMM-YYYY'); 
  
  const updatedDataObj = { ...selectedGlobalFilters };
  const removeValueFromObjectArray = (obj) => {
      const key = 'rpln_flag';
      if (obj.hasOwnProperty(key) && obj[key].length > 0) {
      delete obj[key];
      }
      return obj;
  }
  const updatedObject = removeValueFromObjectArray(updatedDataObj);

  useEffect(() => {
    setLoading(true);
    dispatch(getSentimentCountChart({ customerKey: selectedCustomerKey, filterValues: updatedObject, date:{startDate:dateFormat(insightsDateValues?.startDateCurrent),endDate:dateFormat(insightsDateValues?.endDateCurrent)} },(res) => {
      if (res?.data?.responseType === 'success') {
        setSentimentChartData(res?.data?.responseData);
      } else {
        setSentimentChartData([]);
      }      
    }));
    dispatch(getSentimentAVGChart({ customerKey: selectedCustomerKey, filterValues: updatedObject, date:{startDate:dateFormat(insightsDateValues?.startDateCurrent),endDate:dateFormat(insightsDateValues?.endDateCurrent)} },(res) => {
      if (res?.data?.responseType === 'success') {
        setSentimentAvgChartData(res?.data?.responseData);
      } else {
        setSentimentAvgChartData([]);
      } 
    }));
    dispatch(getSentimentTrends({ customerKey: selectedCustomerKey, filterValues: updatedObject, date:{startDate:dateFormat(insightsDateValues?.startDateCurrent),endDate:dateFormat(insightsDateValues?.endDateCurrent)} },(res) => {
      if (res?.data?.responseType === 'success') {
        setSentimentTrendsChartData(res?.data?.responseData);        
      } else {
        setSentimentTrendsChartData([]);
      } 
      setLoading(false);
    }));
  }, [selectedGlobalFilters, dateFilterAndRange?.dateRange]);

  useEffect(() => {    
    dispatch(getSentimetTableCount({ customerKey: selectedCustomerKey, filterValues: updatedObject,
      date:{startDate:dateFormat(insightsDateValues?.startDateCurrent),endDate:dateFormat(insightsDateValues?.endDateCurrent)} },(res) => {
      if (res?.data?.responseType === 'success') {
        setSentimentTableData(res?.data?.responseData);
      } else {
        setSentimentTableData([]);
      }       
    }))
  }, [selectedGlobalFilters, dateFilterAndRange?.dateRange]);

  let minValue = 0;
  let maxValue = 100;

  sentimentTrendsChartData?.length && sentimentTrendsChartData.forEach(obj => {
      Object.keys(obj).forEach(prop => {
          const value = parseFloat(obj[prop]);
          if (!isNaN(value)) {
              minValue = Math.min(minValue, value);
              maxValue = Math.max(maxValue, value);
          }
      });
  });

  const labelFormatterNumcupr = (num) => {
    if (num !== null) {
      return num >= 1000000000 ? (parseFloat(num) / 1000000000).toFixed(0).replace(/\.0$/, '') + 'B' : num >= 1000000 ? (parseFloat(num) / 1000000).toFixed(0).replace(/\.0$/, '') + 'M' :
        num >= 1000 ? (parseFloat(num) / 1000).toFixed(0).replace(/\.0$/, '') + 'K' : num < 1000 ? parseFloat(num).toFixed(0).replace(/\.0$/, '') : num;
    }
  };
  function numberWithCommas(val) {
    let result = (val - Math.floor(val)) !== 0;
    let resultNum = val !== null ? parseFloat(val).toFixed(0) : 0;
    return resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {payload.map((pld, index) => (
            <>
              {index === 0 &&
                <div key={index} style={{ display: "inline-block", backgroundColor: "#000", color: "#fff", padding: "10px 15px" }}>
                  {pld.payload.DATE ? <div><span className='' >DATE:</span> {pld.payload.DATE}</div> : ''}
                  {pld.payload.POSITIVE ? <div>Positive Count: {numberWithCommas(pld.payload.POSITIVE)} </div> : ''}
                  {pld.payload.NEGATIVE ? <div>Negative Count: {numberWithCommas(pld.payload.NEGATIVE)}</div> : ''}
                  {pld.payload.AVG_PRODUCT_RATING ? <div>Avg Product Rating: {labelFormatterVal(pld.payload.AVG_PRODUCT_RATING)}</div> : ''}
                  {pld.payload.AVG_CUSTOMER_RATING ? <div>Avg Customer Rating: {labelFormatterVal(pld.payload.AVG_CUSTOMER_RATING)}</div> : ''}
                  {pld.payload.PACKAGING ? <div>Packaging: {numberWithCommas(pld.payload.PACKAGING)} </div> : ''}
                  {pld.payload.QUALITY ? <div>Quality: {numberWithCommas(pld.payload.QUALITY)}</div> : ''}
                  {pld.payload.PRICING ? <div>Pricing: {numberWithCommas(pld.payload.PRICING)}</div> : ''}
                  {pld.payload.DELIVERY ? <div>Delivery: {numberWithCommas(pld.payload.DELIVERY)}</div> : ''}
                  {pld.payload.PROMOTIONS ? <div>Promotions: {numberWithCommas(pld.payload.PROMOTIONS)} </div> : ''}
                  {pld.payload.CUSTOMER_SATISFACTION ? <div>Customer Satisfaction : {numberWithCommas(pld.payload.CUSTOMER_SATISFACTION)}</div> : ''}
                  {pld.payload.PACK_SIZE ? <div>Pack Size: {numberWithCommas(pld.payload.PACK_SIZE)}</div> : ''}
                  {pld.payload.OTHERS ? <div>Others: {numberWithCommas(pld.payload.OTHERS)}</div> : ''}
                </div>}
            </>
          ))}
        </div>
      );
    }
    return null;
  };
  const labelWithoutFixedVal = (num) => num !== null ? parseFloat(num).toFixed(0) : '';
  const labelFormatterVal = (num) => {
    if (num !== null) {
      return parseFloat(num).toFixed(1);
    }
  };
  const countGraph = () => {
    return (
      <>
        <div className='mt-2'><strong>Positive Count and Negative Count</strong> </div>
        <ResponsiveContainer width="100%" height={335}>
          <LineChart data={sentimentChartData}
            margin={{ top: 40, right: 50, bottom: 35 }}>
            <XAxis dataKey="DATE" textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} dy={5} />
            <YAxis axisLine={false} style={{ fontSize: "10px" }} />              
            <CartesianGrid stroke="#e8eaec" />
            <Line dataKey="POSITIVE" isAnimationActive={false} name='Positive Count' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#50e432" strokeWidth="2" dot={{ stroke: "#50e432", strokeWidth: 2, fill: "#50e432" }} />
            <Line dataKey="NEGATIVE" isAnimationActive={false} name='Negative Count' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#f74c13" strokeWidth="2" dot={{ stroke: "#f74c13", strokeWidth: 2, fill: "#f74c13" }} />
            <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
            <Legend verticalAlign="top" iconType="circle" align="left" />
          </LineChart>
        </ResponsiveContainer>
        <div className='mt-4'><strong>Average of Product Rating and Average of Customer Rating</strong> </div>
        <ResponsiveContainer width="100%" height={335}>
          <LineChart data={sentimentAvgChartData}
            margin={{ top: 40, right: 50, bottom: 35}}>
            <XAxis dataKey="DATE" textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} dy={5} />
            <YAxis axisLine={false} style={{ fontSize: "10px" }} />
            <CartesianGrid stroke="#e8eaec" />
            <Line dataKey="AVG_PRODUCT_RATING" isAnimationActive={false} name='Average of Product Rating' label={{ position: 'top', formatter: labelFormatterVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#118dff" strokeWidth="2" dot={{ stroke: "#118dff", strokeWidth: 2, fill: "#118dff" }} />
            <Line dataKey="AVG_CUSTOMER_RATING" isAnimationActive={false} name='Average of Customer Rating' label={{ position: 'top', formatter: labelFormatterVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#12239e" strokeWidth="2" dot={{ stroke: "#12239e", strokeWidth: 2, fill: "#12239e" }} />
            <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
            <Legend verticalAlign="top" iconType="circle" align="left" />
          </LineChart>
        </ResponsiveContainer>
        <div className='mt-4'><strong>Topic Trends</strong> </div>
        <ResponsiveContainer width="100%" height={350}>
          <LineChart data={sentimentTrendsChartData}
            margin={{ top: 46, right: 50, bottom: 35}}>
            <XAxis dataKey="DATE" textAnchor="end" tick={{ angle: -45 }} style={{ fontSize: "10px" }} dy={5} />            
            <YAxis axisLine={false} style={{ fontSize: "10px" }} domain={[minValue, maxValue]}  />
            <CartesianGrid stroke="#e8eaec" />
            <Line dataKey="PACKAGING" isAnimationActive={false} name='Packaging' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#118dff" strokeWidth="2" dot={{ stroke: "#118dff", strokeWidth: 2, fill: "#118dff" }} />
            <Line dataKey="QUALITY" isAnimationActive={false} name='Quality' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#12239e" strokeWidth="2" dot={{ stroke: "#12239e", strokeWidth: 2, fill: "#12239e" }} />
            <Line dataKey="PRICING" isAnimationActive={false} name='Pricing' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#e66c37" strokeWidth="2" dot={{ stroke: "#e66c37", strokeWidth: 2, fill: "#e66c37" }} />
            <Line dataKey="DELIVERY" isAnimationActive={false} name='Delivery' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#6b007b" strokeWidth="2" dot={{ stroke: "#6b007b", strokeWidth: 2, fill: "#6b007b" }} />
            <Line dataKey="PROMOTIONS" isAnimationActive={false} name='Promotions' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#e044a7" strokeWidth="2" dot={{ stroke: "#e044a7", strokeWidth: 2, fill: "#e044a7" }} />
            <Line dataKey="CUSTOMER_SATISFACTION" isAnimationActive={false} name='Customer Satisfaction' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#744ec2" strokeWidth="2" dot={{ stroke: "#744ec2", strokeWidth: 2, fill: "#744ec2" }} />
            <Line dataKey="PACK_SIZE" isAnimationActive={false} name='Pack Size' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#d9b300" strokeWidth="2" dot={{ stroke: "#d9b300", strokeWidth: 2, fill: "#d9b300" }} />
            <Line dataKey="OTHERS" isAnimationActive={false} name='Others' label={{ position: 'top', formatter: labelWithoutFixedVal, fill: "#9283a0", fontSize: "10px" }}
              stroke="#d64550" strokeWidth="2" dot={{ stroke: "#d64550", strokeWidth: 2, fill: "#d64550" }} />

            <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
            <Legend verticalAlign="top" iconType="circle" align="left" />
          </LineChart>
        </ResponsiveContainer>
      </>
    )
  };
  const NoDataIndication = () => (
    <div className="spinner text-center p-2">
      No Data Available
    </div>
  );
  const reviewPercent = (cell) => {
    let result = cell !== 'NaN' && cell !== null && cell !== undefined ? cell !== 0 ? parseFloat(cell).toFixed(2) : cell : 0;
    return result
  };
  let columns = [{
      text: "Platform",
      dataField: "KC_PLATFORM"
    },
    {
      text: "Total Review / Topics",
      dataField: "TOTAL",
      formatter: (cell) => cell ? numberWithCommas(cell) : 0
    },
    {
      text: "Positive Review / Topics",
      dataField: "POSITIVE",
      formatter: (cell) => cell ? numberWithCommas(cell) : 0
    },
    {
      text: "Negative Review / Topics",
      dataField: "NEGATIVE",
      formatter: (cell) => cell ? numberWithCommas(cell) : 0
    },
    {
      text: "Positive Review %",
      dataField: "percentage",
      formatter: reviewPercent
    }
  ]
  if(loading) {
    return (<div id="strategy_wrapper">
    <div className="container-fluid  default-top loading_insights loading_wrapper bg-w p-5">
      <div className="sweet-loading">
        <BarLoader width={100} color="#123abc" />
      </div>
    </div>
  </div>)
  } else {
    return (
      <div className='ratingreview graph-mar'>
          {countGraph()}
          <div className='mr-5 pr-3'>
          <Table bordered striped className='table_center mb-4'>
                                    <thead>
                                        <tr className='sentimentbdycls'>                                           
                                            <th>Platform</th>
                                            <th>Total Review / Topics</th>
                                            <th>Positive Review / Topics</th>
                                            <th>Negative Review / Topics</th>
                                            <th>Positive Review %</th>
                                        </tr>
                                    </thead>
                                    <tbody className='topmvtablestle'>
                                        {sentimentTableData.length ? <>
                                            {sentimentTableData.map((row, index) => (
                                                <tr key={index}>                                                   
                                                    <td>{row?.KC_PLATFORM}</td>
                                                    <td>{numberWithCommas(row?.TOTAL)}</td>
                                                    <td>{numberWithCommas(row?.POSITIVE)}</td>
                                                    <td>{numberWithCommas(row?.NEGATIVE)}</td>
                                                    <td>{reviewPercent(row?.percentage)}</td>
                                                </tr>
                                            ))} </>
                                            : <tr><td colSpan={5} style={{ textAlign: 'center' }}>No Data Available</td></tr>}
                                    </tbody>
                                </Table>
          {/* <BootstrapTable remote bodyClasses="sentimentcls" headerClasses="sentimentbdycls" data={sentimentTableData}
                columns={ columns } keyField="sentiment" noDataIndication={ () => <NoDataIndication /> } /> */}
          </div>          
      </div>
    )
  }
};

export default SentimentSectionCharts;
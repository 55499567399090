import React, { Component } from 'react';
import Sidebar from '../../components/Sidebar';
// import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import DOMPurify from 'dompurify';

import { getCookie, getFormattedDate } from '../../helper';
import Filters from '../../components/Filters';
import {
  getAllPagesForAdmin, getSelectedView, getEmbedToken, updateViewBuilderJSON,
  getLastDataRefreshDate, getSavedFilters
} from '../../actions';
import cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import {Report} from 'powerbi-report-component';
import BarLoader from "react-spinners/BarLoader";
import {addCommonActivityCaptureDetail} from '../../helper';
import Card from "react-bootstrap/Card";
import LatestModal from '../../components/LatestModal';
import { withRouter } from 'react-router-dom';
//Powerbi Reports
class Insights extends Component {
  constructor(props) {   
    super(props);
      this.state = {
          loading: true,
          selectedTab: "",
          startDate: '23 March 2020',
          currentOption: "Last 1 week",
          selectedDateRange: "Last 1 week",
          compareToOption: "Prior period",
          displayLatestModal:false,
			    showReleaseIdModal: '',
          reportPageName:"",
          sideBarItemCount:1,
          randomKey : Math.random(),
          overallLink: {
            "Forecaster" : {
              "Walmart-BnM-USA" :{
                "text" : "Overall: Aggregated weekly store data rolled up to the UPC / Item",
                "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\WMT_US_BNM\\FORECASTER",
                "reportHeight" : "1900px"
              },
              "Target-BnM-USA" : {
                "text" : "Overall: Aggregated weekly store data rolled up to the UPC / Item",
                "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\TGT_US_BNM\\FORECASTER",
                "reportHeight" : "3500px"
              },
              "Kroger-BnM-USA" : {
                "text" : "Overall: Aggregated weekly store data rolled up to the UPC / Item",
                "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\KRG_US_BNM\\FORECASTER",
                "reportHeight" : "3650px"
              }
            },
              "OSA Universe" : {
                "Walmart-BnM-USA" :{
                  "text" : "Overall: Aggregated weekly store data rolled up to the UPC / Item",
                  "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\WMT_US_BNM\\OSA_UNIVERSE",
                  "reportHeight" : "2400px"
                },
                "Target-BnM-USA" : {
                  "text" : "Overall: Aggregated weekly store data rolled up to the UPC / Item",
                  "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\TGT_US_BNM\\OSA_UNIVERSE",
                  "reportHeight" : "2400px"
                },
                "Kroger-BnM-USA" : {
                  "text" : "Overall: Aggregated weekly store data rolled up to the UPC / Item",
                  "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\KRG_US_BNM\\OSA_UNIVERSE",
                  "reportHeight" : "2400px"
                }
            },
            "Crossmark" : {
              "Walmart-BnM-USA" :{
                "text" : "Item level alert history and details",
                "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\WMT_US_BNM\\CROSSMARK",
                "reportHeight" : "1200px"
              },
              "Target-BnM-USA" : {
                "text" : "Item level alert history and details",
                "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\TGT_US_BNM\\CROSSMARK",
                "reportHeight" : "1200px"
              },
              "Kroger-BnM-USA" : {
                "text" : "Item level alert history and details",
                "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\KRG_US_BNM\\CROSSMARK",
                "reportHeight" : "1210px"
              }
          },
          "PI: Inventory Gap" : {
            "Walmart-BnM-USA" :{
              "text" : "All item stores with Estimated PI Inventory Gap Alerts",
              "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\WMT_US_BNM\\PI_INV_GAP",
              "reportHeight" : "1700px"
            },
            "Target-BnM-USA" : {
              "text" : "All item stores with Estimated PI Inventory Gap Alerts",
              "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\TGT_US_BNM\\PI_INV_GAP",
              "reportHeight" : "1700px"
            },
            "Kroger-BnM-USA" : {
              "text" : "All item stores with Estimated PI Inventory Gap Alerts",
              "link" : "\\\\kcfiles\\share\\Corporate\\eCompass\\JARVIS2\\KRG_US_BNM\\PI_INV_GAP",
              "reportHeight" : "1700px"
            }
      }
          }, // An overall link added for DASH project under powerBi report
          sidebarOptions: [
              { icon: "fas fa-money-check-alt", title: "Sales Overview" },
              { icon: "far fa-chart-bar", title: "Recommendations" },
              { icon: "far fa-image", title: "Content" },
              { icon: "fas fa-layer-group", title: "PO Fill Rate" }
              ,],
          filterArr: [
            {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: "calendar_date",
                column: "date_range"
              },
              operator: "In",
              values: ["Last 1 week"]
            },
            {
              $schema: "http://powerbi.com/product/schema#basic",
              target: {
                table: "calendar_comparison",
                column: "compare_to"
              },
              operator: "In",
              values: ["Prior period"]
            }
          ],
          loggedInuserDetails: localStorage.getItem("okta-token-storage") ? JSON.parse(localStorage.getItem("okta-token-storage"))['idToken'] : ''
      };
        this.getFilteredResults = this.getFilteredResults.bind(this);
  }

  setStartDateAndCompareToPeriod = (selectedDateRange, compareToOption) => {
    let arrFromState = cloneDeep(this.state.filterArr);
    arrFromState[0].values = [selectedDateRange];
    arrFromState[1].values = [compareToOption];
    this.setState({
      filterArr: arrFromState, 
      selectedDateRange: selectedDateRange,
      compareToOption: compareToOption,
      randomKey: Math.random(),
    });  
  }

  componentDidMount() {
    document.title = `Insights`;
    localStorage.setItem("insight-page-name","")
    localStorage.setItem("previous-insight-page-data","")
    const queryParams = new URLSearchParams(window.location.search);
    const termId = queryParams.get("id");
    if(termId !== '' && termId !== null){
      this.setState({ displayLatestModal: true, showReleaseIdModal:termId});				
    }
    Promise.all([
      this.props.updateViewBuilderJSONAPI({}),
      this.props.getAllPagesForAdminAPI({  viewStatus: 'Active', categoryName: 'Insights' }),
      this.props.getSavedFiltersAPI()

    ]).then(data => {

      this.setState({
        sideBarItemCount:data[1].data.responseData.length,
      })
    }).catch(err => {
      this.setState({
        loading: true
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.selectedDateRange1 !== this.props.selectedDateRange1) {
      this.setState({selectedDateRange: this.props.selectedDateRange1});
      this.setState({compareToOption: this.props.compareToOption1});
    }
  }
  
   componentWillUnmount(){
     localStorage.setItem("insight-page-name","")
     let isFilterUpdated = false
     let globalFilters = getCookie("selected-global-filters")
      if(globalFilters) {
        Object.keys(globalFilters).forEach(filter => {
          if(globalFilters[filter] && globalFilters[filter].length) {
            isFilterUpdated = true
          }
        })
        if(!isFilterUpdated) {
          document.cookie = "selected-parent-filter= ; path=/; max-age=0;"
					document.cookie = "selected-filter-name= ; path=/; max-age=0;"
          document.cookie = "selected-filter-object= ; path=/; max-age=0;"
        }
      }
   }
  setSelectedTab = (tab, data) => {
    this.props.updateViewBuilderJSONAPI({});
    addCommonActivityCaptureDetail({page: `Insights- ${tab}`,globalFilters:this.props.selectedGlobalFilters ,strategyFilters: this.props.selectedStrategies })
    this.setState({
      selectedTab: tab, 
      loading: true
    })
    Promise.all([
      this.props.getSelectedViewAPI({ viewId: data.data.viewId }),
      this.props.getLastDataRefreshDateAPI({ projectName: "eCompass", retailerName: "Walmart", dashboardName: data.data.viewName })
    ]).then(data => {


    }).catch(err => {
      this.setState({
        loading: true
      })
    })

    }

    displayLatestModalOff = () => {		
      if(this.state.showReleaseIdModal !== ""){
        const { history } = this.props;
        history.push(this.props.location.pathname);
        this.setState({displayLatestModal: false,showReleaseIdModal:''});
      }   
    };

    handleBackButtonClick = ()=>{
      let localStoragePreviousPageData = JSON.parse(localStorage.getItem("previous-insight-page-data"))
      if(localStoragePreviousPageData && localStoragePreviousPageData.previousRandomKey !== this.state.randomKey){
        localStorage.setItem("insight-page-name",localStoragePreviousPageData.currentPageName)
        this.getFilteredResults(this.props.selectedGlobalFilters)
        window.scrollTo(0, 0)
          }
      }
    componentWillReceiveProps(nextprops) {
      document.title = nextprops.keyMapTabList ? nextprops.keyMapTabList.insightsTab : "";
        if (nextprops.viewBuilder !== this.state.viewBuilder) {
            this.setState({ viewBuilder: nextprops.viewBuilder }, () => {
                this.getFilteredResults(this.state.filters, this.props.selectedGlobalFilters);
            });
        }
        if(nextprops.selectedDateRange1 !== this.state.selectedDateRange) {
          this.setState({selectedDateRange: this.props.selectedDateRange1})
          this.setState({compareToOption: this.props.compareToOption1})
        }
    }
    getFilteredResults(...args) {

        const { viewBuilder } = this.props;
        let obj = args[1] ? args[1] :args[0];
        let newObj = {};
        let filterArr = [];
        let tablesForPbi = [];
    for (let key in obj) {
      let keyName = key;
      keyName = keyName.replace(/ /g, '_');
      keyName = keyName.toLocaleLowerCase();
      newObj[keyName] = obj[key];
    }
      
      
      if (viewBuilder && viewBuilder.hasOwnProperty("viewBuilderJSON")) {
          tablesForPbi = viewBuilder.viewBuilderJSON.tablesForPbi;
      }
        console.log("these are tables in pbi ", tablesForPbi);
    
      for (let key in newObj) {
        if (key === 'special_filter' && tablesForPbi.length > 0) {
          newObj[key].forEach(specialKey => {
            this.setInsightsFilter(tablesForPbi, key, specialKey, filterArr)
          })
        } else if (key !== 'special_filter' && tablesForPbi.length > 0) {
          this.setInsightsFilter(tablesForPbi, key, '', filterArr, newObj[key])
        }
      }
    let arrFromState = cloneDeep(this.state.filterArr);
    arrFromState.splice(2);
    arrFromState = arrFromState.concat(filterArr);
    this.setState({ filterArr: arrFromState, randomKey: Math.random(), loading:false});
  }

  setInsightsFilter(tablesForPbi, key, specialKey, filterArr, filterValue) {
    for (let i = 0; i < tablesForPbi.length; i++) {
      let obj = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: tablesForPbi[i], // table name will be "share_of_search","data","data_ogp"
          column: key === 'special_filter' ? specialKey : key //KC_PRIMARY_BRAND or "sector"
        },
        operator: "In",
        values: key === 'special_filter' ? ['Y'] : (key === 'rpln_flag' ? filterValue : filterValue)
      };
      filterArr.push(obj);
    }
  }

  renderSidebar() {
      let sidebarOptions = [];
      this.props.allPagesForAdmin.map((data, index) => {      
        if (index === 0 && this.state.selectedTab === '') {
          addCommonActivityCaptureDetail({page: `Insights- ${data.viewName}`,globalFilters:this.props.selectedGlobalFilters ,strategyFilters: this.props.selectedStrategies})
          this.props.getSelectedViewAPI({ viewId: data.viewId });
          this.setState({ selectedTab: data.viewName })
          this.props.getLastDataRefreshDateAPI({ projectName: "eCompass", retailerName: "Walmart", dashboardName: data.viewName });
        }
        sidebarOptions.push({ icon: "", title: data.viewName, data: data })
      });
     if(this.state.sideBarItemCount > 1){
      return (
        <Sidebar setSelectedTab={this.setSelectedTab} selectedTab={this.state.selectedTab} sidebarOptions={sidebarOptions} />
      )
     }
     
  }

  setCustomerKeyInPBIFilter() {
    let customerKeyFilter = [];
    let tablesForPbi = [];
    let {viewBuilder} = this.props;
    if (viewBuilder && viewBuilder.hasOwnProperty("viewBuilderJSON")) {
      tablesForPbi = viewBuilder.viewBuilderJSON.tablesForPbi;
    }
    let customizedColumns = ['customer_key']
    if (tablesForPbi.length > 0) {
      for (let columnIndex = 0; columnIndex < customizedColumns.length; columnIndex++) {
        for (let i = 0; i < tablesForPbi.length; i++) {
          let obj = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: tablesForPbi[i], // table name will be "share_of_search","data","data_ogp"
              column: customizedColumns[columnIndex]
            },
            operator: "In",
            values: [this.getColumnValues(customizedColumns[columnIndex])]
          };
          customerKeyFilter.push(obj);
        }
      }
      return customerKeyFilter;
    } else {
      return [];
    }
  }

  getColumnValues(columnName) {
    if (columnName === 'customer_key') {
      return this.props.selectedCustomerKey
    } else if (columnName === 'timeframe') {
      return this.getDateRangeValues().dateRangeValue
    } else if (columnName === 'comparison_id') {
      return this.getDateRangeValues().comparisonId
    } else {
      return []
    }
  }

  getDateRangeValues() {
    let dateRangeValue = this.props.dateFilterKeyValueOptions.filter(dateRange => dateRange.key == this.state.selectedDateRange)
    let comparisonId = this.props.comparisonIdValues.filter(compareId => compareId.key == this.state.compareToOption)
    return {dateRangeValue: dateRangeValue[0].value, comparisonId: comparisonId[0].value}
  }


  renderPowerBIDashboard(obj) {
    if (obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken === '') {
      this.props.getEmbedTokenAPI({
        groupId: process.env.REACT_APP_GROUP_ID,
        dashboardId: obj.reportId
      }, (res) => {
        if (res) {
          if (res.data.embedToken) {
            let tempViewBuilderObj = cloneDeep(this.props.viewBuilder);
            tempViewBuilderObj.viewBuilderJSON.htmlTemp[obj.id]['embedToken'] = res.data.embedToken;
            this.props.updateViewBuilderJSONAPI(tempViewBuilderObj)
          }
        }
      });
      return null;
    }
    else if (obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken !== '') {
      let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${obj.reportId}&groupId=${process.env.REACT_APP_GROUP_ID}`;
      embedUrl = embedUrl.replace("watch?v=", "v/");
      let customerKeyFilterArr =  this.props.selectedCustomerKey ? this.setCustomerKeyInPBIFilter() : []
      let arr = [], allFilters = [];
      let arrFromProps = cloneDeep(this.state.filterArr);
      for (let i = 0; i < arrFromProps.length; i++) {
        if (arrFromProps[i].values.length > 0 && arrFromProps[i].values[0] !== undefined) {
          if(arrFromProps[i] && arrFromProps[i].target && arrFromProps[i].target.column && arrFromProps[i].target.column === 'rpln_flag') {
            arrFromProps[i].target.column = 'RPLN_FLAG'
            arrFromProps[i].values.forEach((val, index) => {
              arrFromProps[i].values[index] = (val === 'Replenishable' || val === 'Y') ? 'Y' : 'N'
            })
          }
          arr.push(arrFromProps[i]);
        }
      }
      allFilters = arr
      arr = arr.concat(customerKeyFilterArr)
      if (arr && arr.length > 0) {
        arr[0].values = [this.state.selectedDateRange];
        arr[1].values = this.state.selectedDateRange == 'YTD' ? ['Prior year'] : this.state.compareToOption ? [this.state.compareToOption] : ['Prior period']
      }
      const layoutSettings = {
        customLayout: {
          pageSize: {
            type: models.PageSizeType.Custom,
            width: 1200,
            height: 5900
          },
          displayOption: models.DisplayOption.FitToWidth,
          pagesLayout: {
            ReportSection1: {
              defaultLayout: {
                displayState: {
                  mode: models.VisualContainerDisplayMode.Hidden,
                },
              },
              visualsLayout: {
                VisualContainer1: {
                  x: 1,
                  y: 1,
                  z: 1,
                  width: 900,
                  height: 300,
                  displayState: {
                    mode: models.VisualContainerDisplayMode.Visible,
                  },
                },
                VisualContainer2: {
                  displayState: {
                    mode: models.VisualContainerDisplayMode.Visible,
                  },
                },
              },
            },
          },
        },
      };
      const extraSettings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        hideErrors: false,
        ...layoutSettings, // layout config
      };
      const style = {
        height: "2000px",
        width: "100%",
        fontSize: "80%"
      }
      const overallLinkStyle = {
        height: this.state.overallLink[this.state.selectedTab] && this.state.overallLink[this.state.selectedTab][this.props.subproject] &&
        this.state.overallLink[this.state.selectedTab][this.props.subproject]["reportHeight"] ? this.state.overallLink[this.state.selectedTab][this.props.subproject]["reportHeight"] : "6000px",
        width: "100%",
        fontSize: "80%"
      }
      console.log("Calendar filter =====", arr);
      const onPageChange = (event) => {
        const data = event.detail;
        window.scrollTo(0, 0);
        if (data.newPage.displayName && data.newPage.displayName.includes('Insights')) {
            localStorage.setItem('main-page-name', data.newPage.displayName);
        } else {
            localStorage.setItem('last-visited-detail-page-name', data.newPage.displayName);
            window.dataLayer.push({
                'event': 'insightsclick',
                'category': DOMPurify.sanitize(`${this.props.project}_${this.props.subproject}`),
                'action': DOMPurify.sanitize(`${data.newPage.displayName}`),
                'label': 'Card_click',
                'startDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCurrent),
                'endDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCurrent),
                'compareStartDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCompare),
                'compareEndDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCompare),
                'filter': DOMPurify.sanitize(allFilters),
                'user id': DOMPurify.sanitize(this.state.loggedInuserDetails && this.state.loggedInuserDetails.claims && this.state.loggedInuserDetails.claims.sAMAccountName ? this.state.loggedInuserDetails.claims.sAMAccountName : '')
            });
        }
        let currentPageName = localStorage.getItem("insight-page-name");
        if (currentPageName !== "" && currentPageName !== data.newPage.name) {
            localStorage.setItem("previous-insight-page-data", JSON.stringify({ previousPageName: currentPageName, previousRandomKey: this.state.randomKey }));
        }
        localStorage.setItem("insight-page-name", data.newPage.name);
    };

    const onButtonClicked = (event) => {
        const data = event.detail;
        if (data.type === "Back") {
            this.handleBackButtonClick();
            window.dataLayer.push({
                'event': 'insightsclick',
                'category': DOMPurify.sanitize(`${this.props.project}_${this.props.subproject}`),
                'action': DOMPurify.sanitize(`${localStorage.getItem('last-visited-detail-page-name')}`),
                'label': 'Back',
                'startDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCurrent),
                'endDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCurrent),
                'compareStartDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCompare),
                'compareEndDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCompare),
                'filter': DOMPurify.sanitize(allFilters),
                'user id': DOMPurify.sanitize(this.state.loggedInuserDetails && this.state.loggedInuserDetails.claims && this.state.loggedInuserDetails.claims.sAMAccountName ? this.state.loggedInuserDetails.claims.sAMAccountName : '')
            });
        }
        if (data.title && data.title.includes('Crisp')) {
            window.dataLayer.push({
                'event': 'insightsclick',
                'category': DOMPurify.sanitize(`${this.props.project}_${this.props.subproject}`),
                'action': DOMPurify.sanitize(`${data.title.replace('Crisp', '').trim()}`),
                'label': 'Crisp',
                'startDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCurrent),
                'endDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCurrent),
                'compareStartDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCompare),
                'compareEndDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCompare),
                'filter': DOMPurify.sanitize(allFilters),
                'user id': DOMPurify.sanitize(this.state.loggedInuserDetails && this.state.loggedInuserDetails.claims && this.state.loggedInuserDetails.claims.sAMAccountName ? this.state.loggedInuserDetails.claims.sAMAccountName : '')
            });
        }
    };
      return (
        <div className="col-12 p-0 m-0" key={this.state.randomKey} id={obj.id}>
          {/* <Report
            embedType="report" // "dashboard"
            tokenType="Embed" // "Aad"
            accessToken={obj.embedToken} // accessToken goes here
            embedUrl={embedUrl} // embedUrl goes here
            embedId={obj.reportId}
            permissions="All"
            style={
              this.state.overallLink.hasOwnProperty(this.state.selectedTab) ? overallLinkStyle :style
            }   
            pageName={localStorage.getItem("insight-page-name") ?localStorage.getItem("insight-page-name") : "" }       
            onPageChange={(data) => {
              window.scrollTo(0, 0)
              if(data.newPage.displayName && data.newPage.displayName.includes('Insights')) {
                localStorage.setItem('main-page-name', data.newPage.displayName)
              }else{
                localStorage.setItem('last-visited-detail-page-name', data.newPage.displayName)
                window.dataLayer.push({
                  'event': 'insightsclick',
                  'category': DOMPurify.sanitize(`${this.props.project}_${this.props.subproject}`),
                  'action': DOMPurify.sanitize(`${data.newPage.displayName}`),
                  'label': 'Card_click',
                  'startDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCurrent),
                  'endDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCurrent),
                  'compareStartDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCompare),
                  'compareEndDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCompare),
                  'filter': DOMPurify.sanitize(allFilters),
                  'user id': DOMPurify.sanitize(this.state.loggedInuserDetails && this.state.loggedInuserDetails.claims && this.state.loggedInuserDetails.claims.sAMAccountName ? this.state.loggedInuserDetails.claims.sAMAccountName : '')
                })
              }
              let currentPageName = localStorage.getItem("insight-page-name")
              if(currentPageName !== "" && currentPageName !== data.newPage.name){
                localStorage.setItem("previous-insight-page-data",JSON.stringify({previousPageName: currentPageName, previousRandomKey: this.state.randomKey }))
              }
            localStorage.setItem("insight-page-name",data.newPage.name)            
            }              
            }
            onRender={(data)=>{
           }} 
           onButtonClicked={(data) => {
            if(data.type === "Back"){
             this.handleBackButtonClick()
              window.dataLayer.push({
                'event': 'insightsclick',
                'category': DOMPurify.sanitize(`${this.props.project}_${this.props.subproject}`),
                'action': DOMPurify.sanitize(`${localStorage.getItem('last-visited-detail-page-name')}`),
                'label': 'Back',
                'startDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCurrent),
                'endDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCurrent),
                'compareStartDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCompare),
                'compareEndDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCompare),
                'filter': DOMPurify.sanitize(allFilters),
                'user id': DOMPurify.sanitize(this.state.loggedInuserDetails && this.state.loggedInuserDetails.claims && this.state.loggedInuserDetails.claims.sAMAccountName ? this.state.loggedInuserDetails.claims.sAMAccountName : '')
              })
            }
             if(data.title && data.title.includes('Crisp')) {
                window.dataLayer.push({
                  'event': 'insightsclick',
                  'category': DOMPurify.sanitize(`${this.props.project}_${this.props.subproject}`),
                  'action': DOMPurify.sanitize(`${data.title.replace('Crisp', '').trim()}`),
                  'label': 'Crisp',
                  'startDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCurrent),
                  'endDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCurrent),
                  'compareStartDate': DOMPurify.sanitize(this.props.insightsDateValues.startDateCompare),
                  'compareEndDate': DOMPurify.sanitize(this.props.insightsDateValues.endDateCompare),
                  'filter': DOMPurify.sanitize(allFilters),
                  'user id': DOMPurify.sanitize(this.state.loggedInuserDetails && this.state.loggedInuserDetails.claims && this.state.loggedInuserDetails.claims.sAMAccountName ? this.state.loggedInuserDetails.claims.sAMAccountName : '')   
                })
             }
            }            
          }
            extraSettings={extraSettings}
            onLoad={(report) => {
             this.report = report;
              this.report.setFilters(arr).then((res, rej) => {
              }).catch(function (errors) {
              });
            }
            }
          /> */}
          <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // 'report' or 'dashboard'
                    id: obj.reportId,
                    embedUrl: embedUrl,
                    accessToken: obj.embedToken,
                    tokenType: models.TokenType.Embed,
                    settings: extraSettings
                }}
                eventHandlers={
                    new Map([
                        ['loaded', (event) => {
                            const report = event.target;
                            if (report && typeof report.setFilters === 'function') {
                                report.setFilters(arr).catch(function (errors) {
                                    console.error(errors);
                                });
                            } else {
                                console.error('Report object does not support setFilters');
                            }
                        }],
                        ['rendered', () => {
                            console.log('Report rendered');
                        }],
                        ['pageChanged', this.onPageChange], // Assuming the event is 'pageChanged'
                        ['buttonClicked', this.onButtonClicked] // Assuming the event is 'buttonClicked'
                    ])
                }
                cssClassName="report-style-class-dash"
            />
        </div>
      )
    }

  }

  renderHtmlContent(obj) {
    if (obj.innerhtml !== '' && obj.type !== 'dashboard') {
       return (<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(obj.innerhtml)}} />)
    }

    else if (obj.innerhtml !== '' && obj.type === 'dashboard' && obj.reportId !== "" && obj.embedToken === '') {
       return (<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(obj.innerhtml)}} />)
    }
    else {

    }

  }

  renderChildrenOfId(id) {
    if (Object.keys(this.props.viewBuilder).length > 0) {
      if (this.props.viewBuilder.hasOwnProperty("viewBuilderJSON") && this.props.viewBuilder.viewBuilderJSON.hasOwnProperty("htmlTemp")) {
        let newJson = this.props.viewBuilder.viewBuilderJSON.htmlTemp
        if (Object.keys(newJson).length > 0) {
          let obj = newJson[id];

          const HtmlTag = `${obj.element}`;
          return (
            <HtmlTag className={obj.classDefault + ' ' + obj.classMobile + ' ' + obj.classTablet + ' ' + obj.classDesktop}
              id={obj.id} type={obj.type}
              style={obj.configOptions.style}
            >
              {this.renderHtmlContent(obj)}
              {this.renderPowerBIDashboard(obj)}
              {obj.children.length > 0 ? obj.children.map((id, index) => {
                return (
                  <React.Fragment key={index}>
                    {this.renderChildrenOfId(id)}
                  </React.Fragment>
                )
              }) : null}
            </HtmlTag>
          )
        }
      }
    }else  if(!this.state.loading){
     return (
        <div className="ml-4">
          No data available 
        </div>
      )
    }
  }

  renderOverallLink(link,text){
    return (
      <Card style={{fontSize:'12.5px', width:'100%'}}>
        <Card.Body>
          <Card.Text>{text}</Card.Text>
          <Card.Text>{link}</Card.Text>
        </Card.Body>
      </Card>
    )
  }

  renderLinkForDocumentation(linkForDocumentation) {
    if (linkForDocumentation) {
      if (linkForDocumentation.indexOf("http") === -1) {
        linkForDocumentation = "https://" + linkForDocumentation;
      }
    }
    if (linkForDocumentation) {
      return (
        <div className="col-12 pr-5" style={{ textAlign: "right" }}  >
          <span style={{ cursor: "pointer" }} title={linkForDocumentation} onClick={(e) => {
            window.open(linkForDocumentation);
          }}>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M5.25 6.033h1.32c0-.781.458-1.384 1.36-1.384.685 0 1.313.343 1.313 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.007.463h1.307v-.355c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.326 0-2.786.647-2.754 2.533zm1.562 5.516c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
          </span>
        </div>
      )
    } else {
      return null;
    }
  }

  render() {
    let datePicker = false;
    let linkForDocumentation = null;
    if (this.props.viewBuilder.hasOwnProperty("viewBuilderJSON")) {
      datePicker = this.props.viewBuilder.viewBuilderJSON.datePicker;
      linkForDocumentation = this.props.viewBuilder.viewBuilderJSON.linkForDocumentation;
    } 

    return (
      <div>
        {this.state.displayLatestModal ? <LatestModal displayLatestModalOff={this.displayLatestModalOff} releaseByid={this.state.showReleaseIdModal}/> : null}
        {this.renderSidebar()}
        <div className= {`container-fluid  default-top ${this.state.sideBarItemCount>1 && "padd-left"} bg-gray`}>
         
          {this.state.loading ? <div className=" loading_wrapper">
            <div className="sweet-loading">
              <BarLoader
                size={150}
                color={"#123abc"}
                height={4}
                width={100}
                loading={this.state.loading}
              />
            </div>

          </div> : <>
          <Filters  title={this.state.selectedTab} 
            getFilteredResults={this.getFilteredResults}
            displayDateFilter={datePicker}
            setStartDateAndCompareToPeriod={this.setStartDateAndCompareToPeriod}
            lastRefreshedDate={this.props.lastRefreshedDate}
            ytdStartDate={this.props.ytdStartDate}
            selectedDateRange={this.state.selectedDateRange}
            compareToOption={this.state.compareToOption}
            defaultDateRange={this.props.selectedDateRange1}
            transMaxDate={this.props.transMaxDate}
          />
              <div className="container-fluid  px-0  height_max bg-gray">
                <div className="col-12 sections height_100 p-0 m-0" id="builder_screen_header">
                  <div className="col-12 px-0 builder_window height_100 bg-gray" >
                    <div className="sections p-0 m-0" id="preview_screen" style={{ 'backgroundImage': 'none', height: "auto" }}>

                      {this.renderLinkForDocumentation(linkForDocumentation)}

                      {this.renderChildrenOfId(0)}
                      {this.props.project === "Dash" && this.state.overallLink.hasOwnProperty(this.state.selectedTab) && this.state.overallLink[this.state.selectedTab][this.props.subproject] && this.state.overallLink[this.state.selectedTab][this.props.subproject]["link"] ? this.renderOverallLink(this.state.overallLink[this.state.selectedTab][this.props.subproject]["link"],this.state.overallLink[this.state.selectedTab][this.props.subproject]["text"]) : <></>}
                    </div>
                  </div>
                </div>
              </div>


            </>}
        </div>
      </div>);
  }
}

function mapStateToProps(state) {
  
  let tempNewDate = new Date();
  tempNewDate = getFormattedDate(tempNewDate);
  let lastRefreshedDate = _get(state, 'globalReducer.getLastDataRefreshDate.responseData.lastDataRefreshDate', tempNewDate);
  let transMaxDate = _get(state, 'globalReducer.getLastDataRefreshDate.responseData.transMaxDate', tempNewDate);
  let ytdStartDate = _get(state, 'globalReducer.getLastDataRefreshDate.responseData.ytdStartDate', '');
  let allPagesForAdmin = _get(state, 'adminReducer.allAdminViews.responseData', []);
  let viewBuilder = _get(state, 'viewBuilderObj', { enableDatepicker: false });
  let {keyMapTabList} = _get(state, 'globalReducer', "");
  let selectedGlobalFilters = _get(state, 'globalReducer.selectedGlobalFilters', {});
  let selectedGlobalFilterValues = _get(state, 'globalReducer.selectedGlobalFilterValues', {});
  let selectedCustomerKey = _get(state, 'globalReducer.selectedCustomerKey', "");
  let dateFilterKeyValueOptions = _get(state, "globalReducer.dateFilterKeyValueOptions", { key: '', value: '' })
  let comparisonIdValues = _get(state, "globalReducer.comparisonIdValues", { key: '', value: '' })
  let project = _get(state, 'globalReducer.project', "");
	let subproject = _get(state, 'globalReducer.subproject', "");
  let insightsDateValues = _get(state, 'globalReducer.insightsDateValues', {startDateCurrent: new Date(), endDateCurrent: new Date(), 
    startDateCompare: new Date(), endDateCompare: new Date()});
  let selectedStrategies = _get(
    state,
    "strategyReducer.selectedStrategies",
    []
  );
  let selectedDateRange1 = _get(state, 'newRecommendationTableData.dateFilterAndRange', '')['dateRange'];
  let compareToOption1 = _get(state, 'newRecommendationTableData.dateFilterAndRange', '')['compareTo'];
  if(!selectedDateRange1){
    selectedDateRange1 =  _get(state, 'adminReducer.selectedDateRange', 'Last 1 week');
  }
  if(!compareToOption1){
    compareToOption1 = 'Prior period';
  }

  return ({
    allPagesForAdmin: allPagesForAdmin,
    viewBuilder: viewBuilder,
    lastRefreshedDate,
    ytdStartDate,
    keyMapTabList:keyMapTabList,
    selectedGlobalFilters,
    selectedGlobalFilterValues,
    selectedStrategies,
    selectedDateRange1,
    selectedCustomerKey,
    compareToOption1,
    dateFilterKeyValueOptions,
    comparisonIdValues,
    project,
    subproject,
    insightsDateValues,
    transMaxDate
  })
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllPagesForAdminAPI: getAllPagesForAdmin,
    getSelectedViewAPI: getSelectedView,
    getEmbedTokenAPI: getEmbedToken,
    updateViewBuilderJSONAPI: updateViewBuilderJSON,
    getLastDataRefreshDateAPI: getLastDataRefreshDate,
    getSavedFiltersAPI: getSavedFilters,

  }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Insights));

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAlertLogData } from "../actions/index";
import { PriceFormatter } from "../helper";
import { Modal, Pagination, Form } from "react-bootstrap";

function AlertLogModal(props) {
  const [alertLogData, setAlertLogData] = useState([]);
  const [preModalBodyText, setPreModalBodyText] = useState("Loading...");
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState();
  let { selectedRetailerCurrency } = useSelector((state) => state.globalReducer);
  let { selectedStrategies } = useSelector((state) => state.strategyReducer)

  let { actionedAndActiveData } = useSelector(
    (state) => state.recommendationsReducer
  );
  let productDetails = props.alertLogModalDetails;

  useEffect(() => {
    setLoading(true);
    setPreModalBodyText("Loading...");
    let alertType = "";
    if (selectedStrategies?.length) {
      if (selectedStrategies && selectedStrategies?.length === 1) {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) =>
          selectedStrategies.find(
            (strName) => actAndActv.RECOMMENDATION_NAME === strName
          )
        );
        alertType = filterArr[0]["DISPLAY_TYPE"];
      } else {
        let filterArr = actionedAndActiveData?.responseData.filter((actAndActv) =>
          selectedStrategies.find(
            (strName) => actAndActv.RECOMMENDATION_NAME === strName
          )
        );
        alertType = filterArr[0]["DEFAULT_TYPE"];
      }
    }

    getAlertLogData(
      {
        keyword: productDetails.KEYWORD,
        rankType: productDetails.RANK_TYPE,
        pageNo: pageNumber,
        totalRows: sizePerPage
      },
      (response) => {
        if (
          response.data &&
          response.data.responseType &&
          response.data.responseType.toLowerCase() === "success"
        ) {
          if(response.data.responseData.sosAlertLogData.length <= 0) {
            setLoading(true);
            setPreModalBodyText("No Data Available");
          } else {
            setAlertLogData(response.data.responseData.sosAlertLogData) 
            setTotalSize(response.data.responseData.totalRows)
            setLoading(false);
          }
        } else {
          setLoading(true);
          setPreModalBodyText("No Data Available");
        }
      }
    );
  }, [sizePerPage, pageNumber]);

  const handlePageChange = (newPageNumber) => {
    if (
      newPageNumber > 0 &&
      newPageNumber <= Math.ceil(totalSize / sizePerPage)
    ) {
      setPageNumber(newPageNumber);
    }
  };

  const handleSizePerPageChange = (event) => {
    setSizePerPage(Number(event.target.value));
    setPageNumber(1); // Reset to first page
  };

  const renderTableBody = () => {
    return alertLogData.map((row, index) => (
      <tr key={index}>
        {props.isProductLevel && <td>{row.location}</td>}
        <td>{row.alertName}</td>
        <td>{row.alertStatus}</td>
        <td>{row.statusDate}</td>
        <td>{row.alertTriggerDate}</td>
        <td>
          {PriceFormatter(row.alertEstimatedValue, {
            precision: 0,
            symbol: selectedRetailerCurrency,
          })}
        </td>
        <td>{row.actionedBy}</td>
        <td>{row.snoozeDays}</td>
        <td>{row.reason}</td>
      </tr>
    ));
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalSize / sizePerPage);

    return (
      <nav aria-label="Page navigation">
        <Pagination className="d-flex justify-content-end mb-4">
          <Pagination.Prev
            onClick={() => handlePageChange(pageNumber - 1)}
            disabled={pageNumber === 1}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === pageNumber}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(pageNumber + 1)}
            disabled={pageNumber === totalPages}
          />
        </Pagination>
      </nav>
    );
  };

  return (
    <Modal
      show={props.displayAlertLogModal}
      onHide={props.displayAlertLogModalOff}
      id="alert-log-modal"
    >
      <Modal.Header closeButton>
        <div class="d-flex">         

          <div className="ml-5">
            <div>
              <span style={{ fontWeight: 600 }}>Keyword : </span>
              <span>{productDetails.KEYWORD}</span>
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>Rank Type : </span>
              <span>{productDetails.RANK_TYPE}</span>
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>Alert : </span>
              <span>{selectedStrategies.toString()}</span>
            </div>
           
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-gray py-4">
        {loading ? (
          <div style={{ height: "400px", textAlign: "center" }}>
            {preModalBodyText}
          </div>
        ) : (
          <>
          <div className={`${alertLogData?.length > 8 ? 'fix-ht' : ''}`}>
            <table className='table alert-logmodal'>
              <thead className="mdl_header_fixed">
                <tr>
                  {props.isProductLevel && <th>Location</th>}
                  <th>Alert Name</th>
                  <th>Alert Status</th>
                  <th>Status Date</th>
                  <th>Alert Trigger Date</th>
                  <th>Focused Estimated Value</th>
                  <th>Actioned By</th>
                  <th>Snooze Days</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>{renderTableBody()}</tbody>
            </table>
          </div>
            <div className="d-flex justify-content-between align-items-center mt-0">
              <Form.Group controlId="sizePerPageSelect" >
                
                <Form.Control
                  as="select"
                  value={sizePerPage}
                  onChange={handleSizePerPageChange}
                  id='pageDropDown'
                  className="pageDropdownoption"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                </Form.Control>
                <Form.Label> Showing rows {((pageNumber - 1) * sizePerPage) + 1} to {Math.min(pageNumber * sizePerPage, totalSize)} of {totalSize}</Form.Label>
              </Form.Group>
              {renderPagination()}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default AlertLogModal;

import React, { useState } from "react";
import {  useSelector } from "react-redux";
import { getCookie } from "../helper";


import Modal from "react-bootstrap/Modal";

function TakeActionModal(props) {
  const [showComments, setShowComments] = useState(false);
  const [otherComments, setOtherComments] = useState("");
  const [enableActionButton, setEnableActionButton] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [scheduleAuto, setScheduleAuto] = useState("manual");
  const [modeOfAction, setModeOfAction] = useState(false);

  let { alertReasonAndSnooze, selectedRows, selectedSkuIds } = useSelector(
    (state) => state.newRecommendationTableData
  );
  let { selectedStrategies} = useSelector(
    (state) => state.strategyReducer
  );
  selectedSkuIds = getCookie("selected-sku-id") ? getCookie("selected-sku-id") : [];
  let sosselectedKeyword = selectedSkuIds.length > 1 ? 'keywords' : 'keyword';

  let handleActionButton = () => {
    props.displayActionModalOff();
    selectedRows = []
    document.cookie = "selected-sku-id=; path=/; max-age=0; isSecure;"
    if (props.dcLocation) {
      localStorage.setItem('triggerlocation', JSON.stringify({dclocationCount:props.dclocationCount,dclocationName:props.dclocationName,dcproductId:props?.dcproductId,selectedStrategyName:props.selectedStrategyName}));
      props.handleDCLocationActionButtonClick({
        reason: selectedReason.reason.includes("Free text by User")
          ? otherComments
          : selectedReason.reason,
        snoozeTime: selectedReason.snoozeTime,
        scheduleAuto: scheduleAuto,
      });
    } else  {
      props.handleActionButtonClick({
        reason: selectedReason.reason.includes("Free text by User")
          ? otherComments
          : selectedReason.reason,
        snoozeTime: selectedReason.snoozeTime,
        scheduleAuto: scheduleAuto,
      });
    }
    
  };

  

  let handleRadioButtonClick = (row) => {
    if (row.reason.includes("Free text by User")) {
      setShowComments(true);
      setEnableActionButton(false);
    } else {
      setEnableActionButton(true);
      setShowComments(false);
      setOtherComments(null);
    }
    setSelectedReason(row);
  };
 function handleSchduleChange(e) {
    setModeOfAction(!modeOfAction);
    if (e) {
      setScheduleAuto("auto");
    }else {
      setScheduleAuto("manual");
      setModeOfAction(!modeOfAction);
    }
  }
  function renderBody() {
    return (
      <div id="action-wrapper">
        <div
          style={{ marginLeft: "28px", color: "#0f059e", fontWeight: 600 }}
          class="flex"
        >
          Reason{" "}
          <span class="ml-auto" style={{ marginRight: "6px" }}>
            Snooze Days<span></span>
          </span>
        </div>
        <div class=" form-group">
          {alertReasonAndSnooze.map((row) => {
            return (
              <div
                class={`form-check ${
                  selectedReason &&
                  selectedReason.reason === row.reason &&
                  selectedReason.reason !== "Other [Free text by User]" &&
                  "selected-reason"
                }`}
                style={{ paddingLeft: "20px" }}
              >
                <div class="flex">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="action-reason"
                    onChange={(e) => {
                      handleRadioButtonClick(row);
                    }}
                  />
                  <label title="" for="4" class="form-check-label col-9">
                    {row.reason.includes("Free text by User")
                      ? "Other"
                      : row.reason}
                  </label>
                  <span
                    class="ml-auto"
                    style={{ marginRight: "6px", color: "#3152e2" }}
                  >
                    {row.snoozeTime} Days
                  </span>
                </div>
              </div>
            );
          })}
          {showComments && (
            <textarea
              placeholder="Please enter reason"
              id="action-textarea"
              onChange={(e) => {
                if (e.target.value === "") setEnableActionButton(false);
                else setEnableActionButton(true);
                setOtherComments("Other-" + e.target.value);
              }}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <Modal
      show={props.displayActionModal}
      onHide={props.displayActionModalOff}
      id="take-action-modal"
      
    >
      <Modal.Header closeButton>
        <Modal.Title>Take Action</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-gray py-4">
        {alertReasonAndSnooze && alertReasonAndSnooze.length <= 0 ? (
          <div style={{ height: "400px", textAlign: "center" }}>
            No Data Available
          </div>
        ) : (
          renderBody()
        )}
      </Modal.Body>
      {alertReasonAndSnooze && alertReasonAndSnooze.length >= 0 && (
        <Modal.Footer>
          <div className="form-check" style={{ right: "28px",position:"relative",width:"330px" }} >
            <input type="checkbox" disabled={
              props.dcLocation ? !props.isLocationsSelected() : Object.keys(selectedRows).length > 0 && !selectedSkuIds.length} 
              checked={modeOfAction} name="scheduleauto" class="form-check-input" onChange={(e) => {
                                handleSchduleChange(true)
                            }} />
            <label title="" for="4" class="form-check-label" style={{position:"relative",bottom:"3px",paddingLeft:"15px"}}>
            Don't trigger on further locations till the snooze period is over.
            </label>
          </div>
          <span style={{ paddingRight: 3 }}>
            {selectedStrategies?.length && selectedStrategies[0] === 'Share of Search' ? <>{selectedSkuIds?.length} {sosselectedKeyword} </> : 
            <>
            ({props.dcLocation ? 1 : Object.keys(selectedRows).length + selectedSkuIds.length} product
            {(props.dcLocation ? '' : Object.keys(selectedRows).length + selectedSkuIds.length) > 1 && "s"})
            </>}
          </span>
          <button
            className={`btn ${
              enableActionButton ? "btn-apply" : "btn-none"
            } btn-apply  my-2`}
            onClick={handleActionButton}
            style={{ marginRight: "4px", lineHeight: "16px" }}
          >
            Move To Actioned
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default TakeActionModal;

import React, { useState, useEffect } from 'react';
import './Selfservice.scss';
import { Table, Pagination, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { createNewSelfservice, displayAllSelfService, updateSelfserviceById, deleteSelfserviceById } from '../../actions';
import moment from "moment";

const AddUpdateModal = (props) => {
    let dispatch = useDispatch();
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    const { propName, propLink, propDescription, propStatus, propsId, propsCreatedOn, propsCreatedBy, propsCreatedByEmail, propsProjectId,
        propsSubprojectId } = props.modalDetails;
    const [addStrategyError, setStrategyError] = useState("");
    const [name, setName] = useState(propName);
    const [description, setDescription] = useState(propDescription);
    const [link, setLink] = useState(propLink);
    const [status, setStatus] = useState(propStatus);
    function releaseDateFormat(date) {
        return moment(date).utc().format("YYYY-MM-DD");
    };
    const saveUpdate = (e, modalMode) => {
        e.preventDefault();
        if (name === "" || description === "" || link === "" || status === "") {
            setStrategyError("Please fill all values");
        } else {
            props.displayModalOff();
            if (modalMode === "Add") {
                dispatch(createNewSelfservice({ datasetName: name, description: description, link: link, status: status, createdByEmail: userInfo?.email, createdByName: userInfo?.name }, (res, rej) => {
                    if (res) {
                        toast.success(res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                        dispatch(displayAllSelfService({
                            pageNo: 1, sortColumn: "id",
                            sortOrder: "asc"
                        }, (res) => { }));
                    }
                }));
            } else {
                dispatch(updateSelfserviceById({
                    selfServiceData: {
                        datasetName: name, description: description, link: link, status: status, createdByEmail: propsCreatedByEmail, createdByName: propsCreatedBy, createdOn: propsCreatedOn, projectId: propsProjectId,
                        SubprojectId: propsSubprojectId, updatedBy: userInfo?.name, updatedByEmail: userInfo?.email, updatedOn: releaseDateFormat(new Date())
                    }, selfServiceId: propsId
                }, (res, rej) => {
                    if (res) {
                        toast.success(res?.data?.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                        dispatch(displayAllSelfService({
                            pageNo: 1, sortColumn: "id",
                            sortOrder: "asc"
                        }, (res) => { }));
                    }
                }));
            }
        }
    }

    let modalText = "Manage";
    if (props.modalMode === "Add") {
        modalText = "New";
    }
    return (
        <Modal size={'md'} show={true} onHide={props.displayModalOff}>
            <Modal.Header closeButton>
                <Modal.Title><i className="far fa-file-alt mr-3"></i> Self-Service</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">
                <div className="container ">
                    <div className="row recommendations-table">
                        <div className="col-12 my-2">
                            <form >
                                <div className="form-group">
                                    <label> Name</label>
                                    <input type="text" placeholder='Name' value={name} className="form-control" onChange={(e) => { setName(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Description</label>
                                    <input type="text" placeholder='Description' value={description} className="form-control" onChange={(e) => { setDescription(e.target.value); setStrategyError("") }} />
                                </div>

                                <div className="form-group">
                                    <label> Link</label>
                                    <input type="text" placeholder='Link' value={link} className="form-control" onChange={(e) => { setLink(e.target.value); setStrategyError("") }} />
                                </div>
                                <div className="form-group">
                                    <label> Status</label>
                                    <select className="form-control" value={status} onChange={(e) => { setStatus(e.target.value); setStrategyError("") }}>
                                        <option value="" disabled>Please select a status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <p className="error">{addStrategyError}</p>
                                <button class="btn btn-apply" onClick={(e) => { saveUpdate(e, props.modalMode) }}>Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const Selfservice = () => {
    let dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [modalMode, setModalMode] = useState("Add");
    const [allreasesData, setAllreasesData] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [modalDetails, setModalDetails] = useState({
        propName: "",
        propDescription: "",
        propStatus: "",
        propLink: "",
        propStrategyId: ""
    });
    const [pageNumber, setPageNumber] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; // Number of rows per page
    const [totalSize, setTotalSize] = useState();
    const options = {
        totalSize: totalSize,
        sizePerPage: sizePerPage,
        page: pageNumber,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
    };
    const handleTableChange = (
        type,
        { page, sizePerPage }
    ) => {
        if (type === "pagination") {
            setSizePerPage(sizePerPage);
            setPageNumber(page);
        }
    };
    let { allSelfservicedata } = useSelector((state) => state.versionReducer);
    let { isAppAdmin, isProjectAdmin, isSubprojectAdmin } = useSelector((state) => state.globalReducer);
    let hasAdminAccess = isAppAdmin || isProjectAdmin || isSubprojectAdmin ? true : false;
    const [titleSortDirection, setTitleSortDirection] = useState();
    const [descriptionSort, setDescriptionSort] = useState();
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [sortConfigloser, setSortConfigloser] = useState({ key: '', direction: '' });
    const onSort = (column) => {
        let direction = 'asc';
        if (sortConfig.key === column && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        if (allSelfservicedata?.responseData?.releaseList?.length > 0) {
            dispatch(displayAllSelfService({
                pageNo: currentPage,
                totalRows: sizePerPage, sortOrder: direction,
                sortColumn: column,
            }, responseHandlerupdate));
        }
        setSortConfig({ key: column, direction });
    };
    const renderSortCaret = (column) => {
        if (sortConfig.key !== column) {
            return <i className="fas fa-sort default-sort-icon"></i>;
        }
        if (sortConfig.direction === 'asc') {
            return <i className="fas fa-sort-up default-sort-icon active"></i>;
        }
        return <i className="fas fa-sort-down default-sort-icon active"></i>;
    };
    const sortCareticon = (order, column) => {
        if (column.dataField == 'Dataset_Name') {
            order === 'desc' ? setTitleSortDirection('asc') : setTitleSortDirection('desc');
        } else if (column.dataField == 'Description') {
            order === 'desc' ? setDescriptionSort('asc') : setDescriptionSort('desc');
        }
        if (order === 'asc') {
            return (<i className="fas fa-sort-up  default-sort-icon active"></i>)
        } else if (order === 'desc') {
            return (<i className="fas fa-sort-down  default-sort-icon active"></i>)
        } else {
            return (<i className="fas fa-sort default-sort-icon"></i>)
        }
    }
    const sortingEvents = {
        onClick: (e, column, columnIndex) => {
            let colSort = column.dataField == 'Dataset_Name' ? titleSortDirection : column.dataField == 'Description' ? descriptionSort : "";
            if (allSelfservicedata?.responseData?.releaseList?.length > 0) {
                dispatch(displayAllSelfService({
                    pageNo: pageNumber,
                    totalRows: sizePerPage, sortOrder: colSort,
                    sortColumn: column.dataField,
                }, responseHandlerupdate));
            }
        }
    }
    const displayModalOff = () => {
        setDisplayModal(false);
    }
    const handleAddUpdates = (e) => {
        setDisplayModal(true);
        setModalMode("Add");
        let obj = {
            propName: "",
            propDescription: "",
            propLink: "",
            propStatus: ""
        }
        setModalDetails(obj);
    }

    const responseHandlerupdate = (response) => {
        if (response?.data?.responseData) {
            if (response.data.responseData.length <= 0) {
                setLoading(false)
            } else {
                if (response?.data?.responseData?.releaseList) {
                    setAllreasesData(response.data.responseData.releaseList)
                    setTotalSize(response.data.responseData.totalRows)
                    setLoading(false);
                }
            }
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (allSelfservicedata?.responseData?.releaseList) {
            setAllreasesData(allSelfservicedata.responseData.releaseList)
            setTotalSize(allSelfservicedata.responseData.totalRows)
            setLoading(false);
        }
    }, [allSelfservicedata]);

    useEffect(() => {
        setLoading(true)
        dispatch(displayAllSelfService({
            pageNo: currentPage,
            totalRows: sizePerPage, sortColumn: "id",
            sortOrder: "asc"
        }, responseHandlerupdate));
    }, [currentPage]);

    const NoDataIndication = () => (
        <div className="spinner text-center p-2">
            No Data Available
        </div>
    );

    const handleEditRelease = (e, row) => {
        setDisplayModal(true);
        setModalMode("Edit");
        let obj = {
            propName: row.Dataset_Name,
            propDescription: row.Description,
            propLink: row.Link,
            propStatus: row.Status,
            propsId: row.id,
            propsCreatedByEmail: row.CreatedByEmail,
            propsCreatedBy: row.CreatedBy,
            propsCreatedOn: row.CreatedOn,
            propsProjectId: row.ProjectId,
            propsSubprojectId: row.SubprojectId,
            propsUpdatedBy: row.UpdatedBy,
            propsUpdatedByEmail: row.UpdatedByEmail,
            propsUpdatedOn: row.UpdatedOn
        }
        setModalDetails(obj);
    }

    const handleDelete = (e, row) => {
        Swal.fire({
            title: 'Are you sure you want to delete this Self-Service ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.value) {
                dispatch(deleteSelfserviceById({ selfServiceId: row.id }, (res, rej) => {
                    if (res) {
                        dispatch(displayAllSelfService({
                            pageNo: 1, sortColumn: "id",
                            sortOrder: "asc"
                        }, (res) => { }));
                        toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                    }
                }));
            }
        })
    }

    const editColumnFormatter = (cell, row) => {
        return (
            <div className="flex editColumn">
                <span onClick={(e) => { handleEditRelease(e, row) }}><i className="fas fa-edit mr-3" ></i></span>
                <span onClick={(e) => { handleDelete(e, row) }}><i className="fas fa-trash"></i></span>
            </div>
        )
    }

    const statusFormatter = (cell, row) => {
        return (
            <div style={{ width: "8rem" }}>
                <i class={`fas ${row.Status === "Active" ? 'green' : 'red'} fa-dot-circle mr-2`}></i>
                {row.Status}
            </div>
        )
    }

    const datasetFormatter = (cell, row) => {
        return (
            <div>
                {row.Status === "Active" ?
                    <a href={row.Link} target="_blank">{row.Dataset_Name}</a>
                    : <>{row.Dataset_Name}</>
                }
            </div>
        )
    }


    const totalPages = Math.ceil(allreasesData.length / pageSize);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const getPaginationItems = () => {
        const items = [];
        const visiblePages = 3; // Number of pages to display at once

        const startPage = Math.max(1, currentPage - visiblePages);
        const endPage = Math.min(totalPages, currentPage + visiblePages);

        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            items.push(
                <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return items;
    };

    const paginatedData = allreasesData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <div>
            {loading ? (
                <div id="no-data-available" style={{ marginTop: "5%" }}> Loading....</div>) :
                (<div>
                    <ToastContainer />
                    {displayModal ?
                        <AddUpdateModal
                            modalDetails={modalDetails}
                            modalMode={modalMode}
                            display={displayModal} displayModalOff={displayModalOff} /> : <div></div>}

                    <div className="container-fluid default-top bg-gray p-5">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between mb-3">
                                <div className="header-self"> Self-Service</div>
                                {hasAdminAccess &&
                                    <button className="btn btn-light" onClick={(e) => { handleAddUpdates(e) }}>
                                        <i className="fas fa-plus mr-2"></i> Add a Dataset
                                    </button>}
                            </div>
                        </div>

                        <Table bordered className='mb-4'>
                            <thead>
                                <tr className='Selfservicetable'>
                                    <th onClick={() => onSort('Dataset_Name')}>Power BI Dataset {renderSortCaret('Dataset_Name')}</th>
                                    <th onClick={() => onSort('Description')}>Description {renderSortCaret('Description')}</th>
                                    {hasAdminAccess ?
                                        <>
                                            <th >Status </th>
                                            <th >Edit </th></> : null}
                                </tr>
                            </thead>
                            <tbody className='Selfservicebcls'>
                                {paginatedData?.length > 0 ? <>
                                    {paginatedData.map((row, index) => (
                                        <tr key={index}>
                                            <td>{datasetFormatter(row?.Dataset_Name, row)}</td>
                                            <td>{row?.Description}</td>
                                            {hasAdminAccess ?
                                                <>
                                                    <td>{statusFormatter(row?.Status, row)}</td>
                                                    <td>{editColumnFormatter(row?.Status, row)}</td> </> : null}
                                        </tr>
                                    ))}</> : <tr> <td colSpan="17" style={{ textAlign: 'center' }}>No Data Available</td></tr>}
                            </tbody>
                        </Table>
                        {allreasesData?.length > 0 &&
                            <div className='row'><div className='col-6'>Showing rows {allreasesData.length > 0 ? <span>1 to {allreasesData.length} of {allreasesData.length}</span> : <span>0 to 0 of 0</span>}</div>
                                <div className='col-6'>
                                <Pagination className="d-flex justify-content-end mb-4 mt-0">
                                    <Pagination.Prev
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    />
                                    {getPaginationItems()}
                                    <Pagination.Next
                                        disabled={currentPage === totalPages}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    />
                                </Pagination>
                                </div>
                            </div>}
                    </div>
                </div>
                )}
        </div>
    )

}

export default Selfservice;
import React, { useState, useEffect } from 'react';
import { Table } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { Form } from "react-bootstrap";
import { getInsightsTable } from '../../actions';
import { ExcelfileIcon, CarretIconUp, CarretIconDown } from '../../components/Icons';
import { convertToKMB } from '../../helper';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx-js-style"
import NoImageeCompass from "./NoImageeCompass.jpg";
import BarLoader from "react-spinners/BarLoader";
import { splitRetailerAndCountry, getFirstAndLastDate, getOtherFilterInformation } from '../../helper';

const NewInsightsTopMoversTable = (props) => {
    let dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [topMoversTableData, setTopMoversTableData] = useState([]);
    const [toploserTableData, setToploserTableData] = useState([]);
    const { filterDateRange } = props;
    let {
        selectedCustomerKey,
        selectedGlobalFilters,
        selectedRetailerCurrency,
        dynamicPriceFormatting,
        project,
        subproject,
        insightsDateValues
    } = useSelector((state) => state.globalReducer);
    let { selectedSelectionAndMetricName, insightsTabsDetailsData } = useSelector((state) => state.insightReducer);
    let { finalData, tabDetails } = insightsTabsDetailsData ? insightsTabsDetailsData : "";
    let tabDetailsindex = tabDetails[selectedSelectionAndMetricName?.sectionName]?.length > 0 ? tabDetails[selectedSelectionAndMetricName.sectionName][0] : "";
    let tabDetailsTimeframeDefault = tabDetailsindex?.TIMEFRAME;
    const [topGainersOption, setTopGainersOption] = useState(tabDetailsindex?.METRIC_NAME);

    let topMoverstext = "Top Movers";
    let toplosertext = "Top Losers";
    let filternamesarray = finalData["FILTER_NAMES"] && finalData["FILTER_NAMES"] ? finalData["FILTER_NAMES"].split(',') : '';
    let filtervaluesarray = finalData["FILTER_COLUMNS"] && finalData["FILTER_COLUMNS"] ? finalData["FILTER_COLUMNS"].split(',') : '';
    let finalfilterarray = [];

    if (filternamesarray?.length && filtervaluesarray?.length) {
        filternamesarray.forEach(function (item, index) {
            finalfilterarray.push({ name: item, value: filtervaluesarray[index] })
        });
    }

    let filterarrayresult = finalfilterarray[0]?.value ? finalfilterarray[0]?.value : "";
    let filterarrayname = finalfilterarray[0]?.name ? finalfilterarray[0]?.name : "";
    const [topLoserOption, setTopLoserOption] = useState(filterarrayresult);
    const [typeValuecheck, setTypeValuecheck] = useState(tabDetailsindex?.TYPE);
    const [displayNameDropdown, setDisplayNameDropdown] = useState(filterarrayname);
    const [topLoserFinalSort, setTopLoserFinalSort] = useState('asc');
    const [topGainerFinalSort, setTopGainerFinalSort] = useState('desc');
    const [topGaineColumnSort, setTopGaineColumnSort] = useState('CHANGE_PP');
    const [topLoserCoulmnSort, setTopLoserCoulmnSort] = useState('CHANGE_PP');
    const responseHandlerupdate = (response) => {
        let resTabledata = response?.data?.responseData?.insightsTableData;
        if (resTabledata && (Object.keys(resTabledata[[topMoverstext]]).length > 0)) {
            setTopMoversTableData(response.data.responseData.insightsTableData[[topMoverstext]]);
        } else {
            setTopMoversTableData([]);
        }
        if (resTabledata && (Object.keys(resTabledata[[toplosertext]]).length > 0)) {
            setToploserTableData(response.data.responseData.insightsTableData[[toplosertext]]);
        } else {
            setToploserTableData([]);
        }
        setLoading(false);
    }
    const [displayNameFdp, setDisplayNameFdp] = useState(tabDetailsindex?.DISPLAY_NAME);
    const [timeframeCheck, setTimeframeCheck] = useState(tabDetailsindex?.TIMEFRAME);
    const getTimeFrame = (timeFrame) => {
        let timeFrameArr = '';
        tabDetails[selectedSelectionAndMetricName.sectionName].forEach(tab => {
            if (tab?.METRIC_NAME === tabDetailsindex?.METRIC_NAME) {
                timeFrameArr = timeframeCheck.replace(/"|'/g, '').split(',');
            }
        })
        let timeFramObj = {};
        for (let i = 0; i < timeFrameArr.length; i++) {
            let temp = timeFrameArr[i].trim();
            if (temp == 'L1CW') {
                timeFramObj['Last 1 week'] = temp;
            } else if (temp == 'L4CW') {
                timeFramObj['Last 4 weeks'] = temp;
            } else if (temp == 'L13CW') {
                timeFramObj['Last 13 weeks'] = temp;
            } else if (temp == 'L26CW') {
                timeFramObj['Last 26 weeks'] = temp;
            } else if (temp == 'L52CW') {
                timeFramObj['Last 52 weeks'] = temp;
            } else if (temp.includes('MTD')) {
                timeFramObj['MTD'] = temp
            } else if (temp.includes('QTD')) {
                timeFramObj['QTD'] = temp;
            } else if (temp.includes('YTD')) {
                timeFramObj['YTD'] = temp;
            }
        }
        return timeFramObj[timeFrame];
    }

    useEffect(() => {
        setLoading(true);
        if (selectedSelectionAndMetricName?.sectionName === "Top Movers" && topLoserOption !== '') {
            dispatch(getInsightsTable({
                customerKey: selectedCustomerKey,
                filterValues: selectedGlobalFilters,
                sectionName: selectedSelectionAndMetricName.sectionName,
                metricNames: topGainersOption,
                timeframe: getTimeFrame(filterDateRange.selectedDateRange),
                sectiongroupValue: topLoserOption,
                sortColumn: [topGaineColumnSort, topLoserCoulmnSort],
                sortOrder: [topGainerFinalSort, topLoserFinalSort]
            },
                responseHandlerupdate));
        }
        dispatch({ type: "SAVE_TOPMOVERS_OPTIONS", payload: displayNameFdp })

    }, [selectedGlobalFilters, filterDateRange?.selectedDateRange, topLoserOption, topGainersOption]);

    function getSheetData(data, header) {
        let fields = Object.keys(data[0]);
        let sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    const downloadData = () => {
        dispatch(getInsightsTable({
            customerKey: selectedCustomerKey,
            filterValues: selectedGlobalFilters,
            sectionName: selectedSelectionAndMetricName.sectionName,
            metricNames: topGainersOption,
            timeframe: getTimeFrame(filterDateRange.selectedDateRange),
            sectiongroupValue: topLoserOption,
            sortColumn: [topGaineColumnSort, topLoserCoulmnSort],
            sortOrder: [topGainerFinalSort, topLoserFinalSort],
            isExport: true
        }, responseHandlerupdatedownload));
    }
    const numberWithCommas = (val) => {
        let resultNum = val !== null && val !== undefined && val !== 'NaN' ? parseFloat(val) : 0;
        return selectedRetailerCurrency + resultNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const responseHandlerupdatedownload = (response) => {
        let headers = []
        let bodyData = [];
        let topMovercombinedata = [];
        let topMoversTableDataDownloadnw = [];
        let toploserTableDataDownloadnw = [];
        if (response?.data?.responseData?.insightsTableData) {
            if (response.data.responseData.insightsTableData[[topMoverstext]].length >= 0) {
                topMoversTableDataDownloadnw.push(response.data.responseData.insightsTableData[[topMoverstext]]);
            }
            if (response.data.responseData.insightsTableData[[topMoverstext]].length >= 0) {
                toploserTableDataDownloadnw.push(response.data.responseData.insightsTableData[[toplosertext]]);
            }

        }
        if (topMoversTableDataDownloadnw.length || toploserTableDataDownloadnw.length) {
            headers = ["Type", displayNameFdp, "Change compared to previous period", "POP", "Change compared to same period last year", "YOY",
            "Sellout current period","Sellout POP","Sellout YOY","Content Health","Content Health Score POP","Content Health Score YOY",
            "Current Rating","Current Rating POP","Current Rating YOY", "Count of Reviews", "Reviews POP", "Reviews YOY",
            "In-stock percentage","In-stock POP","In-stock YOY","Search rank","Search Rank POP", "Search Rank POY",
            "Price", "Price POP", "Price POY", "Sku Health", "Sku Health POP", "Sku Health POY"
            ]
            let rpcupcHeader = subproject === 'MagazineLuiza-BR' ? 'UPC Id' : 'RPC Id';
            if (topLoserOption === "ITEM_ID") {
                headers.splice(1, 0, "SKU Image", " SKU Title", "ITEM_ID", rpcupcHeader);
            } else {
                headers.splice(1, 0, displayNameDropdown);
            }
            let multilehund = 100;
            let tablefirst = topMoversTableDataDownloadnw[0].map(v => ({ ...v, topGainers: "Top Gainers" }));
            let tablesecond = toploserTableDataDownloadnw[0].map(v => ({ ...v, topGainers: "Top Losers" }));

            topMovercombinedata = [...tablefirst, ...tablesecond];
            bodyData = topMovercombinedata.map(item => {
                let typecheck = typeValuecheck.toLowerCase();
                let imageurl = item.IMAGE_URL ? item.IMAGE_URL : NoImageeCompass;
                let productTitle = item.PRODUCT_TITLE ? item.PRODUCT_TITLE : 'N/A';
                let itemId = item.ITEM_ID ? item.ITEM_ID : '';
                let rpcId = item.RPC_ID ? item.RPC_ID : 'N/A';
                let upcId = item.UPC ? item.UPC : 'N/A';
                let rpcupcId = subproject === 'MagazineLuiza-BR' ? upcId : rpcId;
                let toploser = item[[topLoserOption]] ? item[[topLoserOption]] : "";
                let roundValue = item.CP !== null ? item.CP !== 0 ? Math.round(item.CP) : `${item.CP}` : 'N/A';
                let newcellroundPP = item.CHANGE_PP !== null ? item.CHANGE_PP !== 0 ? Math.round(item.CHANGE_PP) : `${item.CHANGE_PP}` : 'N/A';
                let newcelldecimalPP = item.CHANGE_PP !== null ? item.CHANGE_PP !== 0 ? (item.CHANGE_PP).toFixed(1) : `${item.CHANGE_PP}` : 'N/A';
                let newcellroundPY = item.CHANGE_PY !== null ? item.CHANGE_PY !== 0 ? Math.round(item.CHANGE_PY) : `${item.CHANGE_PY}` : 'N/A';
                let newcelldecimalPY = item.CHANGE_PY !== null ? item.CHANGE_PY !== 0 ? (item.CHANGE_PY).toFixed(1) : `${item.CHANGE_PY}` : 'N/A';
                let sellOutCurrent = item.sellOutCurrent ? item.sellOutCurrent : 'N/A';
                let selloutPOP = item.selloutPOP ? item.selloutPOP + '%' : 'N/A';
                let sellOutYOY = item.sellOutYOY ? item.sellOutYOY + '%' : 'N/A';
                let contentHealth = item.contentHealth ? item.contentHealth : 'N/A';
                let contentHealthPOP = item.contentHealthPOP ? item.contentHealthPOP + '%' : 'N/A';
                let contentHealthYOY = item.contentHealthYOY ? item.contentHealthYOY + '%' : 'N/A';
                let currentRating = item.currentRating ? item.currentRating : 'N/A';
                let currentRatingPOP = item.currentRatingPOP ? item.currentRatingPOP + '%' : 'N/A';
                let currentRatingYOY = item.currentRatingYOY ? item.currentRatingYOY + '%' : 'N/A';
                let countOfReview = item.countOfReview ? item.countOfReview : 'N/A';
                let countOfReviewPOP = item.currentReviewPOP ? item.currentReviewPOP + '%' : 'N/A';
                let countOfReviewYOY = item.currentReviewYOY ? item.currentReviewYOY + '%' : 'N/A';
                let instockPercentage = item.instockPercentage ? item.instockPercentage + '%' : 'N/A';
                let instockPercentagePOP = item.instockPercentagePOP ? item.instockPercentagePOP + '%' : 'N/A';
                let instockPercentagePOY = item.instockPercentagePOY ? item.instockPercentagePOY + '%' : 'N/A';
                let searchRank = item.searchRank ? item.searchRank : 'N/A';
                let searchRankPOP = item.searchRankPOP ? item.searchRankPOP + '%'  : 'N/A';
                let searchRankPOY = item.searchRankPOY ? item.searchRankPOY + '%'  : 'N/A';
                let price = item.price ? item.price : 'N/A';
                let pricePOP = item.pricePOP ? item.pricePOP + '%' : 'N/A';
                let pricePOY = item.pricePOY ? item.pricePOY + '%' : 'N/A';
                let skuHealth = item.skuHealth ? item.skuHealth : 'N/A';
                let skuHealthPOP = item.skuHealthPOP ? item.skuHealthPOP + '%' : 'N/A';
                let skuHealthPOY = item.skuHealthPOY ? item.skuHealthPOY + '%' : 'N/A'

                if (topLoserOption === "ITEM_ID") {
                    return [item.topGainers, imageurl, productTitle, itemId, rpcupcId, item?.CP !== null ? typecheck === 'number' ? roundValue : typecheck === 'decimal' ? item.CP !== 0 ? (item.CP).toFixed(1) : item.CP : typecheck === 'percentage' ? item.CP !== 0 ? ((item.CP) * multilehund).toFixed(0) + '%' : `${item.CP}%` : typecheck === 'text' ? numberWithCommas(item.CP) : parseFloat(item.CP) : 'N/A',
                        item?.CHANGE_PP !== null ? typecheck === 'number' ? newcellroundPP : typecheck === 'decimal' ? newcelldecimalPP : typecheck === 'percentage' ? item.CHANGE_PP !== 0 ? ((item.CHANGE_PP) * multilehund).toFixed(0) + '%' : `${item.CHANGE_PP}%` : typecheck === 'text' ? numberWithCommas(item.CHANGE_PP) : parseFloat(item.CHANGE_PP) : 'N/A', item.POP !== null ? ((item.POP) * 100).toFixed(0) + '%' : 'N/A',
                        item?.CHANGE_PY !== null ? typecheck === 'number' ? newcellroundPY : typecheck === 'decimal' ? newcelldecimalPY : typecheck === 'percentage' ? item.CHANGE_PY !== 0 ? ((item.CHANGE_PY) * multilehund).toFixed(0) + '%' : `${item.CHANGE_PY}%` : typecheck === 'text' ? numberWithCommas(item.CHANGE_PY) : parseFloat(item.CHANGE_PY) : 'N/A', item.YOY !== null ? ((item.YOY) * 100).toFixed(0) + '%' : 'N/A',
                        sellOutCurrent, selloutPOP, sellOutYOY, contentHealth, contentHealthPOP, contentHealthYOY,
                        currentRating, currentRatingPOP, currentRatingYOY, countOfReview, countOfReviewPOP, countOfReviewYOY,
                        instockPercentage, instockPercentagePOP, instockPercentagePOY,
                        searchRank, searchRankPOP, searchRankPOY, price, pricePOP, pricePOY,
                        skuHealth, skuHealthPOP, skuHealthPOY
                    ]
                } else {
                    return [item.topGainers, toploser, item?.CP !== null ? typecheck === 'number' ? roundValue : typecheck === 'decimal' ? item.CP !== 0 ? (item.CP).toFixed(1) : item.CP : typecheck === 'percentage' ? item.CP !== 0 ? ((item.CP) * multilehund).toFixed(0) + '%' : `${item.CP}%` : typecheck === 'text' ? numberWithCommas(item.CP) : parseFloat(item.CP) : 'N/A',
                        item?.CHANGE_PP !== null ? typecheck === 'number' ? newcellroundPP : typecheck === 'decimal' ? newcelldecimalPP : typecheck === 'percentage' ? item.CHANGE_PP !== 0 ? ((item.CHANGE_PP) * multilehund).toFixed(0) + '%' : `${item.CHANGE_PP}%` : typecheck === 'text' ? numberWithCommas(item.CHANGE_PP) : parseFloat(item.CHANGE_PP) : 'N/A', item.POP !== null ? ((item.POP) * 100).toFixed(0) + '%' : 'N/A',
                        item?.CHANGE_PY !== null ? typecheck === 'number' ? newcellroundPY : typecheck === 'decimal' ? newcelldecimalPY : typecheck === 'percentage' ? item.CHANGE_PY !== 0 ? ((item.CHANGE_PY) * multilehund).toFixed(0) + '%' : `${item.CHANGE_PY}%` : typecheck === 'text' ? numberWithCommas(item.CHANGE_P) : parseFloat(item.CHANGE_PY) : 'N/A', item.YOY !== null ? ((item.YOY) * 100).toFixed(0) + '%' : 'N/A']
                }
            });
            const getSelectedGlobalFilters = selectedGlobalFilters
            const { retailer, country } = splitRetailerAndCountry(subproject);
            const currentPeriod = `${insightsDateValues.startDateCurrent} to ${insightsDateValues.endDateCurrent}`;
            const previousPeriod = `${insightsDateValues.startDateCompare} to ${insightsDateValues.endDateCompare}`;
            // insightsDateValues =
            // {
            //     "startDateCurrent": "2 March 2024",
            //     "endDateCurrent": "6 March 2024",
            //     "startDateCompare": "1 February 2024",
            //     "endDateCompare": "6 February 2024"
            // }

            let filtersRows = [];
            console.log(getSelectedGlobalFilters)
            if (getSelectedGlobalFilters) {
                const calculatedCategories = getSelectedGlobalFilters?.kc_category?.length ? getSelectedGlobalFilters.kc_category.join(', ') : 'All';
                const calculatedBrands = getSelectedGlobalFilters?.kc_primary_brand?.length ? getSelectedGlobalFilters.kc_primary_brand.join(', ') : 'All'
                const calculatedOtherFilters = getOtherFilterInformation(getSelectedGlobalFilters)
                console.log(calculatedCategories, calculatedBrands, calculatedOtherFilters)
                filtersRows = [
                    ['Current period', currentPeriod],
                    ['Previous Period', previousPeriod],
                    ['Country', country],
                    ['Retailer', retailer],
                    ['Categories', calculatedCategories],
                    ['Brands', calculatedBrands],
                    ['Other filter information', calculatedOtherFilters]
                ];
            }
            else {
                filtersRows = [
                    ['Current period', currentPeriod],
                    ['Previous Period', previousPeriod],
                    ['Country', country],
                    ['Retailer', retailer],
                    ['Categories', "N/A"],
                    ['Brands', "N/A"],
                    ['Other filter information', "N/A"]
                ];
            }
            const sheetData = [...filtersRows];
            const insightsTableData = getSheetData(bodyData, headers);
            console.log(insightsTableData)
            sheetData.push(...insightsTableData);

            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

            const headerRowIndex = filtersRows.length; // Row after the filters
            const totalColumns = headers.length;

            // apply styles
            for (let col = 0; col < totalColumns; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: headerRowIndex, c: col });
                if (worksheet[cellAddress]) {
                    worksheet[cellAddress].s = {
                        fill: { fgColor: { rgb: "BFBFBF" } }, // Light grey background
                        font: { bold: true } // Make the text bold
                    };
                }
            }
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
            saveAs(dataBlob, "DownloadKPIS.xlsx");
        }
    };
    const skuProductTitleFormatter = (row) => {
        let upcId = row?.UPC ? row.UPC : 'N/A';
        let rpcId = row?.RPC_ID ? row.RPC_ID : 'N/A',
            productImage = row?.IMAGE_URL ? row?.IMAGE_URL : NoImageeCompass;
        return (
            <tr className="">
                <td className='img_cls'><img src={productImage} alt="" /></td>
                <td className='title_cls'>{row?.PRODUCT_TITLE ? row?.PRODUCT_TITLE : 'N/A'}{subproject === "MagazineLuiza-BR" ? <> - UPC: {upcId} </> : <> - RPC: {rpcId}</>}</td>
            </tr>
        )
    }

    const changeComparedtoPreviousPeriod = (cell, row) => {
        if (cell !== null && cell !== undefined) {
            let result = (cell - Math.floor(cell)) !== 0;
            let poppercent = row.POP !== 0 ? ((row.POP) * 100).toFixed(0) + '%' : `${row.POP}%`
            let typeValue = typeValuecheck.toLowerCase();
            let newcelldfault = cell !== 0 && result ? cell.toFixed(2) : cell;
            let newcellprecent = cell !== 0 ? (cell * 100).toFixed(0) + '%' : `${cell}%`;
            let newcellround = cell !== 0 ? Math.round(cell) : cell;
            let newcelldecimal = cell !== 0 ? cell.toFixed(1) : cell;
            return (<>
                <span className={row.PP < row.CP ? 'clr-grn d-inline-flex' : 'clr-rd d-inline-flex'}>
                    {row.PP < row.CP ?
                        <CarretIconUp /> : <CarretIconDown />
                    }
                    <span>
                        {typeValue === 'percentage' ? newcellprecent : typeValue === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : typeValue === 'number' ? newcellround : typeValue === 'decimal' ? newcelldecimal : newcelldfault}
                    </span>
                </span>
                <div>{poppercent}</div>
            </>
            )
        } else {
            return 'N/A'
        }
    }
    const changeComparedtSamePeriodLastYear = (cell, row) => {
        if (cell !== null && cell !== undefined) {
            let result = (cell - Math.floor(cell)) !== 0;
            let poppercent = ((row.YOY) * 100).toFixed(0) + '%'
            let typeValue = typeValuecheck.toLowerCase();
            let newcelldfault = cell !== 0 && result ? cell.toFixed(2) : cell;
            let newcellprecent = cell !== 0 ? (cell * 100).toFixed(0) + '%' : `${cell}%`;
            let newcellround = cell !== 0 ? Math.round(cell) : cell;
            let newcelldecimal = cell !== 0 ? cell.toFixed(1) : cell;
            return (<>
                <span className={row.PY < row.CP ? 'clr-grn d-inline-flex' : 'clr-rd d-inline-flex'}>
                    {row.PY < row.CP ?
                        <CarretIconUp /> : <CarretIconDown />
                    }
                    {typeValue === 'percentage' ? newcellprecent : typeValue === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : typeValue === 'number' ? newcellround : typeValue === 'decimal' ? newcelldecimal : newcelldfault}
                </span>
                <div>{poppercent}</div>
            </>
            )
        } else {
            return 'N/A'
        }
    }
    const orderedProductFormatter = (cell, row) => {
        if (cell !== null && cell !== undefined) {
            let result = (cell - Math.floor(cell)) !== 0;
            let typeValue = typeValuecheck.toLowerCase();
            let newcelldfault = cell !== 0 && result ? cell.toFixed(2) : cell;
            let newcellprecent = cell !== 0 ? (cell * 100).toFixed(0) + '%' : `${cell}%`;
            let newcellround = cell !== 0 ? Math.round(cell) : cell;
            let newcelldecimal = cell !== 0 ? cell.toFixed(1) : cell;
            return (
                <>
                    {typeValue === 'percentage' ? newcellprecent : typeValue === 'text' ? convertToKMB(cell, selectedRetailerCurrency) : typeValue === 'number' ? newcellround : typeValue === 'decimal' ? newcelldecimal : newcelldfault}
                </>
            )
        } else {
            return 'N/A'
        }
    };

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [sortConfigloser, setSortConfigloser] = useState({ key: '', direction: '' });
    const onSort = (column) => {
        let direction = 'asc';
        if (sortConfig.key === column && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        dispatch(getInsightsTable({
            customerKey: selectedCustomerKey,
            filterValues: selectedGlobalFilters,
            sectionName: selectedSelectionAndMetricName.sectionName,
            metricNames: topGainersOption,
            timeframe: getTimeFrame(filterDateRange.selectedDateRange),
            sectiongroupValue: topLoserOption,
            sortColumn: [column, topLoserCoulmnSort],
            sortOrder: [direction, topLoserFinalSort]
        }, responseHandlerupdate));
        setTopGainerFinalSort(direction);
        setTopGaineColumnSort(column);
        setSortConfig({ key: column, direction });
    };
    const onSortTopLoser = (column) => {
        let direction = 'asc';
        if (sortConfigloser.key === column && sortConfigloser.direction === 'asc') {
            direction = 'desc';
        }
        dispatch(getInsightsTable({
            customerKey: selectedCustomerKey,
            filterValues: selectedGlobalFilters,
            sectionName: selectedSelectionAndMetricName.sectionName,
            metricNames: topGainersOption,
            timeframe: getTimeFrame(filterDateRange.selectedDateRange),
            sectiongroupValue: topLoserOption,
            sortColumn: [topGaineColumnSort, column],
            sortOrder: [topGainerFinalSort, direction]
        }, responseHandlerupdate));
        setTopLoserFinalSort(direction);
        setTopLoserCoulmnSort(column);
        setSortConfigloser({ key: column, direction });
    };

    const renderSortCaret = (column) => {
        if (sortConfig.key !== column) {
            return <i className="fas fa-sort ml-2"></i>;
        }
        if (sortConfig.direction === 'asc') {
            return <i className="fas fa-sort-up ml-2 active"></i>;
        }
        return <i className="fas fa-sort-down ml-2 active"></i>;
    };

    const renderSortCaretLoser = (column) => {
        if (sortConfigloser.key !== column) {
            return <i className="fas fa-sort ml-2"></i>;
        }
        if (sortConfigloser.direction === 'asc') {
            return <i className="fas fa-sort-up ml-2 active"></i>;
        }
        return <i className="fas fa-sort-down ml-2 active"></i>;
    };

    return (
        <div className='pl-4 pr-4 pb-5 mt-5'>
            <div className='row mar-tp'>
                {topGainersOption?.length > 0 || topLoserOption?.length > 0 ?
                    <div class="col-2">
                        <span className='titletp'> Top Movers</span>
                    </div>
                    : null}
                {topGainersOption?.length > 0 &&
                    <div className="col-3">
                        <Form.Group controlId="formBasicSelect" className='topmovers_dropdwon'>
                            <Form.Control as="select" value={topGainersOption}
                                onChange={e => {
                                    var selectedoption = document.getElementById("formBasicSelect");
                                    var option = selectedoption.options[selectedoption.selectedIndex];
                                    setTopGainersOption(e.target.value);
                                    setTypeValuecheck(option.getAttribute("data-type"));
                                    setTimeframeCheck(option.getAttribute("data-time"));
                                    setDisplayNameFdp(option.getAttribute("data-display"));
                                }}>
                                {tabDetails[selectedSelectionAndMetricName?.sectionName] && tabDetails[selectedSelectionAndMetricName?.sectionName].map((firstData, index) => {
                                    if (firstData === null) {
                                        return false;
                                    } else {
                                        return <option key={index} data-time={firstData?.TIMEFRAME} data-display={firstData?.DISPLAY_NAME} data-type={firstData?.TYPE} value={(firstData?.METRIC_NAME).trim()}>{firstData?.DISPLAY_NAME}</option>
                                    }
                                })}
                            </Form.Control>
                        </Form.Group>
                    </div>}

                {topLoserOption?.length > 0 &&
                    <div className="col-3">
                        <Form.Group className='topmovers_dropdwon'>
                            <Form.Control as="select" id="TopLoserDropdown" value={topLoserOption}
                                onChange={e => {
                                    var selectedoption = document.getElementById("TopLoserDropdown");
                                    var option = selectedoption.options[selectedoption.selectedIndex];
                                    setTopLoserOption(e.target.value);
                                    setDisplayNameDropdown(option.getAttribute("data-name"));
                                }}>
                                {finalfilterarray &&
                                    finalfilterarray.map((firstData) => {
                                        return <option data-name={firstData?.name} value={(firstData.value).trim()}>{firstData.name}</option>
                                    })}
                            </Form.Control>
                        </Form.Group>
                    </div>}
                <div className='col-3'>
                    <span className='downloadfile' onClick={(e) => downloadData()}> Download full report <ExcelfileIcon /></span>
                </div>
            </div>
            {loading ? (<div id="strategy_wrapper">
                <div className="container-fluid  default-top loading_insights loading_wrapper bg-w p-5">
                    <div className="sweet-loading">
                        <span><b>Processing...</b></span>
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <BarLoader width={100} color="#123abc" />
                        </div>
                    </div>
                </div>
            </div>) :
                <div className='d-flex mt-5'>
                    <div className='row'>
                        <div className='col-6 topMoversTablecls'>
                            <div className='topMoverheader'>
                                <span className='topgainorloser'>Top Gainers</span>
                            </div>

                            <div className={`${topMoversTableData.length >= 4 ? "top_moverstablescls" : ""} table-responsive`} >
                                <Table bordered className='table_center mb-4'>
                                    <thead className='topMvtable sticky-top bg-light'>
                                        <tr >
                                            {topLoserOption === "ITEM_ID" ? <th style={{ width: '450px' }}>SKU</th> :
                                                <th style={{ width: '450px' }}>{displayNameDropdown}</th>}
                                            <th style={{ width: '230px' }} onClick={() => onSort('CP')}>{displayNameFdp}{renderSortCaret('CP')}</th>
                                            <th style={{ width: '260px' }} onClick={() => onSort('CHANGE_PP')}>Change compared to previous period {renderSortCaret('CHANGE_PP')}</th>
                                            <th style={{ width: '260px' }} onClick={() => onSort('CHANGE_PY')}>Change compared to same period last year {renderSortCaret('CHANGE_PY')}</th>

                                        </tr>
                                    </thead>
                                    <tbody className='topmvtablestle'>
                                        {topMoversTableData.length ? <>
                                            {topMoversTableData.map((row, index) => (
                                                <tr key={index}>
                                                    {topLoserOption === "ITEM_ID" ?
                                                        <td className='skutitle-cls'>{skuProductTitleFormatter(row)}</td> :
                                                        <td>{row[topLoserOption]}</td>
                                                    }
                                                    <td >{orderedProductFormatter(row?.CP, row)}</td>
                                                    <td >{changeComparedtoPreviousPeriod(row?.CHANGE_PP, row)}</td>
                                                    <td >{changeComparedtSamePeriodLastYear(row?.CHANGE_PY, row)}</td>

                                                </tr>
                                            ))} </>
                                            : <tr><td colSpan={4}>No Data Available</td></tr>}
                                    </tbody>
                                </Table>
                            </div>
                            <div className='mt-3 mb-4'>
                                <span class="">Showing rows {topMoversTableData.length > 0 ? <span>1 to {topMoversTableData.length} of {topMoversTableData.length}</span> : <span>0 to 0 of 0</span>}</span>
                            </div>
                        </div>
                        <div className='col-6 topMoversTablecls'>
                            <div className='topMoverheader'>
                                <span className='topgainorloser'>Top Losers</span>
                            </div>
                            <div className={`${toploserTableData.length >= 4 ? "top_moverstablescls" : ""} table-responsive`} >
                                <Table bordered className='table_center mb-4'>
                                    <thead className='topMvtable sticky-top bg-light'>
                                        <tr >
                                            {topLoserOption === "ITEM_ID" ? <th style={{ width: '450px' }}>SKU</th> :
                                                <th style={{ width: '450px' }}>{displayNameDropdown}</th>}
                                            <th style={{ width: '230px' }} onClick={() => onSortTopLoser('CP')}>{displayNameFdp}{renderSortCaretLoser('CP')}</th>
                                            <th style={{ width: '260px' }} onClick={() => onSortTopLoser('CHANGE_PP')}>Change compared to previous period {renderSortCaretLoser('CHANGE_PP')}</th>
                                            <th style={{ width: '260px' }} onClick={() => onSortTopLoser('CHANGE_PY')}>Change compared to same period last year {renderSortCaretLoser('CHANGE_PY')}</th>

                                        </tr>
                                    </thead>
                                    <tbody className='topmvtablestle'>
                                        {toploserTableData.length ? <>
                                            {toploserTableData.map((row, index) => (
                                                <tr key={index}>
                                                    {topLoserOption === "ITEM_ID" ?
                                                        <td className='skutitle-cls'>{skuProductTitleFormatter(row)}</td> :
                                                        <td>{row[topLoserOption]}</td>
                                                    }
                                                    <td >{orderedProductFormatter(row?.CP, row)}</td>
                                                    <td >{changeComparedtoPreviousPeriod(row?.CHANGE_PP, row)}</td>
                                                    <td >{changeComparedtSamePeriodLastYear(row?.CHANGE_PY, row)}</td>

                                                </tr>
                                            ))} </>
                                            : <tr><td colSpan={4}>No Data Available</td></tr>}
                                    </tbody>
                                </Table>

                            </div>
                            <div className='mt-3 mb-4'>
                                <span class="">Showing rows {toploserTableData.length > 0 ? <span>1 to {toploserTableData.length} of {toploserTableData.length}</span> : <span>0 to 0 of 0</span>}</span>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )

}

export default NewInsightsTopMoversTable;
import React, { Component } from 'react';
import { PriceFormatter } from '../../helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectRecommendation } from '../../actions';
import { Table, Pagination, OverlayTrigger, Tooltip, FormControl, InputGroup, Form } from 'react-bootstrap';
import { getTotalFocusAlertValue, getFocusAltertEstimatedValue } from './FocusAlertFunctions';

class RecommendationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
            data: this.props.data,
            sortField: '',
            sortOrder: 'asc',  // 'asc' or 'desc',
            currentPage: 1,
            rowsPerPage: 10,  // Number of rows per page
            pageSizeOptions : [10, 20, 30, 50]
        }
    }
    callRecommendationModal(row, strategyClicked, asin, productId, location, channel, actionTakenInfo, kcPlatform) {
        if (strategyClicked.substring(0, 3) !== "<ul") {
            this.props.displayModalOn(strategyClicked, asin, productId, location, channel, actionTakenInfo, kcPlatform);
            this.props.selectRecommendationAPI({ row });
        }


    }

    minusSignFormatter() {
        return (
            <i className="fas fa-minus-circle"></i>
        )
    }
    skuDetailFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <div className="flex">
                <div className="mr-3">
                    {row.imageUrl !== null && row.imageUrl !== "0" ?
                        <a className="sku" target="_blank" rel="noopener noreferrer" href={row.productUrl}>
                            <img src={row.imageUrl} className="image-fluid" height="70" width="75" />
                        </a>
                        :

                        <img src={process.env.PUBLIC_URL + '/img/kc_logo.jpg'} className="image-fluid" height="70" width="75" />
                    }
                </div>
                <div className='text-left'>
                    <p> {row.title} </p>
                    <p>UPC : {row.asin}</p>
                    <p>ID : {row.productId}</p>
                    {row.tradeItem ? <p>Trade Item : {row.tradeItem}</p> : null}
                </div>
            </div>
        )
    }
    upcFormatter(row, cell) {
        return (
            <div className="sku">{Number(cell.asin)}</div>
        )
    }
    channelFormatter(row, cell) {
        return (
            <div className="channel_td">{cell.channel}</div>
        )
    }
    estimatedRevenueFormatter(cell, row, rowIndex, formatExtraData) {

        return (
            <div style={{ textAlign: "right", paddingRight: "15px" }}>
                <strong> {PriceFormatter(row.estimatedRevenueImpact, { precision: 0 })}</strong>
            </div>
        )
    }
    focusedEstimatedRevenueFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <div style={{ textAlign: "right", paddingRight: "5px" }}>
                <strong> {PriceFormatter(getFocusAltertEstimatedValue(formatExtraData.recommendationFilters, row, formatExtraData.priorityObj), { precision: 0 })}</strong>
            </div>
        )
    }
    recommendationFormatter(cell, row) {
        function renderTooltip(props) {
            if (props === "No Data Present") {
                return (
                    <Tooltip id="button-tooltip" {...props}>
                        No Data Present
                    </Tooltip>
                );
            }
            return (
                <Tooltip id="button-tooltip" {...props}>
                    {PriceFormatter(props, { precision: 0 })}
                </Tooltip>
            );
        }
        let { actionTakenInfo } = row;
        let map = {
            "Open": {
                color: "#fff",
                background: "#9C9A9A",
                border: "#9C9A9A"
            },
            "Action Taken": {
                color: "#fff",
                background: "#0056BB",
                border: "#0056BB"
            },
            "Approve": {
                color: "#fff",
                background: "#F3AC00",
                border: "#F3AC00"
            },
            "Remove": {
                color: "#303030",
                background: "#F5F5F5",
                border: "#F5F5F5"
            },
            "None": {
                color: "#fff",
                background: "#777",
                border: "#777"
            }
        }
        let recommendations = [];
        if (this.props.recommendationFilters.length > 0) {
            row.recommendationInfo.forEach(itemObj => {
                if (this.props.recommendationFilters.includes(itemObj.recommendationName))
                    recommendations.push(itemObj);
            })
            row.recommendationInfo.forEach(itemObj => {
                if (!this.props.recommendationFilters.includes(itemObj.recommendationName))
                    recommendations.push(itemObj);
            })
        }
        else {
            recommendations = row.recommendationInfo;
        }
        return (
            <ul className="recommendations-type text-left" onClick={(e) => {

            }} style={{ width: "fit-content" }}>
                {recommendations.map((itemObj, index) => {
                    let styleObj = { background: '#fff', color: map["None"], border: `1px solid ${map["None"]}` };
                    if (actionTakenInfo && actionTakenInfo.hasOwnProperty(itemObj.recommendationName)) {
                        let action = map[actionTakenInfo[itemObj.recommendationName].action];
                        let color = action.color;
                        let background = action.background;
                        let border = action.border;
                        styleObj = { background: background, color: color, border: `1px solid ${border}` }
                    }
                    if (this.props.recommendationFilters &&
                        this.props.recommendationFilters.length > 0 &&
                        !this.props.recommendationFilters.includes(itemObj.recommendationName)) {
                        styleObj["opacity"] = "0.2";
                    }
                    return (
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip(itemObj.estimatedValue)}
                        >
                            < li style={styleObj} key={index} onClick={(e) => {
                                this.callRecommendationModal(row, e.target.innerHTML, row.asin, row.productId, row.location, row.channel, row.actionTakenInfo, row.kcPlatform)
                                localStorage.setItem("selected-estimated-value", itemObj.estimatedValue)
                            }}>{itemObj.recommendationName}</li>
                        </OverlayTrigger>
                    )
                })}
            </ul>
        )
    }
    estimatedRevenueHeaderFormatter(column, colIndex, component) {
        return (
            <OverlayTrigger
                placement="auto"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="comments-tooltip">
                        <div style={{ maxWidth: "370px" }}>The weighted estimated value of all recommendations for the concerned product SKU</div>
                    </Tooltip>
                }
            >
                <i
                    class="fas fa-info-circle"
                    style={{
                        color: "#58595B",
                        cursor: "pointer",
                    }}
                ></i>
            </OverlayTrigger>
        )
    }
    focusAlertEstimationColumnHeaderFormatter(column, colIndex, component) {
        return (
            <div style={{ display: "Flex", textAlign: "center" }}>
                <div>{column.text}</div>
                <div style={{ cursor: "pointer" }}>
                    <div onClick={(event) => {
                        event.stopPropagation();
                    }}>
                        <OverlayTrigger
                            placement="auto"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id="comments-tooltip">
                                    <div style={{ maxWidth: "370px" }}>The weighted estimated value of only filtered recommendations for the concerned product SKU</div>
                                </Tooltip>
                            }
                        >
                            <i
                                class="fas fa-info-circle"
                                style={{
                                    color: "#58595B",
                                    cursor: "pointer",
                                    paddingLeft: "5px"
                                }}
                            ></i>
                        </OverlayTrigger></div>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="ml-2 bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
            </div>
        )
    }
    handleSearch = (event) => {
        const searchQuery = event.target.value.toLowerCase();
        const filteredData = this.props.data.filter(row =>
            (row.title?.toLowerCase() || "").includes(searchQuery)
        );
        this.setState({ searchQuery, data: filteredData });
    }

    handleSort = (field) => {
        const { sortField, sortOrder, currentPage, rowsPerPage, data } = this.state;

        // Get the current page's data slice
        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

        let newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

        // Sort only the current page's data
        let sortedCurrentRows = currentRows.sort((a, b) => {
            if (newSortOrder === 'asc') {
                return a[field] > b[field] ? 1 : -1;
            } else {
                return a[field] < b[field] ? 1 : -1;
            }
        });

        // Replace the current page's data slice with the sorted data
        const newData = [
            ...data.slice(0, indexOfFirstRow),
            ...sortedCurrentRows,
            ...data.slice(indexOfLastRow)
        ];

        this.setState({
            data: newData,
            sortField: field,
            sortOrder: newSortOrder,
        });
    };
    // Handle sort icon rendering
    handleSortIcon = (field) => {
        const { sortField, sortOrder } = this.state;
        if (sortField === field) {
            return sortOrder === 'asc'
                ? <i className="fas fa-sort-up active"></i>
                : <i className="fas fa-sort-down active"></i>;
        }
        return <i className="fas fa-sort"></i>;
    };

     // Handle page size change
    
    handleSizePerPageChange = (e) => {
        this.setState({rowsPerPage: parseInt(e.target.value, 10)})
        this.setState({ currentPage: 1 });
    };

    handlePageChange = (page) => {
        this.setState({ currentPage: page });
    };

    getPaginationItems = () => {
        const { currentPage } = this.state;
        const totalPages = Math.ceil(this.state.data.length / this.state.rowsPerPage);
        const items = [];
        const visiblePages = 3; // Number of pages to display at once

        const startPage = Math.max(1, currentPage - visiblePages);
        const endPage = Math.min(totalPages, currentPage + visiblePages);

        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} onClick={() => this.handlePageChange(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => this.handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            items.push(
                <Pagination.Item key={totalPages} onClick={() => this.handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return items;
    };
    render() {
        const { currentPage, rowsPerPage, data } = this.state;

        // Calculate the slice of data to be displayed on the current page
        const indexOfLastRow = currentPage * rowsPerPage;
        const indexOfFirstRow = indexOfLastRow - rowsPerPage;
        const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

        const totalPages = Math.ceil(data.length / rowsPerPage);
        return (
            <div className="table-responsive recommendations-table ">
                <div>

                    <div className="flex justify-content-between">
                        <h4>Showing {this.props.data.length} SKUs</h4>
                        {this.props.recommendationFilters.length > 0 ?
                            <div style={{ display: "Flex" }}>
                                <div>
                                    <h4><strong>Focus Alert:</strong> <font color="#0056BB">The Selected Focus Alerts</font> valued at <strong>{PriceFormatter(getTotalFocusAlertValue(this.props.recommendationFilters, this.props.priorities, this.props.data), { precision: 0 })}</strong> </h4>
                                </div>
                                <div style={{ marginLeft: "5px", marginTop: "-2px" }}>
                                    <OverlayTrigger
                                        placement="bottom"
                                        pointerEvents="none"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="comments-tooltip">
                                                <div style={{ maxWidth: "370px" }}>Total value which can be generated if actioned on all SKUs for the filtered recommendations</div>
                                            </Tooltip>
                                        }
                                    >
                                        <i
                                            class="fas fa-info-circle"
                                            style={{
                                                color: "#58595B",
                                                cursor: "pointer",
                                            }}
                                        ></i>
                                    </OverlayTrigger></div></div>
                            : <></>}
                        <InputGroup className="mb-3 ms-auto" style={{ width: '200px' }}>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                className="form-control-sm"
                                onChange={this.handleSearch}
                            />
                        </InputGroup>
                    </div>
                    <Table bordered className='table_center mb-4'>
                        <thead>
                            <tr className=''>
                                <th>Product </th>
                                <th>Channel </th>
                                <th>Location </th>
                                <th>Total Estimated Value {this.estimatedRevenueHeaderFormatter()}</th>
                                <th onClick={() => this.handleSort('recommendationName')}>Recommendations {this.handleSortIcon('recommendationName')} </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.length > 0 ? <>
                                {currentRows.map((row, index) => (
                                    <tr key={index}>
                                        <td>{this.skuDetailFormatter(row?.title, row, index)}</td>
                                        <td>{row?.channel}</td>
                                        <td>{row?.fcNameId}</td>
                                        <td>{this.estimatedRevenueFormatter(row?.estimatedRevenueImpact, row)}</td>
                                        <td>{this.recommendationFormatter(row?.recommendationTypes, row)}</td>
                                    </tr>
                                ))}</> : <tr> <td colSpan="17">No Data Available</td></tr>}
                        </tbody>

                    </Table>
                    <div className="d-flex justify-content-between align-items-center mt-0">
                        <Form.Group controlId="sizePerPageSelect">
                            <Form.Control
                                as="select"
                                value={rowsPerPage}
                                onChange={this.handleSizePerPageChange}
                                id='pageDropDown'
                                className="pageDropdownoption"
                            >
                                {this.state.pageSizeOptions.map(size => (
                                    <option key={size} value={size}>{size}</option>
                                ))}
                            </Form.Control>
                            <Form.Label>Showing rows {((currentPage - 1) * rowsPerPage) + 1} to {Math.min(currentPage * rowsPerPage, data?.length)} of {data?.length}</Form.Label>
                        </Form.Group>

                        <Pagination className="d-flex justify-content-end mb-4">
                            <Pagination.Prev
                                disabled={currentPage === 1}
                                onClick={() => this.handlePageChange(currentPage - 1)}
                            />
                            {this.getPaginationItems()}
                            <Pagination.Next
                                disabled={currentPage === totalPages}
                                onClick={() => this.handlePageChange(currentPage + 1)}
                            />
                        </Pagination>
                    </div>

                </div>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        selectRecommendationAPI: selectRecommendation
    }, dispatch)
}

export default connect(null, mapDispatchToProps)(RecommendationTable);
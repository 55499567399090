export default function(state={tabList:[]},action){
    switch(action.type){
        case "GET_SAVED_FILTERS":return {...state,savedFilters:action.payload}
        case "NAVBAR_CLICKED" : return {...state,navbarClickedItem:action.payload}
        case "GET_LAST_REFRESH_DATE" : return {...state,getLastDataRefreshDate:action.payload}
        case "HIDE_NAVBAR_PAGES" : return {...state, hideNavbarPages:action.payload}
        case "SELECT_PROJECT" :  return {...state, project:action.payload}
        case "SELECT_SUBSET" :  return {...state, subproject:action.payload}
        case "SET_USER_INFO" : return {...state, userinfo:action.payload}
        case "ADD_NEW_PROJECT" : return {...state, addNewProject:action.payload}
        case "ADD_NEW_SUBSET" : return {...state, addNewSubproject:action.payload}
        case "SET_SELECTED_SUBPROJECT_INFO" : return {...state, selectedSubprojectInfo:action.payload}
        case "SET_RETAILER_CURRENCY" : return {...state, selectedRetailerCurrency:action.payload}
        case "SET_CUSTOMER_KEY" : return {...state, selectedCustomerKey:action.payload}
        case "SET_SELECTED_PROJECT_INFO" : return {...state, selectedProjectInfo:action.payload}
        case "SET_IS_APP_ADMIN" : return {...state, isAppAdmin : action.payload}
        case "SET_IS_PROJECT_ADMIN" : return {...state, isProjectAdmin : action.payload}
        case "SET_IS_SUBPROJECT_ADMIN" : return {...state, isSubprojectAdmin : action.payload}
        case "GET_GLOBAL_FILTER" :  return {...state, globalFilter:action.payload, defaultDailyUsageConfig:action.payload.data.defaultDailyUsageConfig}
        case "GET_PROJECTS_AND_SUBSETS" : return {...state, projectAndSubprojects:action.payload}
        case "GET_TAB_LIST_FOR_ACTIVE_PROJECT" : return {...state, tabList:action.payload}
        case "GET_TAB_KEY_MAP_LIST" : return {...state, keyMapTabList:action.payload}
        case "GET_TAB_LIST_FOR_SUB_PROJECT_CREATION" : return {...state, tabListForSubProjectCreation:action.payload}
        case "SELECTED_GLOBAL_FILTERS" : return {...state, selectedGlobalFilters:action.payload}
        case "SUBSCRIPTION_SELECTED_GLOBAL_FILTERS" : return {...state, subscriptionFilters:action.payload}
        case "PRESELECTED_GLOBAL_FILTERS" : return {...state, preselectedGlobalFilters:action.payload}
        case "NO_DEP_VALUE_GLOBAL_FILTERS" : return {...state, noDepValueFilters:action.payload}
        case "SELECTED_FILTER_VALUES" : return {...state, selectedGlobalFilterValues:action.payload}
        case "ALERT_PRECISION_VALUE" : return {...state, alertPrecision:action.payload}
        case "SAVE_FILTER_CLICKED" : return {...state, saveFilterClicked:action.payload}
        case "IS_SNOOZE_SUBPROJECT" : return {...state, isSnoozeSubProject:action.payload}       
        case "DATE_FILTER_OPTIONS" : return {...state, dateFilterOptions:action.payload}// for future implementation to configure date filter option
        case "DATE_FILTER_KEY_VALUE" : return {...state, dateFilterKeyValueOptions:action.payload}
        case "COMPARISON_ID_VALUES" : return {...state, comparisonIdValues:action.payload}
        case "DYNAMIC_COLUMN_PRECISION_VALUE" : return {...state, dynamicColumnPrecision:action.payload}
        case "DYNAMIC_COLUMN_PRICE_FORMAT" : return {...state, dynamicPriceFormatting:action.payload}
        case "INSIGHTS_DATE_VALUES": return {...state, insightsDateValues: action.payload}
        case "DEFAULT_ALERT_FILTER_CONDITION" : return {...state, defaultAlertFilterCondition:action.payload}
        case "RETAILER_IDLE_TIME" : return {...state, retailerIdleTime:action.payload}
        case "GET_LATESTUPDATE_AVAILABILITY" : return {...state, latestUpdateAvailble:action.payload}
        case "GET_LASTREFRESH_DATEPF" : return {...state,getLastDataRefreshDataPF:action.payload}
        case "GET_DECIMALFOR_RETAILERS" : return {...state,getDecimalRetailersData:action.payload.responseData}
        case "GET_EMBED_ACCESSTOKEN" : return {...state,getEmbedAccessTokenData:action.payload}
        default:
            return state;
    }
}

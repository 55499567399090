import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getEmbedToken } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import BarLoader from "react-spinners/BarLoader";
import moment from "moment";

const NewInsightsDetailsModal = (props) => {
    let dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [embedPBIToken, setEmbedPBIToken] = useState('');
    let { selectedGlobalFilters } = useSelector((state) => state.globalReducer);
    let { reportDetailsMetricName, selectedSelectionAndMetricName, insightsStartEndDatesdata } = useSelector((state) => state.insightReducer);

    let reportId = '', pbiTables = '', customerkeyPbi = '', pbiColumns = '',
        mapobj = reportDetailsMetricName?.responseData?.reportData;
    mapobj !== undefined && mapobj?.length && mapobj.map((report, index) => {
        reportId = report.REPORTID;
        pbiTables = report.PBI_TABLES;
        customerkeyPbi = report.CUSTOMER_KEY;
        pbiColumns = report.PBI_COLUMN_NAMES;
    });
    useEffect(() => {
        setLoading(true);
        dispatch(getEmbedToken({
            groupId: process.env.REACT_APP_GROUP_ID,
            dashboardId: reportId
        })).then((res, rej) => {
            if (res?.data?.embedToken) {
                setEmbedPBIToken(res?.data?.embedToken);
                setLoading(false);
            }
        });
    }, []);
    let timeframeVal = props.mqyToValue;
    let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${process.env.REACT_APP_GROUP_ID}`;
    const tableList = [...pbiTables.split(",")];
    const columnList = [];
    let filterArr = [];
    const extraSettings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        hideErrors: false
    };
    const reportStyle = {
        // height: "48rem"
    };
    if (insightsStartEndDatesdata?.start === undefined) {
        filterArr.push({
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "calendar_date",
                column: "date_range",
            },
            operator: "In",
            values: [timeframeVal?.includes('MTD') ? 'MTD' : timeframeVal?.includes('QTD') ? 'QTD'
                : timeframeVal?.includes('YTD') ? 'YTD' : timeframeVal]
        });
    } else if (tableList.includes('Period To Month Date Ranges') && insightsStartEndDatesdata?.start !== undefined) {

        filterArr.push({
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: 'Period To Month Date Ranges',
                column: 'YEAR'
            },
            operator: "In",
            values: [moment(insightsStartEndDatesdata?.start).format("YYYY")].map(Number)
        });
        filterArr.push({
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: 'Period To Month Date Ranges',
                column: 'MONTH'
            },
            operator: "In",
            values: [moment(insightsStartEndDatesdata?.start).format("M")].map(Number)
        });
        filterArr.push({
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: 'Period To Month Date Ranges',
                column: 'TIMEFRAME'
            },
            operator: "In",
            values: [timeframeVal?.includes('MTM') ? 'MTM' : timeframeVal?.includes('QTM') ? 'QTM'
                : timeframeVal?.includes('YTM') ? 'YTM' : timeframeVal?.includes('Last Day') ? 'LD' : timeframeVal]
        });
    }

    pbiColumns.split(",").forEach((columnName) => {
        columnList.push({
            column: columnName,
            values: [customerkeyPbi]
        });
    })

    const updatedObject = { ...selectedGlobalFilters };
    const removeValueFromObjectArray = (obj) => {
        const key = 'rpln_flag';
        if (obj.hasOwnProperty(key) && obj[key].length > 0) {
            delete obj[key];
        }
        return obj;
    }
    const updatedDataObj = selectedSelectionAndMetricName?.sectionName === 'Digital Shelf' ? removeValueFromObjectArray(updatedObject) : selectedGlobalFilters;
    Object.keys(updatedDataObj)
        .forEach((key) => {
            if (updatedDataObj[key].length > 0) {
                if (key == 'special_filter') {
                    updatedDataObj[key].forEach((spFilterObj) => {
                        columnList.push({
                            column: spFilterObj,
                            values: ["Y"]
                        })
                    })
                } else if (key == 'rpln_flag') {
                    let flags = [];
                    updatedDataObj[key].forEach((spFilterObj) => {
                        if (spFilterObj == 'Replenishable' || spFilterObj === 'Y') {
                            flags.push('Y')
                        }
                        if (spFilterObj == 'Non Replenishable') {
                            flags.push('N')
                        }
                    })
                    columnList.push({
                        column: 'RPLN_FLAG',
                        values: flags
                    })
                } else {
                    columnList.push({
                        column: key,
                        values: updatedDataObj[key]
                    })
                }
            }
        });


    for (let i = 0; i < tableList.length; i++) {
        for (let j = 0; j < columnList.length; j++) {
            let finaltable = tableList[i].trim();
            if (finaltable === 'Period To Month Date Ranges') {

            } else if (finaltable.includes('Previous Month')) {
                if (insightsStartEndDatesdata?.start !== undefined) {
                    filterArr.push({
                        $schema: "http://powerbi.com/product/schema#basic",
                        target: {
                            table: finaltable,
                            column: columnList[j].column
                        },
                        operator: "In",
                        values: columnList[j].values
                    });

                }
            } else {
                filterArr.push({
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: finaltable,
                        column: columnList[j].column
                    },
                    operator: "In",
                    values: columnList[j].values
                });
            }
        }
    }

    console.log("filterArr...:", filterArr);
    return (
        <Modal size={'xl'} show={true} onHide={props.displayColumnDialogOff} id="newinsight-modal" >
            <Modal.Header closeButton> </Modal.Header>
            <Modal.Body className="bg-gray py-4">

                <div className="row">
                    <div className="col-12 my-2">
                        {loading && embedPBIToken !== undefined ? (
                            <div id="strategy_wrapper">
                                <div className="container-fluid  default-top loading_insights loading_wrapper bg-w p-5">
                                    <div className="sweet-loading">
                                        <span><b>Processing...</b></span>
                                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <BarLoader width={100} color="#123abc" />
    </div>
                                    </div>
                                </div>
                            </div>) : reportDetailsMetricName !== undefined && reportDetailsMetricName?.responseData?.reportData?.length ?
                            
                            <PowerBIEmbed
                                embedConfig={{
                                    type: 'report',
                                    id: reportId,
                                    embedUrl: embedUrl,
                                    accessToken: embedPBIToken,
                                    tokenType: models.TokenType.Embed,
                                    settings: extraSettings,
                                    filters: filterArr
                                }}
                                cssClassName="report"
                                style={reportStyle}
                            />
                             : (<div className='text-center' style={{ height: '360px' }}>No data available to display</div>)
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default NewInsightsDetailsModal;
import accounting from "accounting-js";
import React from "react";
import cloneDeep from "lodash/cloneDeep";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { updateUserActivity } from "./actions";
import store from "../src/store";

export function checkIfObjectsHaveSameKeys(obj1, obj2) {
	if (!obj1 || !obj2) {
		return false;
	}
	if (Object.keys(obj1).length !== Object.keys(obj2).length) {
		return false;
	}
	let obj1Keys = Object.keys(obj1);
	for (let i = 0; i < obj1Keys.length; i++) {
		if (!obj2.hasOwnProperty(obj1Keys[i])) {
			return false;
		}
	}
	return true;
}

export function getFormattedDate(today) {
	var month = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	var dd = today.getDate();
	var mm = month[today.getMonth()]; //January is 0!
	var yyyy = today.getFullYear();
	return dd + " " + mm + " " + yyyy;
}
export let getSelectedGlobalFiltersCount = (filterObj) => {
	let count = 0;
	filterObj &&
		Object.keys(filterObj).map((key) => {
			count += filterObj[key].length;
		});

	return count;
};

export function PriceFormatter(cell, options) {
	if (cell === "No Data Present") return "No Data Present";

	if (cell === undefined || cell === null) return cell;
	else {
		let str = accounting.formatMoney(cell, options);
		if (str.indexOf("-") > -1) {
			str = str.replace("-", "");
			str = `-${str}`;
		}
		return str;
	}
}

export function getFormattedPriceValue(value, currency) {
	if (value !== undefined && value !== null) {
		return PriceFormatter(Number(value), {
			precision: value.toString().includes(".") ? 2 : 0,
			symbol: currency,
		});
	}
}

export function decimalConfigRetailerPriceFormat(value, currency) {
	return PriceFormatter(Number(value), {
		precision:
			store.getState().globalReducer?.getDecimalRetailersData
				?.DECIMAL_PRECISION,
		symbol: currency,
	}).replace(/(\.|,)/g, (x) => {
		return x === "," ? "." : x === "." ? "," : "";
	});
}
export function dynamicRetailerPriceFormat(value, currency) {
	return PriceFormatter(Number(value), {
		precision: 2,
		symbol: currency,
	}).replace(/(\.|,)/g, (x) => {
		return x === "," ? "." : x === "." ? "," : "";
	});
}
export function dynamicRetailerimpactPriceFormat(num) {
	return num.replace(/(\.|,)/g, (x) => {
		return x === "," ? "." : x === "." ? "," : "";
	});
}

export function generateStrategyNameList(items) {
	let finalArray = [];
	items && items.map((item) => finalArray.push(item.strategyName));
	return finalArray;
}

export function generateUseCaseNameList(items) {
	// console.log("items", items)
	let finalArray = [];
	items?.map((item) => (item?.useCase ? finalArray.push(item.useCase) : ""));
	return finalArray;
}

export function generateStrategyList(items) {
	let finalArray = [];
	items.map((item) => finalArray.push(item));
	return finalArray;
}

export function generateFormattedEstimateValue(value) {
	let formatedValue = value && value.toString();
	let currencyValue = store.getState().globalReducer.selectedRetailerCurrency;
	if (formatedValue && formatedValue.charAt(0) === "-") {
		formatedValue = value.toString().replace("-", "");
		formatedValue = `-${currencyValue}${formatedValue}`;
	} else formatedValue = `${currencyValue}${formatedValue}`;
	return formatedValue;
}

export function sortFunction(a, b, order, dataField, rowA, rowB) {
	if (order === "asc") {
		return b - a;
	}
	return a - b; // desc
}

export function RenderSelectedFilters(props) {
	let tempSelectedItemsToDisplay = cloneDeep(props.itemsToDisplay);
	let notEmptySelectedItemsToDisplay = {};
	for (let key in tempSelectedItemsToDisplay) {
		if (tempSelectedItemsToDisplay[key].length > 0) {
			notEmptySelectedItemsToDisplay[key] =
				tempSelectedItemsToDisplay[key];
		}
	}

	return Object.keys(notEmptySelectedItemsToDisplay).map((item, index) => {
		let len = notEmptySelectedItemsToDisplay[item].length;

		return (
			<OverlayTrigger
				placement='right'
				delay={{ show: 250, hide: 400 }}
				overlay={renderTooltip(
					notEmptySelectedItemsToDisplay[item].toString()
				)}
			>
				<li className='filtered' key={index}>
					<b>{item} </b>: {len}
				</li>
			</OverlayTrigger>
		);
	});

	function renderTooltip(props, name) {
		return (
			<Tooltip id='button-tooltip' {...props}>
				{props}
			</Tooltip>
		);
	}
}

export function addCommonActivityCaptureDetail(pageSpecificData, callback) {
	let userInfo = JSON.parse(localStorage.getItem("user-info"));
	let activityInfo = {
		userName: userInfo !== null ? userInfo.name : "",
		userMailId: userInfo !== null ? userInfo.email : "",
		userGroup: userInfo !== null ? userInfo.groups : "",
		sAMAccountName: userInfo?.sAMAccountName
			? userInfo?.sAMAccountName
			: "",
	};
	updateUserActivity({ ...activityInfo, ...pageSpecificData }, callback);
}

export function getCookie(key) {
	const regexp = new RegExp(`.*${key}=([^;]*)`);
	const result = regexp.exec(document.cookie);
	if (result) {
		return result[1] !== "undefined" ? JSON.parse(result[1]) : "";
	} else return "";
}

export function getRpcEnabledRetailers() {
	return [
		"Coupang-eCom-Korea",
		"Shopee-eCom-Malaysia",
		"Shopee-eCom-Philippines",
		"Shopee-eCom-Thailand",
		"Shopee-eCom-Singapore",
		"Shopee-eCom-Vietnam",
		"Detmir-Russia",
		"Flipkart-India",
		"Tesco-UK",
		"eBay-eCom-Korea",
	];
}

export function getexistingRetailers() {
	return [
		"Coupang-eCom-Korea",
		"Shopee-eCom-Malaysia",
		"Shopee-eCom-Philippines",
		"Shopee-eCom-Thailand",
		"Shopee-eCom-Singapore",
		"Shopee-eCom-Vietnam",
		"Detmir-Russia",
		"Flipkart-India",
		"Tesco-UK",
		"eBay-eCom-Korea",
		"MagazineLuiza-BR",
	];
}

export const generateFinalGlobalFilter = (responseData) => {
	let localStorageGlobalFilters = getCookie("selected-global-filters");
	let finalGlobalFilters =
		getSelectedGlobalFiltersCount(localStorageGlobalFilters) > 0
			? localStorageGlobalFilters
			: responseData;
	return finalGlobalFilters;
};

export let getStrategyNames = (data) => {
	let allStrategyNames = [];
	data &&
		data.responseData &&
		data.responseData.map((option) => {
			allStrategyNames.push(option.strategyName);
		});
	return allStrategyNames;
};

export function getFirstAndLastDate(data) {
	if (data.length === 0) {
		return [];
	}

	const firstDate = data[0].DATE;
	const lastDate = data[data.length - 1].DATE;

	return [firstDate, lastDate];
}
export function splitRetailerAndCountry(input) {
	const parts = input.split("-");
	const country = parts.pop(); // The last part is the country
	const retailer = parts.join("-"); // The remaining parts are the retailer
	return { retailer, country };
}

// Example entry: const dates = ["2024-07-01", "2024-08-28"];
// Example use: const formattedDates = dates.map(formatDate);
// Output: ["01-July-2024", "28-August-2024"]
export function formatDate(dateStr) {
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const [year, month, day] = dateStr.split("-");
	const monthName = months[parseInt(month) - 1];

	return `${day}-${monthName}-${year}`; // Return in "dd-Month-yyyy" format
}
export const getOtherFilterInformation = (filters) => {
	let otherFilters = [];
	if (filters.kc_platform?.length) {
		otherFilters.push(`KC Platform: ${filters.kc_platform.join(", ")}`);
	}
	if (filters.kc_sub_category?.length) {
		otherFilters.push(
			`KC Sub Category: ${filters.kc_sub_category.join(", ")}`
		);
	}
	if (filters.kc_primary_manufacturer?.length) {
		otherFilters.push(
			`KC Primary Manufacturer: ${filters.kc_primary_manufacturer.join(
				", "
			)}`
		);
	}
	if (filters.special_filter?.length) {
		otherFilters.push(
			`Special Filters: ${filters.special_filter.join(", ")}`
		);
	}
	if (filters.rpln_flag?.length) {
		const rplnFlagValue = filters.rpln_flag.includes("Replenishable") ? "Yes" : "No";
		otherFilters.push(`Replenishable: ${rplnFlagValue}`);
	}
	if (filters.adoptionSelectedStrategies?.length) {
		otherFilters.push(
			`Alert filters: ${filters.adoptionSelectedStrategies.join(", ")}`
		);
	}

	if (filters.adoptionSelectedUseCases?.length) {
		otherFilters.push(
			`UseCase Filter: ${filters.adoptionSelectedUseCases.join(", ")}`
		);
	}

	if (filters.adoptionSelectedUsers?.length) {
		otherFilters.push(
			`User Filter: ${filters.adoptionSelectedUsers.join(", ")}`
		);
	}
	return otherFilters.join(" | ");
};

export function convertToKMB(value, currency) {
	let labelValue = Math.round(value);
	// Nine Zeroes for Billions
	let priceValue =
		Math.abs(Number(labelValue)) >= 1.0e9
			? (Number(value) / 1.0e9).toFixed(2) + "B"
			: // Six Zeroes for Millions
			Math.abs(Number(labelValue)) >= 1.0e6
				? (Number(value) / 1.0e6).toFixed(2) + "M"
				: // Three Zeroes for Thousands
				Math.abs(Number(labelValue)) >= 1.0e3
					? (Number(value) / 1.0e3).toFixed(2) + "K"
					: PriceFormatter(value, { precision: 2, symbol: currency });
	return priceValue.includes(currency) ? priceValue : currency + priceValue;
}

export const getCurrentMonthRange = () => {
	// Usage:
	// Today is 2 October 2024, my current month will be:
	// expected output: 1-October-2024 to 31-October-2024 

	// if Today is 29 september 2024, my current month will be:
	// expected output: 1-September-2024 to 30-September-2024 
	const today = new Date(); // Get the current date
	const year = today.getFullYear();
	const month = today.getMonth(); // 0-based (0 for January, 11 for December)

	// Create date objects for the first and last day of the current month
	const firstDay = new Date(year, month, 1);
	const lastDay = new Date(year, month + 1, 0); // Last day of the current month

	// Function to format date as "Day-Month-Year"
	const formatDate = (date) => {
		const day = date.getDate();
		const monthName = date.toLocaleString('default', { month: 'long' });
		const year = date.getFullYear();
		return `${day}-${monthName}-${year}`;
	};

	const firstDayFormatted = formatDate(firstDay);
	const lastDayFormatted = formatDate(lastDay);
	return `${firstDayFormatted} to ${lastDayFormatted}`;
};

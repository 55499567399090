import React, { useState, useEffect } from 'react';
import { ExcelfileIcon } from '../../components/Icons';
import { useSelector } from 'react-redux';
import NewInsightsPbiComponent from './NewInsightsPbiComponent';
import { Buffer } from 'buffer';

const SalesPredictionSection = () => {
    let { selectedCustomerKey,selectedGlobalFilters } = useSelector((state) => state.globalReducer);
    const downloadSPInfo = () => {
        let combinedObj = {
            customerKey: selectedCustomerKey,
            filterValues: selectedGlobalFilters
        },
        bufferValue = Buffer.from(JSON.stringify(combinedObj)),
        base64data = bufferValue.toString("base64");
        window.open(`${process.env.REACT_APP_ROOT_URL}/download/exportSalesForecastReport/${base64data}`, "_blank");
    }
    return (
        <div className='sales-predict-pbi'>
        <div className='m-4 mb-3 d-flex justify-content-between top-borderbtm'>
            <h4 className='titlename pt-4'>Sales Prediction</h4>
            <div className='d-flex'>
                <div className="togglebtn" onClick={(e) => downloadSPInfo()}><div className='btn downloadfile'> Download Sales Prediction Details<ExcelfileIcon /></div></div>
            </div>
        </div>
        <NewInsightsPbiComponent pbiviewName="Sales Prediction" />
        </div>

    );
}

export default SalesPredictionSection;
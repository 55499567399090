import React, { useEffect, useState } from "react";
import "./adoption.css";
import {
  getDetailsForAllStrategies,
  getDetailsForAllUsers,
  adoptionSelectedUSers,
  getUseCaseList,
  getEngagementSummary
} from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../../components/Filters";
import { css } from '@emotion/react';
import BarLoader from "react-spinners/BarLoader";
import ThreeColumn from "./ThreeColumn";
import AlertSummaryTable from "./AlertSummaryTable";
import UserSummaryTable from "./UserSummaryTable";
const GridLayout = () => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  let {
    keyMapTabList,
    subproject   
  } = useSelector((state) => state.globalReducer);
  useEffect(() => {
    Promise.all([
      dispatch(getDetailsForAllStrategies({ strategyStatus: "active" })),
      dispatch(getDetailsForAllUsers()),
      dispatch(getUseCaseList()),
    ])
      .then((data) => {
          setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  }, []);
  useEffect(() => {
    document.title = keyMapTabList ? keyMapTabList.adoptionTab : "";
}, [keyMapTabList]);
  let generateTitleRow = (title1, title2) => {
    return (
      <div className="row" style={{ marginBottom: 30 }}>
        <div
          className="col-md "
          style={{ fontWeight: "bold", marginLeft: 0, marginRight: 30 }}
        >
          {" "}
          {title1}
        </div>
        <div className="col-md " style={{ fontWeight: "bold", padding: 0 }}>
          {" "}
          {title2}
        </div>
      </div>
    );
  };
  let generateTitleRowthree = (title1, title2,title3,title4,title5) => {
    return (
      <div className="row m-2">
        <div className="col-md font-weight-bold"> {title1} </div>
        <div className="col-md font-weight-bold"> {title2} </div>
        <div className="col-md font-weight-bold"> {title3} </div>
        <div className="col-md font-weight-bold"> {title4} </div>
        <div className="col-md font-weight-bold"> {title5} </div>
      </div>
    );
  };
  if (loading) {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    return (
      <div id="strategy_wrapper">
        <div className="container-fluid  default-top loading_wrapper bg-gray p-5">
          <div className="sweet-loading">
            <BarLoader
              css={override}
              size={150}
              color={"#123abc"}
              height={4}
              width={100}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container-fluid" style={{ marginTop: 35, width: "100%", padding: 30 }} >
    <Filters isAdoption={true} title= {keyMapTabList ? keyMapTabList.adoptionTab : ""}  />    
    <ThreeColumn />    
    <AlertSummaryTable />
    <UserSummaryTable />
    </div>
    
  );
};

export default GridLayout;

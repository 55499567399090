import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BarLoader from "react-spinners/BarLoader";
import Modal from 'react-bootstrap/Modal';
import PreviewDashboard from './PreviewDashboard';
import ViewBuilder from './ViewBuilder';
import DashboardBasicDetails from './DashboardBasicDetails';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom'
import { LeftArrowIcon, EditIcon, TickIcon, ServerIcon, PlusCircleFill, CloseIcon, TrashIcon } from '../../components/Icons';
import {
    updateJsonForView,
    getSelectedView, deleteDashboard,
    getAllPagesForAdmin, updateSelectedView
} from '../../actions';
import { ToastContainer, toast } from 'react-toastify';
import _get from 'lodash/get';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

class DashboardBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showTablesModal: false,
            selectedTab: "Page Builder",
            showSettingsWindow: false,
            sidebarOptions: [{ icon: "fas fa-info-circle", title: "Basic Details" },
            { icon: "fas fa-layer-group", title: "Page Builder" },
            { icon: "far fa-image", title: "Preview" }],
            viewType: 'Desktop',
            tablename: "",
            tables: [],
            modified:false
        }
    }
    //lifecycle methods
    componentDidMount() {
        // if view builder exists in local storage that means the page was
        // refreshed, so use this as your main JSON
        const viewId = localStorage.getItem("viewId");
        this.props.getSelectedViewAPI({ viewId: viewId });
    }
    componentDidUpdate(prevProps, prevState) {
        // view builder props has changed, set it to state
        if (this.props.viewBuilder !== prevProps.viewBuilder) {
            this.setState({ viewBuilder: this.props.viewBuilder });
            localStorage.setItem("viewBuilderJSON", JSON.stringify(this.props.viewBuilder));
        }
    }
    setSelectedTab = (tab) => {
        this.setState({ selectedTab: tab });
    }


    renderSettingsWindow() {
        const { viewBuilder } = this.state;
        return (
            <Modal size={'md'} show={this.state.showSettingsWindow} onHide={() => {
                this.setState({ showSettingsWindow: false });
            }} id="setting_modal" >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="modalForWidgetLabel">Edit Dashboard</h5>
                </Modal.Header>
                <Modal.Body>
                </Modal.Body>
                {viewBuilder ? <DashboardBasicDetails
                    hideSettingsWindow={(e) => { this.setState({ showSettingsWindow: false }) }}
                    viewBuilderJSON={viewBuilder.viewBuilderJSON} />
                    : null}
            </Modal>
        )
    }
    renderTableNames() {
        const { viewBuilder } = this.state;
        if (!viewBuilder || (viewBuilder && viewBuilder.viewBuilderJSON.tablesForPbi.length === 0)) {
            return <div>No data available. Add table names by entering and click on Save.</div>
        }
        return viewBuilder.viewBuilderJSON.tablesForPbi.map((table, index) => {
            return <div className="d-flex align-items-center justify-content-between my-2 px-2" key={index}>
                
                <div className="text">{table}</div>
                <button className="btn btn-delete" onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                        text: `Are you sure you want to delete the table name "${table}"?`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value) {
                            let jsonData = cloneDeep(viewBuilder);
                            let index = jsonData.viewBuilderJSON.tablesForPbi.indexOf(table);
                            jsonData.viewBuilderJSON.tablesForPbi.splice(index, 1);
                            this.setState({ viewBuilder: jsonData, modified:true });
                        }
                    })
                }}>Delete<TrashIcon /></button>
                </div>
        });
    }
    renderTablesModal() {
        const { viewBuilder, tables } = this.state;
        return (
            <Modal size={'md'} show={this.state.showTablesModal} onHide={() => {
                this.setState({ showTablesModal: false });
            }} id="setting_modal" >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="modalForWidgetLabel">Edit Tables to which Power BI can connect</h5>
                </Modal.Header>
                <Modal.Body>
                </Modal.Body>
                {viewBuilder ?
                    <div className="col-12 sections my-2" id="basic_screen">
                        <form className="form">
                            <div className="row">
                                <div className="col-12  col-xl-12 form-group ">
                                    <label className="">
                                        Enter table name
      							</label>
                                    <div className="d-flex align-items-center">
                                        <input value={this.state.tablename} type="text"
                                            className="form-control " placeholder="Table name"
                                            onChange={(e) => { this.setState({ tablename: e.target.value, error: "" }) }} />
                                        <button className="btn btn-cta ml-3" onClick={(e) => {
                                            e.preventDefault();
                                            if (this.state.tablename) {
                                                let jsonData = cloneDeep(this.state.viewBuilder);
                                                jsonData.viewBuilderJSON.tablesForPbi.push(this.state.tablename);
                                                this.setState({ viewBuilder: jsonData, tablename: "" ,modified:true});
                                               } else {
                                                toast.error("Please enter a name", { position: toast.POSITION.BOTTOM_LEFT });
                                            }
                                        }}>Add</button>
                                    </div>
                                </div>
                                
                                <div className="col-12 px-4 my-2">
                                    {this.renderTableNames()}
                                </div>
                                <div className="col-12 error">{this.state.error}</div>
                                <div className="col-12  col-xl-12 form-group  flex mt-3" style={{ justifyContent: "flex-end" }}>
                                    <button type="button" className="btn btn-apply" onClick={(e) => {
                                        const { viewId, tablesForPbi } = this.state.viewBuilder.viewBuilderJSON;
                                       const { userInfo } = this.state;
                                        e.preventDefault();
                                        let obj = {
                                            viewId,
                                            tablesForPbi,
                                        }
                                        if (this.state.modified) {
                                            this.props.updateSelectedViewAPI(obj).then((res, err) => {
                                                if (res) {
                                                    toast.success(res.data.responseDesc, { position: toast.POSITION.BOTTOM_LEFT });
                                                    this.setState({ modified: false });
                                                } else if (err) {
                                                    
                                                }
                                            });
                                        } else {
                                            toast.error("Add / Delete a table to save", { position: toast.POSITION.BOTTOM_LEFT });
                                        }
                                        
                                    }}>Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    : null}
            </Modal>
        )
    }
    renderDashboardTab() {
        if (this.state.selectedTab === "Page Builder") {
            return (
                <ViewBuilder windowType={this.state.viewType} />
            )
        }
        else if (this.state.selectedTab === "Preview") {
            return (
                <PreviewDashboard windowType={this.state.viewType} />
            )
        }
    }

    handleSavingJson() {
        this.setState({ loading: true });
        this.props.updateJsonForViewAPI({
            viewId: this.state.viewBuilder.viewBuilderJSON.viewId,
            htmlTempJson: this.state.viewBuilder.viewBuilderJSON.htmlTemp
        }, (res, rej) => {
            if (res) {
                this.props.getSelectedViewAPI({ viewId: this.state.viewBuilder.viewBuilderJSON.viewId });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });

            }
        });
    }

    renderLoader() {
        if (this.state.loading) {
            return (
                <div className="loader-wraper">
                    <div className='loader'>
                        <BarLoader
                            size={150}
                            color={"#123abc"}
                            height={4}
                            width={100}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            )
        }
    }
    deleteDashboard() {
        if (Object.keys(this.state.viewBuilder).length > 0) {
            if (window.confirm(`Are you sure, you want to delete Dashboard -  ${this.state.viewBuilder.viewBuilderJSON.viewName}  ?`)) {
                this.setState({ loading: true })
                this.props.deleteDashboardAPI({ viewName: this.state.viewBuilder.viewBuilderJSON.viewName, viewId: this.state.viewBuilder.viewBuilderJSON.viewId }, (res, err) => {
                    this.props.getAllPagesForAdminAPI({});
                    this.props.history.push(`/${this.props.project}/${this.props.project}/admin`)
                }
                ).catch(function (errors) {
                    this.setState({ loading: false })
                    toast.error(errors, { position: toast.POSITION.TOP_CENTER })
                });
            }
        }
    }
    discardChanges() {
        if (Object.keys(this.state.viewBuilder).length > 0) {
            if (window.confirm(`Are you sure, you want to discard unsaved change(s)`)) {
                this.props.history.push(`/${this.props.project}/${this.props.subproject}/admin`)
            }
        }
    }

    render() {
        const { viewBuilder } = this.state;
        // const projecturlname = this.props?.project?.toLowerCase() === 'ecompass' ? 'admin/salesprediction' : 'admin';
        return (
            <div className='view_builder_default default-top'>
                <div className="container-fluid  p-5 px-0  height_max">
                    <div className="col-12 sections height_100 " id="builder_screen_header">
                        <div className="row">
                            <div className="col-12 py-2">
                                <Link to={`/${this.props.project}/${this.props.subproject}/admin`} className=""><LeftArrowIcon /> Dashboards</Link>
                            </div>
                            {viewBuilder ?
                                <>
                                    <div className="col-12 py-2 d-flex justify-content-between">
                                        <div className="header">{viewBuilder.viewBuilderJSON.viewName}</div>
                                        <div><button className="btn btn-apply" onClick={(e) => { this.handleSavingJson() }}> <TickIcon /> Save Changes</button></div>
                                    </div>
                                    <div className="col-12 py-2 ">
                                        <div className="row">
                                            <div className="col-3">
                                                <button
                                                    onClick={(e) => this.setState({ selectedTab: 'Page Builder' })} className={`btn btn-cta ${this.state.selectedTab === 'Page Builder' ? "active-view" : null}`}
                                                >Layout</button>
                                                <button
                                                    onClick={(e) => this.setState({ selectedTab: 'Preview' })} className={`btn btn-cta ${this.state.selectedTab === 'Preview' ? "active-view" : null}`}
                                                >Preview</button>
                                            </div>
                                            <div className="col-9 bg-white d-flex justify-content-between align-items-center">
                                                <div>
                                                    <span className="bold mr-3">Details : </span>
                                                    <span className="px-4">Project : {viewBuilder.viewBuilderJSON.projectName}</span>
                                                    <span className="px-4">Category : {viewBuilder.viewBuilderJSON.categoryName}</span>
                                                    <span className="px-4">Status : {viewBuilder.viewBuilderJSON.viewStatus}</span>
                                                </div>
                                                <div>
                                                    <button className="btn btn-cta" onClick={(e) => {
                                                        this.setState({ showTablesModal: true });
                                                    }}> <ServerIcon /> Edit Tables</button>
                                                    <button className="btn btn-cta" onClick={(e) => {
                                                        this.setState({ showSettingsWindow: true });
                                                    }}> <EditIcon /> Edit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null}

                        </div>

                        <div className="col-12 py-2 px-0 builder_window height_100">
                            {this.renderLoader()}
                            {this.renderDashboardTab()}
                            {this.renderSettingsWindow()}
                            {this.renderTablesModal()}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    let project = _get(state, 'globalReducer.project', "");
    let subproject = _get(state, 'globalReducer.subproject', "");
    return ({
        viewBuilder: state.viewBuilderObj,
        project,
        subproject
    })
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateJsonForViewAPI: updateJsonForView,
        getSelectedViewAPI: getSelectedView,
        deleteDashboardAPI: deleteDashboard,
        getAllPagesForAdminAPI: getAllPagesForAdmin,
        updateSelectedViewAPI: updateSelectedView
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardBuilder);















import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { DropDownIcon } from "./Icons";
import Form from "react-bootstrap/Form";
import {RankTypeAlertFilter} from '../actions';


export default function RankTypeFilter() {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);

  let { recommendationSelectedStrategy, selectedStrategies } = useSelector((state) => state.strategyReducer);
  let sosRecomendationName = (recommendationSelectedStrategy?.activeRowDetails?.RECOMMENDATION_NAME === 'Share of Search') || (selectedStrategies?.length && selectedStrategies[0]) === 'Share of Search' ? 'Share of Search' : '';

  useEffect(() => {   
    if(selectedStrategies?.length && selectedOption?.length > 0) {
      setSelectedOption([]);
      dispatch(RankTypeAlertFilter([])); 
    }     
  }, [selectedStrategies]);
  function handleCheckboxClick(event, option ) {
    let finalList = [...selectedOption]
    if (event.target.checked) {
      finalList.push(option)
    }else {
      finalList.splice(finalList.indexOf(option),1)
    }
    setSelectedOption(finalList);

  }

  function handleApplyFilter(e) {
    e.preventDefault();
    onToggle(false, true);
    dispatch(RankTypeAlertFilter(selectedOption));    
  };

  function onToggle(e, apply) {
    !apply && setSelectedOption(selectedOption) 
    setDisplayDropdown(e);
  }

  function setCheckBox(option) {    
      return selectedOption.indexOf(option)> -1 ? true : false   
  }

  return (
    <div id="recommendationFilter">
      <Dropdown className="mr-2" show={displayDropdown} onToggle={onToggle}>
        <Dropdown.Toggle disabled={sosRecomendationName !== 'Share of Search'}
          variant="light"
          id="dropdown-basic"
          className="btn-cta">
          <i class='fas fa-chart-line mr-2'></i>
          Rank Type
          <DropDownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Form>
            <Form.Group className="filter_dropdown_wrapper">              
              
              <div className="strategy_dropdown_wrapper">
              <Form.Check type="checkbox" label="Organic" checked={setCheckBox('Organic')} onChange={(e) => {
              handleCheckboxClick(e,"Organic");
            }}/>
            <Form.Check type="checkbox" label="Paid" checked={setCheckBox('Paid')} onChange={(e) => {
              handleCheckboxClick(e,"Paid");
            }}/>
              <Form.Check type="checkbox" label="Total" checked={setCheckBox('Total')} onChange={(e) => {
              handleCheckboxClick(e,"Total");
            }}/>
              </div>
              <div className="apply_button_wrapper">
              <div className="horizontal_divider"></div>
                <div className="d-flex justify-content-between">
                  <button className={`btn btn-disabled ${
                      selectedOption.length > 0 ? null : "btn-disabled-clear-all"
                    } my-2`} onClick={(e) => {
                      e.preventDefault();
                      setSelectedOption([]);
                      dispatch(RankTypeAlertFilter([])); 
                    }}>
                    Clear All
                  </button>    
                  <button className={`btn btn-apply  ${
                      selectedOption.length > 0 ? null : "btn-disabled-apply"
                    } my-2`} onClick={(e) => {
                      e.preventDefault();
                      handleApplyFilter(e);
                    }}>
                    Apply
                  </button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}